import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoXml } from '../class/info-xml';
import { CustomerXml } from '../class/customer-xml';
import { DeliveryXml } from '../class/delivery-xml';
import { ShopXml } from '../class/shop-xml';
import { StatusXml } from '../class/status-xml';
import { SummaryXml } from '../class/summary-xml';
import { AppThunk, store } from '../../app/store';
import { XmlFactory } from '../factory/xml-factory';
import {
  XmlStructureModel,
  XmlStructureOrderInfoData,
  XmlStructureOrderPartsData,
} from '../model/xml-structure-model';
import { DateFormatter } from '../../utilities/date-formatter';
import { UuidGenerator } from '../../utilities/uuid-generator';
import { OrderSelectXml } from '../class/order/order-select-xml';
import { ApiAllGetXml, GetXmlResponse } from '../../api/front/xml/api-all-get-xml';
import { ResponseBase } from '../../api/response-base';
import { apiActions } from '../../slices/api-slice';
import { ApiUploadXml } from '../../api/front/xml/api-upload-xml';
import { OrderSelectImageData, TOrderSelectMetaModel } from '../model/order/xml-order-select-model';
import { OrdersSliceState } from '../../slices/orders-slice';
import {
  createOrderInfoDataViewModel,
  descriptionInfoPageTypeInherit,
  TOrderInfoDataViewModel,
} from '../model/order/xml-order-info-data-model';
import { OrdersDataState } from '../../slices/orders/orders-data-slice';
import lodash, { cloneDeep, isEqual } from 'lodash';
import { createOrderPartsDataViewModel } from '../model/order/xml-order-parts-data-model';
import { createOrderPageData } from '../model/order/xml-order-page-data-model';
import { IMasterProduct } from '../../models/i-master-product';
import { ApiDeleteXml } from '../../api/front/xml/api-delete-xml';
import { XmlClass } from '../class/xml-class';
import { processId } from '../model/process-id';
import { OrderPageDataXml } from '../class/order/order-page-data-xml';
import {
  ApiImagesCopyPost,
  ApiImagesDelete,
  ApiImagesGet, ApiImagesGetOne, ApiImagesPost,
  ApiImagesUpdatePost, ImagesGetResponse, ImagesPostRequest,
} from '../../api/front/images/api-images';
import { EditableImage } from '../../layout-editor/manager/image-edit/editable-image';
import { OrderPartsDataXml } from '../class/order/order-parts-data-xml';
import { ChildRetouchInfo, ParentRetouchInfo } from '../../components/pages/retouch/footer/retouch.footer';
import { file } from 'jszip';
import { DefaultRetouchView, XMLRetouchModel } from '../model/retouch/xml-retouch-model';
import { OrderInfoDataXml } from '../class/order/order-info-data-xml';
import { ApiImageCheckPost } from '../../api/front/image-check/api-image-check';
import { ImageCheckPostResponse } from '../../models/api/front/image-check';
import { ApiReceptionSerialIdGet } from '../../api/front/recepsion-serial-id/api-reception-serial-id';
import { localStorageActions } from '../../slices/local-storage-slice';
import { PageBreakImageDataEntity } from '../interface/order/i-order-page-break';
import { OrderPageBreakXml } from '../class/order/order-page-break-xml';
import { ApiFavoriteCopyPost } from '../../api/front/favorite/api-favorite';
import { LayoutCompFilesType } from '../../layout-editor/manager/data/layout-data-type';
import { Image } from '../../components/ui/image/image';
import { AlbumManager } from '../../layout-editor/albam/albam';
import { AlbumData, AlbumSettingData, AlbumDatas, layoutActions, SaveSelectData } from '../../slices/layout-slice';
import { ApiOrderFormCopy, ApiOrderFormGet } from '../../api/front/order-form/api-order-form';
import { OrderFormGetResponse } from '../../models/api/front/order-form';
import { dialogActions } from '../../components/dialog/slice/dialog-slice';
import {
  ApiMetaShopOrderGet,
  ApiMetaShopOrderPost,
  MetaShopOrderGetResponse,
  MetaShopOrderPostRequest,
} from '../../api/front/meta/api-meta-shop-order';
import { ApiOrderRegister } from '../../api/back/order-register/api-order-register';
import { ChangeSelectData } from '../../layout-editor/manager/data/layout-data.formatter';
import { optionDescriptionLocationEditableCheck } from '../../components/pages/create-order/option-order';
import { VisibleConditionValueGetter } from '../../manager/visible-condition-value-getter';
import { LayoutXmlUtile, RestorePicData } from '../../layout-editor/model/layout.xml.utile';
import { MetaOrderPostRequest, ApiMetaOrderPost } from '../../api/front/meta/api-meta-order';
import { ApiAllDeleteXml } from '../../api/front/xml/api-all-delete-xml';
import { push } from 'connected-react-router';
import { RoutingPath } from '../../routes/routing-path';
import { ApiGetXml } from '../../api/front/xml/api-get-xml';
import { XmlParser } from '../../manager/xml-parser';
import { IOrderSelect } from '../interface/order/i-order-select';
import { SelectIdQueueManager } from '../../layout-editor/manager/select-id-queue/selectIdQueueManager';
import { EditableImageManager } from '../../layout-editor/manager/editable-image/editable-image.manager';

type TMoveImage = '' | 'comp' | 'use' | 'thumb' | 'logo' | 'text' | 'png';

type XmlSliceState = {
  [shopOrderId: string]: XmlStructureModel | null,
};

const initialState: XmlSliceState = {
  xml: null,
};

type ShopOrderXml = {
  info: InfoXml,
  customer: CustomerXml,
  delivery: DeliveryXml,
  shop: ShopXml,
  status: StatusXml,
  summary: SummaryXml,
};

type UploadImageXml = {
  orderSelect: OrderSelectXml,
};

type TAction<T> = PayloadAction<{
  shopOrderId: string,
  xml: T,
}>;

export const padding = (v: number, len: number) => {
  if (String(v).length >= len) {
    return `${v}`;
  }
  let str = String(v);
  for (let i = String(v).length; i < len; i++) {
    str = `0${str}`;
  }
  return str;
};

export const xmlSlice = createSlice({
  name: 'xml',
  initialState,
  reducers: {
    setXml: (state, action: TAction<XmlStructureModel>) => {
      if (state[action.payload.shopOrderId]) {
        state[action.payload.shopOrderId] = Object.assign(state[action.payload.shopOrderId], action.payload.xml);
      } else {
        state[action.payload.shopOrderId] = action.payload.xml;
      }
      // 各クラス同士の紐付け
      const orderInfo = state[action.payload.shopOrderId]?.orderInfo;
      if (orderInfo?.infoData) {
        orderInfo.xml.orderInfoDataArr = orderInfo.infoData.map((infoData) => {
          const orderParts = infoData.parts;
          infoData.xml.orderParts = orderParts?.xml;
          infoData.xml.orderPageBreak = infoData.pageBreak;
          if (orderParts?.partsData) {
            orderParts.xml.orderPartsDataArr = orderParts.partsData.map((partsData) => {
              const orderPage = partsData.page;
              partsData.xml.orderPage = orderPage?.xml;
              if (orderPage?.pageData) {
                orderPage.xml.orderPageDataArr = orderPage.pageData.map((pageData) => pageData);
              }
              return partsData.xml;
            });
          }
          return infoData.xml;
        });
      }
      // オプション含む全ての order-page-data 設置
      if (orderInfo?.xml) {
        orderInfo.xml.allOrderPageData = [];
        orderInfo?.xml.metaModel.parentSequence?.forEach((parentOrderInfoData) => {
          const orderInfoData = orderInfo.infoData?.find((v) => v.xml.metaModel.id === parentOrderInfoData.id);
          if (parentOrderInfoData.id && orderInfoData) {
            orderInfoData.xml.allOrderPageDataXml = [];
            orderInfoData.xml.optionOrderInfoDataXml = [];
            orderInfo?.infoData?.forEach((infoData) => {
              if (infoData.xml.metaModel.parentId === parentOrderInfoData.id || infoData.xml.metaModel.id === parentOrderInfoData.id) {
                if (infoData.xml.metaModel.parentId === parentOrderInfoData.id) {
                  orderInfoData.xml.optionOrderInfoDataXml.push(infoData.xml);
                }
                infoData.parts?.partsData?.forEach((partsData) => {
                  partsData.page?.pageData?.forEach((pageData) => {
                    orderInfoData.xml.allOrderPageDataXml.push(pageData);
                    orderInfo.xml.allOrderPageData.push(pageData);
                  });
                });
              }
            });
          }
        });
      }
    },
    setShopOrder: (state, action: TAction<ShopOrderXml>) => {
      const {
        shopOrderId,
        xml,
      } = action.payload;
      const {
        info,
        customer,
        delivery,
        shop,
        status,
        summary,
      } = xml;
      const shopOrder = state[shopOrderId];
      if (shopOrder) {
        shopOrder.info = info;
        shopOrder.customer = customer;
        shopOrder.delivery = delivery;
        shopOrder.shop = shop;
        shopOrder.summary = summary;
        state[shopOrderId] = shopOrder;
      } else {
        state[shopOrderId] = {
          info: info,
          customer: customer,
          delivery: delivery,
          shop: shop,
          status: status,
          summary: summary,
        };
      }
    },
    setUploadImage: (state, action: TAction<UploadImageXml>) => {
      const xml = state[action.payload.shopOrderId];
      if (xml) {
        xml.orderSelect = action.payload.xml.orderSelect;
      }
    },
    clearXmlData: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = null;
      });
    },
  },
});

/* XML 生成用の関数（親商品とオプションとで2回呼ぶ） */
const createXml = (
  data: {
    shopOrderId: string,
    structure: Partial<XmlStructureModel>, // 返り値でも持つが参照は切らない
    order: OrdersSliceState,
    orderData: OrdersDataState,
    isNeedAgreement: boolean,
    visibleCondition: VisibleConditionValueGetter,
    keepData?: { xml?: XmlStructureModel, fav?: { id: string, pageDataList: OrderPageDataXml[] } | null, option?: XmlStructureOrderInfoData[] }, // order-page-data 保持用
  },
  v: Partial<TOrderInfoDataViewModel>, // order-info-data の viewModel
  index: number, // order-info-data の index
  option?: { // オプション商品の時に設定
    optionIndex: number,
    parentId: string,
    parentOrderInfo: XmlStructureOrderInfoData | undefined | null,
    needUpload: boolean,
    fav: boolean,
  },
) => {
  const { shopOrderId, structure, order, orderData, isNeedAgreement, keepData, visibleCondition } = data;
  const files: {
    filename: string,
    body: string,
    is_create: '0' | '1',
  }[] = [];
  const orderInfoDataIndex = index;
  /* order-info-data 作成 */
  const orderInfoData = XmlFactory.createFromIndex({
    shopOrderId,
    data: { name: 'order-info-data', indexes: [orderInfoDataIndex] },
  });
  orderInfoData.rootTagModel.contentGroupID = option ? `op01_option${option.optionIndex > 0 ? `_multifid_${option.optionIndex}` : ''}` : 'orderInfo';
  /* order-info の有無を確認 */
  if (!structure.orderInfo) {
    structure.orderInfo = {
      xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-info' } }),
      infoData: [],
    };
  }
  if (!structure.orderInfo.infoData) {
    structure.orderInfo.infoData = [];
  }
  const infoData: XmlStructureOrderInfoData = {
    xml: orderInfoData,
  };
  structure.orderInfo.infoData.push(infoData);
  /* order-info-data に紐づく order-parts 作成 */
  const orderParts = XmlFactory.createFromIndex({
    shopOrderId,
    data: { name: 'order-parts', indexes: [orderInfoDataIndex] },
  });
  const shopCode = structure.shop?.metaModel.shopCode ?? '';
  /* order-parts に紐づく order-parts-data 作成（表紙、本身） */
  const orderPartsData = createOrderPartsDataViewModel(shopCode, order, orderData, option ? (orderData.data.optionProductDetail[option.optionIndex] || {}) : orderData.data.productDetail, Boolean(option), visibleCondition,option && { optionIndex: option.optionIndex, parentOrderInfoStructure: option.parentOrderInfo || undefined });
  /* パス設定のために order-parts-data を生成してから order-info-data をビルド */
  if (orderPartsData.cover || orderPartsData.genuine) {
    infoData.parts = {
      xml: orderParts,
    };
  }
  orderInfoData.build(v, {
    id: `${orderInfoDataIndex}`,
    parentId: option && option.parentId,
    isNeedAgreement: option ? undefined : ((Object.keys(v.surrogateProcess?.data || {}).find((key) => key === 'jptg380076')) ? (isNeedAgreement ? 'true' : 'false') : undefined),
    path: (orderPartsData.cover || orderPartsData.genuine) ? {
      orderParts: orderParts.xmlUniqueName,
    } : undefined,
  });
  console.group(orderInfoData.xmlUniqueName);
  console.log(orderInfoData.xml);
  console.groupEnd();
  // files.push({
  //   filename: orderInfoData.xmlUniqueName,
  //   body: orderInfoData.xml,
  //   is_create: '1',
  // });
  if (!structure.orderInfo.xml.metaModel.path) {
    structure.orderInfo.xml.metaModel.path = [];
  }
  structure.orderInfo.xml.metaModel.path.push({
    id: `${orderInfoDataIndex}`,
    path: orderInfoData.xmlUniqueName,
  });
  /* 親商品の場合は order-info の parentSequence を更新 */
  if (!option) {
    if (!structure.orderInfo.xml.metaModel.parentSequence) {
      structure.orderInfo.xml.metaModel.parentSequence = [];
    }
    structure.orderInfo.xml.metaModel.parentSequence.push({
      id: `${orderInfoDataIndex}`,
    });
  }
  /* プリント商材用のページ数カウント */
  let pageCount = 0;
  /* parts がある場合のみ後続処理を実行 */
  if (orderPartsData.cover || orderPartsData.genuine) {
    /* 表紙の order-parts-data */
    const coverPageIndex = 1;
    const coverPartsData = orderPartsData.cover && XmlFactory.createFromIndex({
      shopOrderId,
      data: { name: 'order-parts-data', indexes: [orderInfoDataIndex, coverPageIndex] },
    });
    if (coverPartsData && orderPartsData.cover) {
      coverPartsData.rootTagModel.contentGroupID = option ? `op01o_parts1${option.optionIndex > 0 ? `_multifid_${option.optionIndex}` : ''}` : 'op01_cover';
      coverPartsData.viewModel = orderPartsData.cover;
    }
    /* 本身の order-parts-data */
    const genuinePageIndex = coverPartsData ? 2 : 1;
    const genuinePartsData = orderPartsData.genuine && XmlFactory.createFromIndex({
      shopOrderId,
      data: { name: 'order-parts-data', indexes: [orderInfoDataIndex, genuinePageIndex] },
    });
    if (genuinePartsData && orderPartsData.genuine) {
      genuinePartsData.rootTagModel.contentGroupID = option ? `op01o_parts2${option.optionIndex > 0 ? `_multifid_${option.optionIndex}` : ''}` : 'op01_body';
      genuinePartsData.viewModel = orderPartsData.genuine;
    }
    const orderPartsPathArr: { id: string, path: string }[] = [];
    if (coverPartsData && orderPartsData.cover) {
      orderPartsPathArr.push({ id: `${orderPartsPathArr.length + 1}`, path: coverPartsData.xmlUniqueName });
    }
    if (genuinePartsData && orderPartsData.genuine) {
      orderPartsPathArr.push({ id: `${orderPartsPathArr.length + 1}`, path: genuinePartsData.xmlUniqueName });
    }
    orderParts.build({}, {
      paths: orderPartsPathArr.length ? orderPartsPathArr : undefined,
    });
    files.push({
      filename: orderParts.xmlUniqueName,
      body: orderParts.xml,
      is_create: '1',
    });
    console.group(orderParts.xmlUniqueName);
    console.log(orderParts.xml);
    console.groupEnd();
    const partsDataXmlArr: { xml: OrderPartsDataXml }[] = [];
    if (coverPartsData) {
      partsDataXmlArr.push({ xml: coverPartsData });
    }
    if (genuinePartsData) {
      partsDataXmlArr.push({ xml: genuinePartsData });
    }
    if (infoData.parts) {
      infoData.parts.partsData = partsDataXmlArr.length ? partsDataXmlArr : undefined;
    }
    /* order-parts-data に紐づく order-page 作成 */
    /* 表紙の order-page */
    const coverPage = XmlFactory.createFromIndex({
      shopOrderId,
      data: { name: 'order-page', indexes: [orderInfoDataIndex, coverPageIndex] },
    });
    /* 本身の orderPage */
    const genuinePage = XmlFactory.createFromIndex({
      shopOrderId,
      data: { name: 'order-page', indexes: [orderInfoDataIndex, genuinePageIndex] },
    });
    /* order-page に紐づく order-page-data 作成 */
    /* 表紙の order-page-data */
    const productDetail = option ? orderData.data.optionProductDetail[option.optionIndex] : orderData.data.productDetail;
    const coverPageInfo = (option)
      ? productDetail?.item?.find((v) => String(v.itemId) === ((
          orderData.option.cover[option.optionIndex]) ? orderData.option.cover[option.optionIndex].itemId : undefined
      ))
      : productDetail?.item?.find((v) => String(v.itemId) === orderData.cover.parts.itemId);
    /* 編集時の元データ保持用 */
    const favOrderPageDataList = keepData?.fav?.pageDataList || [];
    const preOrderInfoData = keepData?.xml?.orderInfo?.infoData?.find((v) => Number(v.xml.indexes[0]) === Number(orderInfoDataIndex));
    const partsData = infoData.parts?.partsData?.find(v => v.xml.viewModel.type === 'main')
    const minPage = partsData ? partsData.xml.viewModel.minPageCount : undefined;
    const maxPage = partsData ? partsData.xml.viewModel.maxPageCount : undefined;
    // const isFixPageCount = minPage && maxPage ? Number(minPage) >= Number(maxPage) : false
    if (coverPartsData && infoData.parts?.partsData?.[0] && coverPageInfo?.pageInfo) {
      const pages = createOrderPageData(coverPageInfo.pageInfo, order.genuine.page, Boolean(option), coverPageInfo.inheritParent);
      pages.forEach((v, i) => {
        if (
          (v.displayPageType || v.pageType)
          && (
            !option
            || coverPageInfo?.inheritParent !== 'true'
            || (option.needUpload && option.parentOrderInfo?.xml.viewModel.orderMethod?.id === '40')
            || option.parentOrderInfo?.parts?.partsData?.find((partsData) => partsData.xml.xmlModel.lnwOrderPartsData.data?.[0]?.$.type !== 'main' && partsData.page?.pageData?.find((pageData) => Number(pageData.indexes[1]) === coverPageIndex && Number(pageData.indexes[2]) === i + 1))
            // || !option?.parentOrderInfo?.parts?.partsData?.find((partsData) => partsData?.page?.pageData?.find((pageData) => Number(pageData.indexes[0]) === Number(option.parentId) && Number(pageData.indexes[1]) === coverPageIndex && Number(pageData.indexes[2]) === i + 1))
          )
        ) {
          const page = XmlFactory.createFromIndex({
            shopOrderId,
            data: { name: 'order-page-data', indexes: [orderInfoDataIndex, coverPageIndex, i + 1] },
          });
          /* 元々ある order-page-data の情報を引き継ぐ*/
          page.viewModel = v;
          if (favOrderPageDataList.length) {
            const preOrderPageData = favOrderPageDataList.find((pageData) => pageData.indexes[1] === page.indexes[1] && pageData.indexes[2] === page.indexes[2] && pageData.viewModel.pageType === page.viewModel.pageType);
            if (preOrderPageData) {
              page.metaModel = lodash.cloneDeep(preOrderPageData.metaModel);
              page.viewModel = lodash.cloneDeep(preOrderPageData.viewModel);
              page.viewModel.orderPicture?.data?.forEach((v) => {
                v.selectID = undefined;
                if (v.logoFlag === '1' && v.logoFileName) {
                  v.logoFileName.realPath = undefined;
                }
              });
              page.viewModel.orderTextImage?.data?.forEach((text) => {
                text.path = undefined;
              });
            }
          }
          if (preOrderInfoData?.xml.viewModel.item?.id === orderInfoData.viewModel.item?.id) {
            preOrderInfoData?.parts?.partsData?.forEach((partsData) => {
              const preOrderPageData = partsData.page?.pageData?.find((pageData) => pageData.indexes[0] === page.indexes[0] && pageData.indexes[1] === page.indexes[1] && pageData.indexes[2] === page.indexes[2] && pageData.viewModel.pageType === page.viewModel.pageType);
              if (preOrderPageData) {
                page.metaModel = lodash.cloneDeep(preOrderPageData.metaModel);
                page.viewModel = lodash.cloneDeep(preOrderPageData.viewModel);
                // page.viewModel.orderPicture?.data?.forEach((v) => {
                //   if (v.logoFlag === '1' && v.logoFileName) {
                //     v.logoFileName.realPath = undefined;
                //   }
                // });
                // page.viewModel.orderTextImage?.data?.forEach((text) => {
                //   text.path = undefined;
                // });
              }
            });
          } else if (keepData?.option) {
            const data = keepData.option.find((v) => v.xml.viewModel.item?.id === orderInfoData.viewModel.item?.id);
            if (data) {
              data.parts?.partsData?.forEach((partsData) => {
                const preOrderPageData = partsData.page?.pageData?.find((pageData) => pageData.indexes[1] === page.indexes[1] && pageData.indexes[2] === page.indexes[2]);
                if (preOrderPageData) {
                  page.metaModel = lodash.cloneDeep(preOrderPageData.metaModel);
                  page.viewModel = lodash.cloneDeep(preOrderPageData.viewModel);
                  if (option?.fav) {
                    page.viewModel.orderPicture?.data?.forEach((v) => {
                      v.selectID = undefined;
                      if (v.logoFlag === '1' && v.logoFileName) {
                        v.logoFileName.realPath = undefined;
                      }
                    });
                    page.viewModel.orderTextImage?.data?.forEach((text) => {
                      text.path = undefined;
                    });
                  }
                }
              });
            }
          }
          page.rootTagModel.contentGroupID = option ? `op01op1_page${option.optionIndex > 0 ? `_multifid_${option.optionIndex}` : ''}` : 'op01c_page';
          // page.build(v, {});
          page.build();
          files.push({
            filename: page.xmlUniqueName,
            body: page.xml,
            is_create: '1',
          });
          console.group(page.xmlUniqueName);
          console.log(page.xml);
          console.groupEnd();
          pageCount++;
          if (infoData.parts?.partsData?.[0].page?.pageData) {
            infoData.parts?.partsData?.[0].page?.pageData?.push(page);
          } else if (infoData.parts?.partsData) {
            /* order-page と order-page-data が存在する場合に order-parts-data のパスを設定 */
            coverPartsData.metaModel = {
              path: coverPage.xmlUniqueName,
            };
            infoData.parts.partsData[0].page = {
              xml: coverPage,
              pageData: [page],
            };
          }
        }
      });
      if (infoData.parts?.partsData?.[0].page?.pageData?.length) {
        coverPage.build({}, {
          paths: infoData.parts.partsData[0].page.pageData.map((v, i) => ({
            id: `${i + 1}`,
            path: v.xmlUniqueName,
          })),
        });
        files.push({
          filename: coverPage.xmlUniqueName,
          body: coverPage.xml,
          is_create: '1',
        });
        console.group(coverPage.xmlUniqueName);
        console.log(coverPage.xml);
        console.groupEnd();
      }
    }
    if (coverPartsData) {
      /* 継承情報が有効で親のページがない場合は親の parentID を設定しない */
      if (
        !coverPartsData.metaModel.path
        && coverPageInfo?.inheritParent === 'true'
        && option
        && !option?.parentOrderInfo?.parts?.partsData?.find((parts) => parts.page?.pageData?.find((v) => Number(v.indexes[0]) === Number(option.parentId) && v.indexes[1] === coverPartsData.indexes[1]))
      ) {
        coverPartsData.viewModel.parentID = undefined;
      }
      coverPartsData.build();
      files.push({
        filename: coverPartsData.xmlUniqueName,
        body: coverPartsData.xml,
        is_create: '1',
      });
      console.group(coverPartsData.xmlUniqueName);
      console.log(coverPartsData.xml);
      console.groupEnd();
    }
    /* 本身の order-page-data */
    // const genuinePageInfo = productDetail?.item?.find((v) => String(v.itemNo) === '2');
    const genuinePageInfo = (option)
      ? productDetail?.item?.find((v) => String(v.itemId) === ((
          orderData.option.genuine[option.optionIndex]) ? orderData.option.genuine[option.optionIndex].itemId : undefined
      ))
      : productDetail?.item?.find((v) => String(v.itemId) === orderData.genuine.parts.itemId);
    if (genuinePageInfo?.pageInfo) {
      const pages = createOrderPageData(genuinePageInfo.pageInfo, order.genuine.page, Boolean(option), genuinePageInfo.inheritParent);
      const genuinePartsIndex = coverPartsData ? 1 : 0;
      pages.forEach((v, i) => {
        if (
          (v.displayPageType || v.pageType)
          && (
            !option
            || genuinePageInfo?.inheritParent !== 'true'
            // || option?.parentOrderInfo?.xml?.orderPageDataArr.find((v) => v.indexes[0] === option.parentId && v.indexes[1] === String(genuinePageInfo) && v.indexes[2] === String(i + 1))
            || option?.parentOrderInfo?.parts?.partsData?.find((partsData) => partsData?.page?.pageData?.find((pageData) => Number(pageData.indexes[0]) === Number(option.parentId) && Number(pageData.indexes[1]) === genuinePageIndex && Number(pageData.indexes[2]) === i + 1))
          )
        ) {
          const page = XmlFactory.createFromIndex({
            shopOrderId,
            data: { name: 'order-page-data', indexes: [orderInfoDataIndex, genuinePageIndex, 1 + i] },
          });
          /* 元々ある order-page-data の情報を引き継ぐ */
          page.viewModel = v;
          if (favOrderPageDataList.length) {
            const preOrderPageData = favOrderPageDataList.find((pageData) => pageData.indexes[1] === page.indexes[1] && pageData.indexes[2] === page.indexes[2] && pageData.viewModel.pageType === page.viewModel.pageType);
            if (preOrderPageData) {
              page.metaModel = lodash.cloneDeep(preOrderPageData.metaModel);
              page.viewModel = lodash.cloneDeep(preOrderPageData.viewModel);
              page.viewModel.orderPicture?.data?.forEach((v) => {
                v.selectID = undefined;
                if (v.logoFlag === '1' && v.logoFileName) {
                  v.logoFileName.realPath = undefined;
                }
              });
              page.viewModel.orderTextImage?.data?.forEach((text) => {
                text.path = undefined;
              });
            }
          }
          if (preOrderInfoData?.xml.viewModel.item?.id === orderInfoData.viewModel.item?.id) {
            preOrderInfoData?.parts?.partsData?.forEach((partsData) => {
              const preOrderPageData = partsData.page?.pageData?.find((pageData) => pageData.indexes[0] === page.indexes[0] && pageData.indexes[1] === page.indexes[1] && pageData.indexes[2] === page.indexes[2] && pageData.viewModel.pageType === page.viewModel.pageType);
              if (preOrderPageData) {
                page.metaModel = lodash.cloneDeep(preOrderPageData.metaModel);
                page.viewModel = lodash.cloneDeep(preOrderPageData.viewModel);
                if (option?.fav) {
                  page.viewModel.orderPicture?.data?.forEach((v) => {
                    v.selectID = undefined;
                    if (v.logoFlag === '1' && v.logoFileName) {
                      v.logoFileName.realPath = undefined;
                    }
                  });
                  page.viewModel.orderTextImage?.data?.forEach((text) => {
                    text.path = undefined;
                  });
                }
              }
            });
          }
          page.rootTagModel.contentGroupID = option ? `op01op2_page${option.optionIndex > 0 ? `_multifid_${option.optionIndex}` : ''}` : (coverPartsData ? 'op01b_page' : 'op01b2_page');
          // page.build(v, {});
          page.build();
          files.push({
            filename: page.xmlUniqueName,
            body: page.xml,
            is_create: '1',
          });
          console.group(page.xmlUniqueName);
          console.log(page.xml);
          console.groupEnd();
          pageCount++;
          if (infoData.parts?.partsData?.[genuinePartsIndex].page?.pageData) {
            infoData.parts?.partsData?.[genuinePartsIndex].page?.pageData?.push(page);
          } else if (infoData.parts?.partsData) {
            if (genuinePartsData && infoData.parts.partsData?.[genuinePartsIndex] && pages.length) {
              /* order-page と order-page-data が存在する場合に order-parts-data のパスを設定 */
              genuinePartsData.metaModel = {
                path: genuinePage.xmlUniqueName,
              };
              infoData.parts.partsData[coverPartsData ? 1 : 0].page = {
                xml: genuinePage,
                pageData: [page],
              };
            }
          }
        }
      });
      if (infoData.parts?.partsData?.[genuinePartsIndex].page?.pageData?.length) {
        genuinePage.build({}, {
          paths: infoData.parts.partsData[genuinePartsIndex].page?.pageData?.map((v, i) => ({
            id: `${i + 1}`,
            path: v.xmlUniqueName,
          })),
        });
        files.push({
          filename: genuinePage.xmlUniqueName,
          body: genuinePage.xml,
          is_create: '1',
        });
        console.group(genuinePage.xmlUniqueName);
        console.log(genuinePage.xml);
        console.groupEnd();
      }
    }
    if (genuinePartsData) {
      // if (
      //   !genuinePartsData.metaModel.path
      //   && genuinePageInfo?.inheritParent === 'true'
      //   && option
      //   && !option.parentOrderInfo?.xml.orderPageDataArr.find((v) => Number(v.indexes[0]) === Number(option.parentId) && v.indexes[1] === genuinePartsData.indexes[1])
      // ) {
      //   genuinePartsData.viewModel.parentID = undefined;
      // }
      genuinePartsData.build();
      files.push({
        filename: genuinePartsData.xmlUniqueName,
        body: genuinePartsData.xml,
        is_create: '1',
      });
      console.group(genuinePartsData.xmlUniqueName);
      console.log(genuinePartsData.xml);
      console.groupEnd();
    }
  }
  /* プリント商材 */
  if (data.orderData.data.productDetail.productTypeId === 'jptg300164' && pageCount) {
    orderInfoData.viewModel.totalPageCount = `${pageCount}`;
    orderInfoData.build();
  }
  files.push({
    filename: orderInfoData.xmlUniqueName,
    body: orderInfoData.xml,
    is_create: '1',
  });
  if (!option && !data.structure.orderSelect && data.order.itemDetail.orderMethod.value !== '40') {
    structure.orderSelect = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-select' } });
    structure.orderSelect.build();
    files.push({
      filename: structure.orderSelect.xmlUniqueName,
      body: structure.orderSelect.xml,
      is_create: '1',
    });
    if (structure.info?.metaModel.path) {
      structure.info.metaModel.path.orderSelect = structure.orderSelect.xmlUniqueName;
      structure.info.build();
      files.push({
        filename: structure.info.xmlUniqueName,
        body: structure.info.xml,
        is_create: '0',
      });
    }
  }
  /* 生成した order-info-data の id を返す */
  return {
    id: `${orderInfoDataIndex}`,
    files,
    structure,
  };
};

// XMLアップロードはここで行う
const asyncActions = {
  shopOrder: (data: { kijshopCd: string, shopOrderId: string }) => ({
    create: (callback: { success: () => void, error: () => void }): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      if (!await XmlFactory.checkVersion()) {
        callback.error();
        return;
      }
      const isClear = await (() => {
        return new Promise<boolean>((resolve) => {
          dispatch(apiActions.run(
            new ApiAllGetXml(kijshopCd, shopOrderId),
            {
              onSuccess: (res: ResponseBase<GetXmlResponse>) => {
                if (!res?.body?.data?.fileinfo?.length) {
                  resolve(true);
                  return;
                }
                dispatch(apiActions.run(
                  new ApiAllDeleteXml(kijshopCd, shopOrderId),
                  {
                    onSuccess: () => {
                      resolve(true);
                    },
                    onError: () => {
                      resolve(false);
                    },
                  },
                  { ignoreSystemError: true },
                ));
              },
              onError: () => {
                resolve(true);
              },
            },
            { ignoreSystemError: true },
          ));
        });
      })();
      if (!isClear) {
        callback.error();
        return;
      }
      const info = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order' } });
      const customer = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'customer' } });
      const shop = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'shop' } });
      const delivery = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'delivery' } });
      const status = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'status' } });
      const summary = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'summary' } });
      const receptionSerialID = await new ApiReceptionSerialIdGet(kijshopCd).do()
        .then((res) => (res as ResponseBase<{ receptionSerialID: string }>)?.body?.data?.receptionSerialID || '')
        .catch(() => '');
      const isProxyMode = getState().systemConfig.metaData.surrogateOrder;
      info.build({}, {
        createDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS.sss'),
        // deliveredDate: '',
        orderDate: '',
        orderGUID: UuidGenerator.create(),
        orderID: shopOrderId,
        receptionSerialID,
        dpNumber: (isProxyMode) ? shopOrderId : undefined,
        path: {
          customer: customer.xmlUniqueName,
          shop: shop.xmlUniqueName,
          delivery: delivery.xmlUniqueName,
          status: status.xmlUniqueName,
          summary: summary.xmlUniqueName,
        },
      });
      customer.build({}, {});
      shop.build({}, {
        shopCode: (isProxyMode) ? shopOrderId.slice(0, 7) : kijshopCd, // kijShopCd
      });
      const store = getState().storage.data.deliveryStore;
      const deliveryType = (getState().storage.data.deliveryType === 'store' || getState().storage.data.deliveryType === '') ? 'shop' : 'customer';
      if (getState().storage.data.deliveryType === '') dispatch(localStorageActions.setData(kijshopCd, 'deliveryType', 'store'));
      const masterShopIdList = getState().common.masterShopIdList;
      const deliveryStore = (masterShopIdList.find((v) => v === store)) ? store
        : (masterShopIdList.find((v) => v === kijshopCd)) ? kijshopCd : masterShopIdList[0];
      dispatch(localStorageActions.setData(kijshopCd, 'deliveryStore', deliveryStore));
      if (isProxyMode) {
        delivery.build({
          type: deliveryType, // 配送先店舗選択
          shopCode: shopOrderId.slice(0, 7),
        }, {});
      } else {
        delivery.build({
          type: deliveryType, // 配送先店舗選択
          shopCode: (deliveryType === 'shop') ? deliveryStore : kijshopCd,
        }, {});
      }
      status.build({}, {
        data: [
          {
            finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS'),
            machineID: getState().storage.machineId,
            processID: 'reserve',
            startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS'),
            statusCode: '2',
          },
        ],
      });
      summary.di({
        info,
        shop,
        customer,
        delivery,
        status,
      });
      summary.build();
      dispatch(xmlActions.setShopOrder({
        shopOrderId,
        xml: {
          info,
          shop,
          customer,
          delivery,
          status,
          summary,
        },
      }));
      dispatch(apiActions.run(
        new ApiUploadXml(kijshopCd, shopOrderId, {
          files: [
            {
              filename: info.xmlUniqueName,
              body: info.xml,
              is_create: '1',
            },
            {
              filename: shop.xmlUniqueName,
              body: shop.xml,
              is_create: '1',
            },
            {
              filename: customer.xmlUniqueName,
              body: customer.xml,
              is_create: '1',
            },
            {
              filename: delivery.xmlUniqueName,
              body: delivery.xml,
              is_create: '1',
            },
            {
              filename: status.xmlUniqueName,
              body: status.xml,
              is_create: '1',
            },
            {
              filename: summary.xmlUniqueName,
              body: summary.xml,
              is_create: '1',
            },
          ],
        }),
        {
          onSuccess: callback.success,
          onError: callback.error,
        }
      ));
    },
    update: (xml: XmlStructureModel, callback?: () => void): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      // TODO Storeの値を直接見るか更新するXMLを引数で送るかは状況次第
      // const xml = getState().xml[shopOrderId];
      if (!xml || !await XmlFactory.checkVersion()) {
        console.log('shopOrderId: ', shopOrderId, ' - ', 'xml: ', xml);
        dispatch(dialogActions.pushMessage({
          title: 'エラー',
          message: [
            'すでに削除済みのオーダーのため編集できません。',
            '「OK」をクリックすると 注文一覧に戻ります。',
          ],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.popAll());
              dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
            }
          }],
        }));
        return;
      }
      const {
        info,
        shop,
        customer,
        delivery,
        status,
        summary,
      } = xml;
      summary.di(xml);
      summary.build();
      dispatch(apiActions.run(
        new ApiUploadXml(kijshopCd, shopOrderId, {
          files: [
            // {
            //   filename: info.xmlUniqueName,
            //   body: info.xml,
            //   is_create: '0',
            // },
            {
              filename: shop.xmlUniqueName,
              body: shop.xml,
              is_create: '0',
            },
            {
              filename: customer.xmlUniqueName,
              body: customer.xml,
              is_create: '0',
            },
            {
              filename: delivery.xmlUniqueName,
              body: delivery.xml,
              is_create: '0',
            },
            // {
            //   filename: status.xmlUniqueName,
            //   body: status.xml,
            //   is_create: '0',
            // },
            {
              filename: summary.xmlUniqueName,
              body: summary.xml,
              is_create: '0',
            },
          ],
        }),
        {
          onSuccess: () => {
            dispatch(xmlActions.getXml(kijshopCd, shopOrderId));
            callback && callback();
          },
        },
      ));
    },
    /**
     * code概要（暫定）
     * ・XML：フロントでのXML操作によるエラー
     * ・irregular：不足の事態
     * ・レスポンスのエラーコード
     * */
    labOrder: (callback: { success: () => void, error: (errorStatus?: { code: 'xml' | 'irregular', message: string[] }) => void }, isGetXml?: boolean, isRetry?: boolean ): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      // - ラボ発注に失敗した際、再度押下時はxmlの生成をskipする -
      if(isRetry) {
        dispatch(apiActions.run(
          // new ApiLabOrderPost({ kijshopCd, shopOrderId }),
          new ApiOrderRegister({ shopOrderId: shopOrderId }),
          {
            onSuccess: callback.success,
            onError: (e) => {
              callback.error({
                code: e?.error?.errorCode || 'irregular',
                message: String(e?.code) === '406' ? [/* リンクを含むため表示先で対応 */] : (Array.isArray(e?.error?.errorDetail) ? e.error.errorDetail : [e?.error?.errorDetail || '予期せぬエラーが発生しました']),
              })
            },
          },
          { ignoreSystemError: true },
        ));
        /* メタデータ更新 */
        dispatch(apiActions.run(
          new ApiMetaShopOrderGet({ kijshopCd, shopOrderId }),
          {
            onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
              if (res?.body?.data) {
                dispatch(apiActions.run(new ApiMetaShopOrderPost({
                  kijshopCd,
                  shopOrderId,
                  data: {
                    ...res.body.data,
                    lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                    orderDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS'),
                  },
                }), {}, { ignoreSystemError: true }));
              }
            },
          },
          { ignoreSystemError: true },
        ));
        return;
      }
      if (!await XmlFactory.checkVersion()) {
        callback.error({ code: 'xml', message: ['バージョン情報の取得に失敗しました'] });
        return;
      }
      const xml = isGetXml ? await new Promise<XmlStructureModel | null>((resolve) => {
        dispatch(xmlActions.getXml(kijshopCd, shopOrderId, (structure) => {
          resolve(structure);
        }));
      }) : getState().xml[shopOrderId];
      if (!xml) {
        callback.error({ code: 'xml', message: ['XMLの取得に失敗しました'] });
        return;
      }
      const structure = lodash.cloneDeep(xml);
      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1',
      }[] = [];
      /* 名前チェック */
      if (!structure?.customer?.xmlModel?.lnwCustomer.customer?.[0]?.data?.[0]?.name?.[0]?.$.lastName) {
        callback.error({ code: 'xml', message: ['お客様名が入力されていません', '【注文概要画面】にてお客様名を入力してください'] });
        return;
      }
      /* 配送情報チェック */
      if (
        structure?.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0].$.type === 'customer'
        && (
          !structure.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.deliveryCustomerName?.[0]?.$.name
          || !structure.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.address?.[0]?.$.zipCode
          || !structure.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.address?.[0]?.$.state
          || !structure.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.address?.[0]?.$.address1
          || !structure.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.phone?.[0]?.$.phoneNumber
          || !structure.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.deliveryTimeZone?.[0]?.$.timeZone
          || !structure.delivery.xmlModel.lnwDelivery.delivery?.[0]?.data?.[0]?.deliveryFromName?.[0]?.$.name
          || !structure.delivery.xmlModel.lnwDelivery.report?.[0]?.data?.[0]?.shipmentForm?.[0]?.$.name
        )
      ) {
        callback.error({ code: 'xml', message: ['お客様の配送情報の入力が不足しています', '【注文概要画面】の「配送情報登録」より不足情報を入力してください'] });
        return;
      }
      /* 発送帳票コピー */
      if (structure.delivery.viewModel.orderFormGUID) {
        console.log('move order form');
        const flag = await new Promise<boolean>((resolve) => {
          new ApiOrderFormCopy({
            kijshopCd,
            shopOrderId,
            orderformId: structure.delivery.viewModel.orderFormGUID || '',
          })
            .do()
            .then((res) => String((res as ResponseBase<any>)?.error?.errorCode) === '200' ? resolve(true) : resolve(false))
            .catch(() => resolve(false));
        });
        console.log('end');
        if (!flag) {
          if (!isGetXml) {
            dispatch(dialogActions.pushMessage({
              title: '確認',
              message: [
                '「発送帳票」で指定したPDFが見つかりません',
                '【注文概要画面】の「配送情報登録」にてPDFを指定してください',
              ],
              buttons: [{
                label: 'OK',
                callback: () => {
                  dispatch(dialogActions.pop());
                },
              }],
            }));
          }
          callback.error({ code: 'xml', message:  ['「発送帳票」で指定したPDFが見つかりません', '【注文概要画面】の「配送情報登録」にてPDFを指定してください'] });
          return;
        }
        structure.delivery.viewModel.pdfPath = '/document/1100001.pdf';
        structure.delivery.build();
        files.push({
          filename: structure.delivery.xmlUniqueName,
          body: structure.delivery.xml,
          is_create: '0',
        });
      }
      structure.info.viewModel.orderMethod = structure.info.metaModel.dpNumber ? '3' : '1';
      structure.info.metaModel.orderDate = DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS');
      structure.info.build();
      const getImages = async (kind: string): Promise<ImagesGetResponse[]> => {
        return await new ApiImagesGet({
          kijshopCd,
          shopOrderId,
          kind,
        })
          .do()
          .then((res) => (res as any as ResponseBase<ImagesGetResponse[]>)?.body?.data || [])
          .catch(() => []);
      };
      /* 完成画像一覧 */
      const compImageList = await getImages('1');
      /* サムネイル */
      const thumbImageList = await getImages('2');
      /* ロゴ、テキスト */
      const exceptImageList = await getImages('4');
      /* PNG */
      const pngImageList = await getImages('5');
      /* アップロード画像(セレクトID割り当て) */
      const uploadImageList = await getImages('6');
      const uploadImagesSetList: {
        id: string,
        selectId: string,
      }[] = [];
      uploadImageList.forEach((v) => {
        const data = structure.orderSelect?.xmlModel.lnwOrderSelect?.orderSelect?.[0]?.data?.find((data) => data.selectFileName?.[0]?.real?.[0]?.$.path === v.filename);
        if (data && data.$.selectID) {
          uploadImagesSetList.push({
            id: v.id,
            selectId: data.$.selectID,
          });
        }
      });
      structure.orderSelect?.metaModel.imageData?.forEach((v) => {
        v.selectCode = '0';
      });
      /* 使用画像精査 */
      let cnt = 1;
      let moveImage: '' | 'comp' | 'use' | 'thumb' | 'logo' | 'text' | 'png' = '';
      const convertSelectIDList: string[] = [];
      for (let i = 0; i < (structure.orderInfo?.infoData?.length || 0); i++) {
        if (moveImage) {
          break;
        }
        const infoData = structure.orderInfo?.infoData?.[i];
        const orderMethodId = infoData?.xml.viewModel.orderMethod?.id || (infoData?.xml.metaModel.parentId && structure.orderInfo?.infoData?.find((v) => v.xml?.metaModel.id === infoData.xml.metaModel.parentId)?.xml?.viewModel.orderMethod?.id);
        /* order-page-break (画像を選択しておまかせ) */
        if (orderMethodId === '10' && infoData?.pageBreak && structure.orderSelect) {
          console.group(infoData.xml.xmlUniqueName);
          for (let j = 0; j < (infoData.pageBreak.viewModel.data?.image?.length || 0); j++) {
            const v = infoData.pageBreak.viewModel.data?.image?.[j];
            /* order-page-break をみて使用画像を取得 */
            if (!moveImage && v?.selectID) {
              const data = structure.orderSelect?.metaModel.imageData?.find((image) => image.selectID === v.selectID);
              const srcFilename = data?.selectFileName?.real.path.indexOf('R_PPM_') === -1 ? data?.selectFileName?.real.path : uploadImageList.find((image) => image.selectId === v.selectID)?.filename;
              /* order-select で登録された画像と紐付け */
              if (data && srcFilename && !convertSelectIDList.find((id) => id === v.selectID)) {
                convertSelectIDList.push(v.selectID);
                const fileName = `R_PPM_${shopOrderId}_01_${data.selectPictureID || padding(Number(data.id) || cnt, 5)}.jpg`;
                console.log('load: ', fileName);
                /* 使用画像アップロード */
                if (srcFilename.toString().indexOf('R_PPM_') === -1) {
                  console.log('wait');
                  moveImage = await (() => {
                    return new Promise<TMoveImage>((resolve) => {
                      dispatch(apiActions.run(
                        new ApiImagesCopyPost({
                          kijshopCd,
                          shopOrderId,
                          srcKind: '6',
                          dstKind: '3',
                          srcFilename,
                          dstFilename: fileName,
                        }),
                        {
                          onSuccess: (res) => {
                            console.log('success: ', res);
                            resolve('');
                          },
                          onError: (e) => {
                            console.log('error: ', e);
                            resolve('use');
                          },
                        },
                        { ignoreSystemError: true },
                      ));
                    });
                  })();
                  if (moveImage) {
                    break;
                  }
                  cnt++;
                  /* order-select 更新 */
                  if (data.selectFileName?.real) {
                    data.selectFileName.real.path = `Real/01/${fileName}`;
                    data.selectCode = '1';
                  }
                }
                console.log('load end');
              }
            }
          }
          infoData.pageBreak.build();
          files.push({
            filename: infoData.pageBreak.xmlUniqueName,
            body: infoData.pageBreak.xml,
            is_create: '0',
          });
          console.groupEnd();
        }
        /* 簡易レイアウト, 完全レイアウト */
        if ((orderMethodId === '20' || orderMethodId === '30') && infoData?.parts?.partsData?.length) {
          console.group(infoData.xml.xmlUniqueName);
          /* order-parts-data をループ */
          for (let j = 0; j < (infoData.parts.partsData.length || 0); j++) {
            if (moveImage) {
              break;
            }
            const orderPartsData = infoData.parts.partsData[j];
            if (orderPartsData?.page?.pageData?.length) {
              /* order-page-data をループ */
              for (let k = 0; k < orderPartsData.page.pageData.length; k++) {
                const orderPageData = orderPartsData.page.pageData[k];
                if (!moveImage && orderPageData?.viewModel.compositeFileName?.virtualPath) {
                  /* サムネイル精査 */
                  if (!thumbImageList.find((v) => `Comp_Virtual/${v.filename}` === orderPageData.viewModel.compositeFileName?.virtualPath)) {
                    console.log('fail to load: ', orderPageData.viewModel.compositeFileName.virtualPath);
                    moveImage = 'thumb';
                    break;
                  }
                }
                if (!moveImage && orderPageData?.viewModel.freeGraphic?.data?.length) {
                  /* PNG画像精査 */
                  orderPageData.viewModel.freeGraphic.data.forEach((freeGraphic) => {
                    if (freeGraphic.virtual?.path && !pngImageList.find((v) => `FreeGraphics/${v.filename}` === freeGraphic.virtual?.path)) {
                      console.log('fail to load: ', freeGraphic.virtual.path);
                      moveImage = 'png';
                    }
                  });
                  if (moveImage) {
                    break;
                  }
                }
                if (!moveImage && orderPageData?.viewModel.orderTextImage?.data?.length) {
                  /* テキスト精査 */
                  orderPageData.viewModel.orderTextImage.data.forEach((orderText) => {
                    if (orderText.path && !exceptImageList.find((v) => `Except_Real/${v.filename}` === orderText.path)) {
                      console.log('fail to load: ', orderText.path);
                      moveImage = 'text';
                    }
                  });
                  if (moveImage) {
                    break;
                  }
                }
                if (!moveImage && orderPageData?.viewModel.orderPicture?.data?.length) {
                  /* order-page-data の orderPicture から使用画像を精査 */
                  for (let l = 0; l < orderPageData.viewModel.orderPicture.data.length; l++) {
                    const v = orderPageData.viewModel.orderPicture.data[l];
                    /* order-page-data をみて使用画像を取得 */
                    if (v?.selectID && v?.deleteFlag !== '1' && v?.logoFlag !== '1' && !convertSelectIDList.find((id) => id === v.selectID)) {
                      const data = structure.orderSelect?.metaModel.imageData?.find((image) => image.selectID === v?.selectID);
                      const srcFilename = data?.selectFileName?.real.path.indexOf('R_PPM_') === -1 ? data?.selectFileName?.real.path : uploadImageList.find((image) => image.selectId === v.selectID)?.filename;
                      /* order-select で登録された画像と紐付け */
                      if (data && srcFilename) {
                        convertSelectIDList.push(v.selectID);
                        const fileName = `R_PPM_${shopOrderId}_01_${data.selectPictureID || padding(Number(data.id) || cnt, 5)}.jpg`;
                        console.log('load: ', fileName);
                        /* 使用画像アップロード */
                        if (srcFilename.toString().indexOf(fileName) === -1) {
                          console.log('wait');
                          moveImage = await (() => {
                            return new Promise<TMoveImage>((resolve) => {
                              dispatch(apiActions.run(
                                new ApiImagesCopyPost({
                                  kijshopCd,
                                  shopOrderId,
                                  srcKind: '6',
                                  dstKind: '3',
                                  srcFilename,
                                  dstFilename: fileName,
                                }),
                                {
                                  onSuccess: (res) => {
                                    console.log('success: ', res);
                                    resolve('');
                                  },
                                  onError: (e) => {
                                    console.log('error: ', e);
                                    resolve('use');
                                  },
                                },
                                { ignoreSystemError: true },
                              ));
                            });
                          })();
                          if (moveImage) {
                            break;
                          }
                          cnt++;
                          /* order-select 更新 */
                          if (data.selectFileName?.real) {
                            data.selectFileName.real.path = `Real/01/${fileName}`;
                            data.selectCode = '1';
                          }
                        }
                        console.log('load end');
                      }
                    }
                    if (!moveImage && v?.deleteFlag !== '1' && v?.logoFlag === '1' && v?.logoFileName?.realPath) {
                      /* ロゴ精査 */
                      if (!exceptImageList.find((image) => `Except_Real/${image.filename}` === v?.logoFileName?.realPath)) {
                        console.log('fail to load: ', v.logoFileName.realPath);
                        moveImage = 'logo';
                      }
                    }
                  }
                }
              }
            }
          }
          console.groupEnd();
        }
        /* 完成画像（チェックのみ） */
        if (orderMethodId === '40' && infoData?.parts?.partsData?.length) {
          console.group(infoData.xml.xmlUniqueName);
          structure.orderInfo?.infoData?.forEach((data) => {
            if (data.xml.metaModel.parentId === infoData.xml.metaModel.id || data.xml.metaModel.id === infoData.xml.metaModel.id) {
              data?.parts?.partsData?.forEach((partsData) => partsData?.page?.pageData?.forEach((pageData) => {
                if (pageData?.viewModel.compositeFileName?.realPath && !compImageList.find((v) => `Comp_Real/${v.filename}` === pageData?.viewModel.compositeFileName?.realPath)) {
                  console.log('fail to load: ', pageData?.viewModel.compositeFileName?.realPath);
                  moveImage = 'comp';
                }
              }));
            }
          });
          console.groupEnd();
        }
      }
      if (moveImage) {
        const message = (() => {
          switch (moveImage) {
            case 'comp':
              return ['アップロードされた完成画像が見つかりません', '【注文概要画面】より再度、画像をアップロードしてください'];
            case 'use':
              return ['アップロードした画像に破損が見つかりました', '再度、画像をアップロードしてください', '※破損したファイルが残っている場合は、先に削除してください'];
            case 'thumb':
              return ['サムネイル画像に破損が見つかりました', '再度、【レイアウト画面】で「レイアウト完了」ボタンをクリックしてください'];
            case 'logo':
              return ['ロゴ画像に破損が見つかりました', '再度、【レイアウト画面】でロゴ画像を配置し「レイアウト完了」ボタンをクリックしてください'];
            case 'text':
              return ['入力されたテキスト情報が読み込み出来ません', '再度、【レイアウト画面】で「レイアウト完了」ボタンをクリックしてください'];
            case 'png':
              return ['配置されたPNG画像が読み込み出来ません', '再度、【レイアウト画面】でPNG画像を配置してください'];
          }
        })();
        if (!isGetXml) {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message,
            buttons: [{
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            }],
          }));
        }
        callback.error({ code: 'xml', message });
        return;
      }
      /* 注文が削除されたかチェック */
      if (
        !await (() => {
          return new Promise<boolean>((resolve) => {
            dispatch(apiActions.run(
              new ApiMetaShopOrderGet({ kijshopCd, shopOrderId }),
              {
                onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => resolve(Boolean(res?.body?.data?.createDate)),
                onError: () => resolve(false),
              },
              { ignoreSystemError: true },
            ));
          });
        })()
      ){
        if (!isGetXml) {
          dispatch(dialogActions.pushMessage({
            title: '確認',
            message: ['注文情報の取得に失敗しました。'],
            buttons: [{
              label: 'OK',
              callback: () => {
                dispatch(dialogActions.pop());
              },
            }],
          }));
        }
        callback.error({ code: 'xml', message: ['注文情報の取得に失敗しました。'] });
        return;
      }
      if (cnt > 1) {
        if (structure.orderSelect) {
          /* 使用していない画像の selectCode を更新 */
          structure.orderSelect.metaModel.imageData?.forEach((imageData) => {
            if (!convertSelectIDList.find((id) => id === imageData.selectID)) {
              imageData.selectCode = '0';
            }
          });
          structure.orderSelect.build();
          files.push(({
            filename: structure.orderSelect.xmlUniqueName,
            body: structure.orderSelect.xml,
            is_create: '0',
          }));
        }
      }
      /* status 更新 */
      if (structure.status.metaModel.data) {
        const machineID = getState().storage.machineId;
        const labOrderStatusData = structure.status.metaModel.data?.findIndex((v) => v.processID === 'jptg380042') || -1;
        const date = DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS');
        if (labOrderStatusData !== -1) {
          structure.status.metaModel.data[labOrderStatusData].startDate = date;
          structure.status.metaModel.data[labOrderStatusData].finishDate = date;
          structure.status.metaModel.data[labOrderStatusData].machineID = getState().storage.machineId;
        } else {
          structure.status.metaModel.data.push({
            startDate: date,
            finishDate: date,
            machineID,
            processID: 'jptg380042',
            statusCode: '2',
          });
        }
        structure.status.metaModel.data.forEach((v) => {
          if (v.machineID !== machineID) {
            v.machineID = machineID;
          }
        });
        structure.status.build();
        files.push({
          filename: structure.status.xmlUniqueName,
          body: structure.status.xml,
          is_create: '0',
        });
      }
      structure.summary.di(structure);
      structure.summary.build();
      files.push(
        {
          filename: structure.info.xmlUniqueName,
          body: structure.info.xml,
          is_create: '0',
        },
        {
          filename: structure.summary.xmlUniqueName,
          body: structure.summary.xml,
          is_create: '0',
        },
      );
      dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }),
        {
          onSuccess: () => {
            dispatch(apiActions.run(
              // new ApiLabOrderPost({ kijshopCd, shopOrderId }),
              new ApiOrderRegister({ shopOrderId: shopOrderId }),
              {
                onSuccess: callback.success,
                onError: (e) => {
                  callback.error({
                    code: e?.error?.errorCode || 'irregular',
                    message: String(e?.code) === '406' ? [/* リンクを含むため表示先で対応 */] : (Array.isArray(e?.error?.errorDetail) ? e.error.errorDetail : [e?.error?.errorDetail || '予期せぬエラーが発生しました']),
                  })
                },
              },
              { ignoreSystemError: true },
            ));
          },
          onError: (e) => callback.error(e?.error?.errorCode),
        },
        { ignoreSystemError: true },
      ));
      dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      /* メタデータ更新 */
      dispatch(apiActions.run(
        new ApiMetaShopOrderGet({ kijshopCd, shopOrderId }),
        {
          onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
            if (res?.body?.data) {
              dispatch(apiActions.run(new ApiMetaShopOrderPost({
                kijshopCd,
                shopOrderId,
                data: {
                  ...res.body.data,
                  lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                  status: 'ラボ発注中',
                  orderDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS'),
                },
              }), {}, { ignoreSystemError: true }));
            }
          },
        },
        { ignoreSystemError: true },
      ));
      /* アップロード画像の selectID 割り当て */
      dispatch(apiActions.run(
        new ApiImagesUpdatePost({
          kijshopCd,
          shopOrderId,
          images: uploadImagesSetList,
        }),
        {},
        { ignoreSystemError: true },
      ));
    },
  }),
  order: (data: { kijshopCd: string, shopOrderId: string, callback?: { success?: () => void, error?: () => void, } }) => ({
    create: (order: OrdersSliceState, orderData: OrdersDataState, visibleCondition: VisibleConditionValueGetter, isComp: boolean, createDate: string): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId, callback } = data;
      if (!await XmlFactory.checkVersion()) {
        data?.callback?.error?.();
        return;
      }
      const xml = getState().xml[shopOrderId];
      const fav = getState().createOrder.favData;
      if (fav) {
        console.group();
        console.log(fav);
        console.groupEnd();
      }
      if (!xml) {
        console.log('shopOrderId: ', shopOrderId, ' - ', 'xml: ', xml);
        callback?.error?.();
        dispatch(dialogActions.pushMessage({
          title: 'エラー',
          message: [
            'すでに削除済みのオーダーのため編集できません。',
            '「OK」をクリックすると 注文一覧に戻ります。',
          ],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.popAll());
              dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
            }
          }],
        }));
        return;
      }
      /* ファイルアップロード用 */
      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1',
      }[] = [];
      /* store のデータをクローン */
      const structure = lodash.cloneDeep(xml);
      /* index 決定 */
      const orderInfoDataCurrentIndex = Math.max(...((structure.orderInfo?.infoData || []).map((v) => Number(v.xml.indexes?.[0]))));
      const orderInfoDataIndex = 1 + (orderInfoDataCurrentIndex > 0 ? orderInfoDataCurrentIndex : 0);
      /* 親商品とオプション商品のデータ生成 */
      const orderInfoDataModel = createOrderInfoDataViewModel(order, orderData, visibleCondition);
      /* 親商品のXML生成（親商品は id を取得） */
      const parentData = createXml(
        {
          shopOrderId,
          structure,
          order,
          orderData,
          isNeedAgreement: getState().systemConfig.metaData.isNeedAgreement,
          keepData: fav ? { fav: fav.parent } : undefined,
          visibleCondition,
        },
        orderInfoDataModel.parent,
        orderInfoDataIndex,
      );
      files.push(...parentData.files);
      /* オプションのXML生成 */
      const arr: {
        id: string,
        files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[],
        structure: Partial<XmlStructureModel>,
      }[] = [];
      for (let index = 0; index < orderInfoDataModel.options.length; index++) {
        const v = orderInfoDataModel.options[index];
        const res = await new ApiImageCheckPost({
          kijshopCd,
          page: 0,
          goodsId: v.model.item?.id || '',
        }).do().then((res) => res).catch(() => ({ body: { data: [] } })) as ResponseBase<ImageCheckPostResponse[]>;
        arr.push(createXml(
          {
            shopOrderId,
            structure,
            order,
            orderData,
            isNeedAgreement: getState().systemConfig.metaData.isNeedAgreement,
            keepData: fav ? { option: fav.option } : undefined,
            visibleCondition,
          },
          v.model,
          orderInfoDataIndex + index + 1,
          {
            optionIndex: v.optionIndex,
            parentId: parentData.id,
            parentOrderInfo: parentData.structure.orderInfo?.infoData?.find((infoData) => infoData.xml?.metaModel.id === parentData.id),
            needUpload: Boolean(res?.body?.data?.[0]?.length),
            fav: Boolean(fav),
          },
        ));
      }
      // const arr = orderInfoDataModel.options.map(
      //   (v, index) => createXml(
      //     {
      //       shopOrderId,
      //       structure,
      //       order,
      //       orderData,
      //       isNeedAgreement: getState().systemConfig.metaData.isNeedAgreement,
      //     },
      //     v.model,
      //     orderInfoDataIndex + index + 1,
      //     { optionIndex: v.optionIndex, parentId: parentData.id, parentOrderInfo: parentData.structure.orderInfo?.infoData?.find((infoData) => infoData.xml?.metaModel.id === parentData.id) },
      //   ),
      // );
      arr.forEach((v) => files.push(...v.files));
      /* order-info 更新 */
      const orderInfo = structure.orderInfo?.xml;
      if (orderInfo) {
        orderInfo.build();
        files.push({
          filename: orderInfo.xmlUniqueName,
          body: orderInfo.xml,
          is_create: '0',
        });
        /* order-info 新規作成時に info 更新 */
        if (structure.info.metaModel.path && !structure.info.metaModel.path?.orderInfo) {
          structure.info.metaModel.path.orderInfo = orderInfo.xmlUniqueName;
          structure.info.build();
          const index = files.findIndex((v) => v.filename === structure.info.xmlUniqueName);
          if (index !== -1) {
            files.splice(index, 1);
          }
          files.push({
            filename: structure.info.xmlUniqueName,
            body: structure.info.xml,
            is_create: '0',
          });
        }
        /* status 更新 */
        const statusProcess = ((execute: { [key: string]: [''] }, surrogate: { [key: string]: [''] }) => {
          // const execute = orderInfoDataModel.parent.executeProcess || {};
          // const surrogate = orderInfoDataModel.parent.surrogateProcess?.data || {};
          const arr: string[] = [];
          Object.keys(execute).forEach((key) => {
            if (!Object.keys(surrogate).find((v) => v === key)) {
              arr.push(key);
            }
          });
          return arr;
        });
        /* 親商品 status */
        if (!structure.status.metaModel.orderInfoData) {
          structure.status.metaModel.orderInfoData = [];
        }
        structure.status.metaModel.orderInfoData.push({
          id: parentData.id,
          data: statusProcess(orderInfoDataModel.parent.executeProcess || {}, orderInfoDataModel.parent.surrogateProcess?.data || {}).map((v) => ({
            finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
            machineID: getState().storage.machineId,
            processID: v,
            // startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
            startDate: createDate,
            statusCode: (v === processId.order || v === processId.layoutConfirm || orderInfoDataModel.parent.orderMethod?.id === '40') ? (isComp ? '1' : '0') : '0',
          })),
        });
        /* オプション商品 status */
        orderInfoDataModel.options.forEach((op) => {
          if (op.model.executeProcess) {
            const data = statusProcess(op.model.executeProcess, op.model.surrogateProcess?.data || {}).map((v) => ({
              finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
              machineID: getState().storage.machineId,
              processID: v,
              startDate: createDate,
              statusCode: (v === processId.order || v === processId.layoutConfirm || orderInfoDataModel.parent.orderMethod?.id === '40') ? '1' : '0',
            }));
            if (data.length) {
              structure.status.metaModel.orderInfoData?.push({
                id: `${Number(parentData.id) + op.optionIndex + 1}`,
                data,
              });
            }
          }
        });
        structure.status.build();
        /* summary 更新 */
        structure.summary.di(structure);
        structure.summary.build();
        files.push({
          filename: structure.status.xmlUniqueName,
          body: structure.status.xml,
          is_create: '0',
        }, {
          filename: structure.summary.xmlUniqueName,
          body: structure.summary.xml,
          is_create: '0',
        });
        /* ファイルアップロード */
        dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
          onSuccess: data.callback?.success,
          onError: data.callback?.error,
        }));
        /* お気に入り商品を読み込んだ場合は画像設置 */
        if (fav) {
          dispatch(apiActions.run(new ApiFavoriteCopyPost({
            kijshopCd,
            shopOrderId,
            favProductId: fav.parent.id,
          })));
        }
        /* store の xml 情報更新 */
        dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      }
    },
    edit: (order: OrdersSliceState, orderData: OrdersDataState, visibleCondition: VisibleConditionValueGetter, orderInfoDataId: string, isComp: boolean, isNeedAgreement: boolean): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId, callback } = data;
      if (!await XmlFactory.checkVersion()) {
        data?.callback?.error?.();
        return;
      }
      const xml = getState().xml[shopOrderId];
      if (!xml?.orderInfo) {
        console.log('shopOrderId: ', shopOrderId, ' - ', 'xml: ', xml);
        callback?.error?.();
        dispatch(dialogActions.pushMessage({
          title: 'エラー',
          message: [
            'すでに削除済みのオーダーのため編集できません。',
            '「OK」をクリックすると 注文一覧に戻ります。',
          ],
          buttons: [{
            label: 'OK',
            callback: () => {
              dispatch(dialogActions.popAll());
              dispatch(push(RoutingPath.cnv.orders({ kijshopCd })));
            }
          }],
        }));
        return;
      }
      /* 処理を終えるまで元データを残すために一度新規に作成 */
      const structure: Partial<XmlStructureModel> = {};
      /* 親商品とオプション商品のデータ生成 */
      const orderInfoDataModel = createOrderInfoDataViewModel(order, orderData, visibleCondition);
      /* 親商品のXML生成（親商品は id を取得） */
      const parentData = createXml(
        {
          shopOrderId,
          structure,
          order,
          orderData,
          isNeedAgreement,
          keepData: { xml },
          visibleCondition,
        },
        orderInfoDataModel.parent,
        Number(orderInfoDataId),
      );
      /* 編集した商品のステータスを変更 */
      xml.status.metaModel.orderInfoData?.forEach((orderInfoData) => {
        if (orderInfoData.id === orderInfoDataId) {
          const data = orderInfoData.data.find((v) => v.processID === processId.order);
          if (data) {
            data.statusCode = isComp ? '1' : '0';
          }
        }
      });
      /* オプションのXML生成 */
      const arr: {
        id: string,
        files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[],
        structure: Partial<XmlStructureModel>,
      }[] = [];
      for (let index = 0; index < orderInfoDataModel.options.length; index++) {
        const v = orderInfoDataModel.options[index];
        const res = await new ApiImageCheckPost({
          kijshopCd,
          page: 0,
          goodsId: v.model.item?.id || '',
        }).do().then((res) => res).catch(() => ({ body: { data: [] } })) as ResponseBase<ImageCheckPostResponse[]>;
        arr.push(createXml(
          {
            shopOrderId,
            structure,
            order,
            orderData,
            isNeedAgreement: getState().systemConfig.metaData.isNeedAgreement,
            keepData: { xml },
            visibleCondition,
          },
          v.model,
          Number(orderInfoDataId) + index + 1,
          {
            optionIndex: v.optionIndex,
            parentId: parentData.id,
            parentOrderInfo: parentData.structure.orderInfo?.infoData?.find((infoData) => infoData.xml?.metaModel.id === parentData.id),
            needUpload: Boolean(res?.body?.data?.[0]?.length),
            fav: false,
          },
        ));
      }
      // const arr = orderInfoDataModel.options.map(
      //   (v, index) => createXml(
      //     {
      //       shopOrderId,
      //       structure,
      //       order,
      //       orderData,
      //       isNeedAgreement,
      //     },
      //     v.model,
      //     Number(orderInfoDataId) + index + 1,
      //     { optionIndex: v.optionIndex, parentId: parentData.id, parentOrderInfo: parentData.structure.orderInfo?.infoData?.find((infoData) => infoData.xml?.metaModel.id === parentData.id) },
      //   ),
      // );
      /* ファイル増減分の差分 */
      const optionOrderInfoDataArr = [...(xml.orderInfo?.infoData || [])].map((v) => v.xml.metaModel.parentId && v.xml.metaModel.parentId === orderInfoDataId ? v : null).filter((v) => !!v);
      /* 親商品に紐づくオプション商品のうち id が最大値のものを取得 */
      const preMaxIndex = optionOrderInfoDataArr.length ? Math.max(...optionOrderInfoDataArr.map((v) => Number(v?.xml.metaModel.id))) : Number(orderInfoDataId);
      const maxIndex = arr.length ? Math.max(...arr.map((v) => Number(v.id))) : Number(orderInfoDataId);
      /* ファイル数が異なる場合 */
      if (preMaxIndex !== maxIndex) {
        if (preMaxIndex > maxIndex) {
          for (let i = maxIndex + 1; i <= preMaxIndex; i++) {
            const index = (structure.orderInfo?.infoData || []).findIndex((v) => v.xml.metaModel.id === String(i));
            if (index !== -1) {
              structure.orderInfo?.infoData?.splice(index, 1);
            }
          }
        }
        /* 更新が必要な画像を保持 */
        const updateFiles: { kind: '1' | '2' | '3' | '4' | '5' | '6', dir: string, before: string, after: string }[] = [];
        /* 次の親商品の order-info-data を取得 */
        const arr = [...(xml.orderInfo?.xml.metaModel.parentSequence || [])];
        arr.sort((a, b) => Number(a.id) - Number(b.id));
        const nextOrderInfoDataId = arr.find((v) => Number(v.id) > Number(orderInfoDataId))?.id;
        /* オプション削除によって order-info-data が増減した際 */
        if (nextOrderInfoDataId) {
          /* 編集した orderInfoData 以外の orderInfoData を格納 */
          const renameOrderInfoData: XmlStructureOrderInfoData[] = [];
          /* 更新後と更新前の index 差分をみる */
          const num = maxIndex - preMaxIndex;
          /* その商品と以降の order-info-data の id を更新する */
          xml.orderInfo?.infoData?.forEach((orderInfoData) => {
            if (orderInfoData.xml.metaModel.id === orderInfoDataId || orderInfoData.xml.metaModel.parentId === orderInfoDataId) {
              return;
            }
            renameOrderInfoData.push(orderInfoData);
            /* 元データの該当商品以前のidは精査対象外 */
            // if (Number(orderInfoData.xml.metaModel.id) <= maxIndex) {
            // if (Number(orderInfoData.xml.metaModel.id) <= Number(orderInfoDataId) || (orderInfoData.xml.metaModel.parentId && Number(orderInfoData.xml.metaModel.parentId) <= Number(orderInfoDataId))) {
            if (Number(orderInfoData.xml.metaModel.id) <= preMaxIndex) {
              return;
            }
            const orderMethod = !orderInfoData.xml.metaModel.parentId
              ? orderInfoData.xml.viewModel.orderMethod?.id
              : xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderInfoData.xml.metaModel.parentId)?.xml.viewModel.orderMethod?.id;
            const newOrderInfoDataId = String(Number(orderInfoData.xml.metaModel.id) + num);
            /* 親商品なら紐づくオプションの parentID と status の id を更新 */
            // if (structure.orderInfo?.xml.metaModel.parentSequence?.find((v) => v.id !== orderInfoDataId && v.id === orderInfoData.xml.metaModel.id)) {
            if (xml.orderInfo?.xml.metaModel.parentSequence?.find((v) => v.id !== orderInfoDataId && v.id === orderInfoData.xml.metaModel.id)) {
              xml.orderInfo?.infoData?.forEach((v, i) => {
                if (v.xml.metaModel.parentId === orderInfoData.xml.metaModel.id && !xml.orderInfo?.infoData?.find((data, j) => j > i && data.xml.metaModel.parentId === v.xml.metaModel.parentId)) {
                  v.xml.metaModel.parentId = newOrderInfoDataId;
                }
              });
              xml.status.metaModel.orderInfoData?.forEach((v, i) => {
                if (v.id === orderInfoData.xml.metaModel.id && !xml.status.metaModel.orderInfoData?.find((data, j) => j > i && data.id === v.id)) {
                  v.id = newOrderInfoDataId;
                }
              });
            }
            /* order-info-data の id 更新 */
            orderInfoData.xml.changeIndexes([newOrderInfoDataId]);
            orderInfoData.xml.metaModel.id = newOrderInfoDataId;
            /* order-page-break の id 更新 */
            orderInfoData.pageBreak?.changeIndexes([newOrderInfoDataId]);
            /* order-parts の id 更新 */
            orderInfoData.parts?.xml.changeIndexes([newOrderInfoDataId]);
            /* order-info-data のパス情報更新 */
            orderInfoData.xml.metaModel.path = (orderInfoData.pageBreak || orderInfoData.parts) && {
              orderPageBreak: orderInfoData.pageBreak?.xmlUniqueName,
              orderParts: orderInfoData.parts?.xml.xmlUniqueName,
            };
            orderInfoData.xml.build();
            orderInfoData.parts?.partsData?.forEach((orderPartsData) => {
              /* order-parts-data の id 更新 */
              orderPartsData.xml.changeIndexes([newOrderInfoDataId, orderPartsData.xml.indexes[1]]);
              /* order-page の id 更新 */
              orderPartsData.page?.xml.changeIndexes([newOrderInfoDataId, orderPartsData.page.xml.indexes[1]]);
              /* order-parts-data のパス情報更新 */
              if (orderPartsData.page?.xml) {
                orderPartsData.xml.metaModel.path = orderPartsData.page.xml.xmlUniqueName;
                orderPartsData.xml.build();
              }
              orderPartsData.page?.pageData?.forEach((orderPageData) => {
                /* order-page-data の id 更新 */
                orderPageData.changeIndexes([newOrderInfoDataId, orderPageData.indexes[1], orderPageData.indexes[2]]);
                const indexes = orderPageData.indexes;
                /* 画像の更新が必要なものはXML内の記述のみここで更新してファイル自体は後述の処理で行う */
                if (orderMethod === '20' || orderMethod === '30') {
                  /* サムネイル */
                  if (orderPageData.viewModel?.compositeFileName?.virtualPath) {
                    const dir = 'Comp_Virtual';
                    const name = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01.jpg`;
                    updateFiles.push({
                      kind: '2',
                      dir,
                      before: orderPageData.viewModel?.compositeFileName.virtualPath.split('/')[1],
                      after: name,
                    });
                    orderPageData.viewModel.compositeFileName.virtualPath = `${dir}/${name}`;
                  }
                  /* テキスト */
                  const textImageList = orderPageData.viewModel?.orderTextImage?.data;
                  if (textImageList) {
                    textImageList.forEach((v) => {
                      const fileIndex = v.path?.split('/')?.[1]?.split('.')?.[0]?.split('_')?.[6];
                      if (v.path) {
                        const dir = 'Except_Real';
                        const name = `ER_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_${fileIndex}.png`
                        updateFiles.push({
                          kind: '4',
                          dir,
                          before: v.path.split('/')[1],
                          after: name,
                        });
                        v.path = `${dir}/${name}`;
                      }
                    });
                  }
                }
                if (orderMethod === '40') {
                  /* 完成画像 */
                  if (orderPageData.viewModel.compositeFileName?.realPath) {
                    const dir = 'Comp_Real';
                    const ext = orderPageData.viewModel.compositeFileName.realPath.split('.')[1];
                    const fileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01`;
                    const name = `${fileName}.${ext}`;
                    updateFiles.push({
                      kind: '1',
                      dir,
                      before: orderPageData.viewModel.compositeFileName.realPath.split('/')[1],
                      after: name,
                    });
                    orderPageData.viewModel.compositeFileName.realPath = `${dir}/${name}`;
                    orderPageData.viewModel.compositeFileName.displayPath = `Display_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                    orderPageData.viewModel.compositeFileName.sheetPath = `ContactSheet_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                  }
                }
                orderPageData.build();
              });
              /* order-page のパス情報更新 */
              if (orderPartsData.page?.pageData) {
                orderPartsData.page.xml.metaModel.paths = orderPartsData.page.pageData?.map((v, i) => ({
                  id: `${i + 1}`,
                  path: v.xmlUniqueName,
                }));
                orderPartsData.page.xml.build();
              }
            });
            /* order-parts のパス情報更新 */
            if (orderInfoData.parts) {
              orderInfoData.parts.xml.metaModel.paths = orderInfoData.parts.partsData?.map((v, i) => ({
                id: `${i + 1}`,
                path: v.xml.xmlUniqueName,
              }));
              orderInfoData.parts.xml.build();
            }
          });
          /* order-info のパス情報更新 */
          // if (structure.orderInfo?.xml) {
          if (xml.orderInfo?.xml) {
            const orderInfoDataArr: XmlStructureOrderInfoData[] = [...renameOrderInfoData, ...(structure?.orderInfo?.infoData || [])];
            orderInfoDataArr.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id));
            /* 保存するデータを structure に上書き */
            structure.orderInfo = {
              xml: xml.orderInfo.xml,
              infoData: orderInfoDataArr,
            };
            const parentOrderInfo: { id: string, path: string }[] = [];
            structure.orderInfo.xml.metaModel.path = orderInfoDataArr.map((v, i) => {
              const path = {
                id: `${v.xml.metaModel.id || (i + 1)}`,
                path: v.xml.xmlUniqueName,
              };
              if (!v.xml.metaModel.parentId) {
                parentOrderInfo.push(path);
              }
              return path;
            });
            if (parentOrderInfo.length) {
              structure.orderInfo.xml.metaModel.parentSequence = parentOrderInfo;
            }
            structure.orderInfo.xml.build();
          }
          /* 更新が必要なファイルのアップデート */
          if (updateFiles.length) {
            /* ファイル取得 */
            const uploadFiles: ImagesPostRequest[] = await Promise.all(updateFiles.map((v) => new Promise<ImagesPostRequest | null>((resolve) => {
              dispatch(apiActions.run(
                new ApiImagesGetOne({
                  kijshopCd,
                  path: `${kijshopCd}/${shopOrderId}/${v.dir}/${v.before}`,
                }),
                {
                  onSuccess: async (blob: Blob) => {
                    if (blob.type === 'application/json') {
                      resolve(null);
                      return;
                    }
                    const name = v.after;
                    const file = new File(
                      [blob],
                      name,
                      {
                        lastModified: new Date().getTime(),
                        type: blob.type,
                      },
                    );
                    let thumb: string | null = null;
                    if (v.kind === '1') {
                      thumb = await new Promise<string | null>((resolve) => {
                        dispatch(apiActions.run(
                          new ApiImagesGetOne({
                            kijshopCd,
                            path: `${kijshopCd}/uploadThumb/${shopOrderId}/${v.before}`,
                          }),
                          {
                            onSuccess: (v: Blob) => {
                              if (v.type === 'application/json') {
                                resolve(null);
                                return;
                              }
                              const reader = new FileReader();
                              reader.readAsDataURL(v);
                              reader.onload = () => {
                                resolve((reader.result as string).replace(/^data:\w+\/\w+;base64,/, ''));
                              }
                            },
                            onError: () => resolve(null),
                          },
                          { ignoreSystemError: true },
                        ));
                      });
                    }
                    resolve({
                      kijshopCd,
                      shopOrderId,
                      kind: v.kind,
                      filename: name,
                      data: file,
                      dataThumb: thumb || undefined,
                    });
                  },
                  onError: () => resolve(null),
                },
                { ignoreSystemError: true },
              ));
            }))) as ImagesPostRequest[];
            if (uploadFiles.findIndex((v) => !v) !== -1) {
              console.error(uploadFiles);
              data.callback?.error?.();
              return;
            }
            /* ファイル更新 ※このタイミングで中断すると致命的なので注意 */
            const beforeUnload = window.onbeforeunload;
            window.onbeforeunload = () => true;
            const resultArr = await Promise.all(updateFiles.map((v) => new Promise<boolean>((resolve) => {
              dispatch(apiActions.run(
                new ApiImagesDelete({
                  kijshopCd,
                  shopOrderId,
                  kind: v.kind,
                  filename: v.before,
                }),
                {
                  onSuccess: (res: ResponseBase<any>) => resolve(res?.error?.errorCode === '200'),
                  onError: () => resolve(false),
                },
              ));
            })));
            await Promise.all(uploadFiles.map((v) => new Promise<boolean>((resolve) => {
              dispatch(apiActions.run(
                new ApiImagesPost(v),
                {
                  onSuccess: () => resolve(true),
                  onError: () => resolve(false),
                },
              ));
            })))
              .then((res) => resultArr.push(...res));
            window.onbeforeunload = beforeUnload;
            if (resultArr.find((v) => !v)) {
              data.callback?.error?.();
              return;
            }
          }
          /* 採番しなおしはここまで */
          /* 採番が必要ない場合は structure の上書きのみ */
        } else {
          const orderInfoDataArr: XmlStructureOrderInfoData[] = [];
          xml.orderInfo.infoData?.forEach((v) => {
            if (v.xml.metaModel.id && Number(v.xml.metaModel.id) <= maxIndex) {
              orderInfoDataArr.push(v);
            }
          });
          if (!structure.orderInfo) {
            structure.orderInfo = {
              xml: xml.orderInfo.xml,
              infoData: orderInfoDataArr,
            };
          }
          if (!structure.orderInfo.xml) {
            structure.orderInfo.xml = xml.orderInfo.xml;
          }
          if (!structure.orderInfo.infoData) {
            structure.orderInfo.infoData = [];
          }
          orderInfoDataArr?.forEach((orderInfoData) => {
            if (structure.orderInfo?.xml.metaModel) {
              if (!structure.orderInfo.xml.metaModel.path) {
                structure.orderInfo.xml.metaModel.path = [];
              }
              if (orderInfoData.xml.metaModel.id && !structure.orderInfo.xml.metaModel.path.find((v) => v.id === orderInfoData.xml.metaModel.id)) {
                structure.orderInfo.xml.metaModel.path.push({
                  id: orderInfoData.xml.metaModel.id,
                  path: orderInfoData.xml.xmlUniqueName,
                });
              }
            }
            if (structure.orderInfo?.infoData && !structure.orderInfo.infoData.find((v) => v.xml.metaModel.id === orderInfoData.xml.metaModel.id)) {
              if (orderInfoData.xml.metaModel.parentId !== parentData.id && orderInfoData.xml.metaModel.id) {
                structure.orderInfo.infoData.push(orderInfoData);
                if (xml.orderInfo?.xml?.metaModel.parentSequence?.find((v) => v.id === orderInfoData.xml.metaModel.id)) {
                  if (structure.orderInfo.xml.metaModel.parentSequence) {
                    structure.orderInfo.xml.metaModel.parentSequence.push({ id: orderInfoData.xml.metaModel.id });
                  } else {
                    structure.orderInfo.xml.metaModel.parentSequence = [{ id: orderInfoData.xml.metaModel.id }];
                  }
                }
              }
            }
          });
          structure.orderInfo.xml.metaModel.path?.sort((a, b) => Number(a.id) - Number(b.id));
          structure.orderInfo.xml.metaModel.parentSequence?.sort((a, b) => Number(a.id) - Number(b.id));
          structure.orderInfo.infoData.sort((a, b) => Number(a.xml.indexes[0]) - Number(b.xml.indexes[0]));
          structure.orderInfo.xml.build();
        }
      } else {
        if (!structure.orderInfo) {
          structure.orderInfo = {
            xml: xml.orderInfo.xml,
            infoData: xml.orderInfo.infoData,
          };
        }
        if (!structure.orderInfo.xml) {
          structure.orderInfo.xml = xml.orderInfo.xml;
        }
        if (!structure.orderInfo.infoData) {
          structure.orderInfo.infoData = [];
        }
        xml.orderInfo.infoData?.forEach((orderInfoData) => {
          if (structure.orderInfo?.xml.metaModel) {
            if (!structure.orderInfo.xml.metaModel.path) {
              structure.orderInfo.xml.metaModel.path = [];
            }
            if (orderInfoData.xml.metaModel.id && !structure.orderInfo.xml.metaModel.path.find((v) => v.id === orderInfoData.xml.metaModel.id)) {
              structure.orderInfo.xml.metaModel.path.push({
                id: orderInfoData.xml.metaModel.id,
                path: orderInfoData.xml.xmlUniqueName,
              });
            }
          }
          if (structure.orderInfo?.infoData && !structure.orderInfo.infoData.find((v) => v.xml.metaModel.id === orderInfoData.xml.metaModel.id)) {
            if (orderInfoData.xml.metaModel.parentId !== parentData.id && orderInfoData.xml.metaModel.id) {
              structure.orderInfo.infoData.push(orderInfoData);
              if (xml.orderInfo?.xml.metaModel.parentSequence?.find((v) => v.id === orderInfoData.xml.metaModel.id)) {
                if (structure.orderInfo.xml.metaModel.parentSequence) {
                  structure.orderInfo.xml.metaModel.parentSequence.push({ id: orderInfoData.xml.metaModel.id });
                } else {
                  structure.orderInfo.xml.metaModel.parentSequence = [{ id: orderInfoData.xml.metaModel.id }];
                }
              }
            }
          }
        });
        structure.orderInfo.xml.metaModel.path?.sort((a, b) => Number(a.id) - Number(b.id));
        structure.orderInfo.xml.metaModel.parentSequence?.sort((a, b) => Number(a.id) - Number(b.id));
        structure.orderInfo.infoData.sort((a, b) => Number(a.xml.indexes[0]) - Number(b.xml.indexes[0]));
        structure.orderInfo.xml.build();
      }
      /* 注文データの統合 */
      structure.info = xml.info;
      structure.customer = xml.customer;
      structure.shop = xml.shop;
      structure.delivery = xml.delivery;
      structure.status = xml.status;
      structure.summary = xml.summary;
      structure.orderSelect = xml.orderSelect;
      /* 更新した order-info-data に紐づく xml を更新 */
      dispatch(apiActions.run(
        new ApiAllGetXml(kijshopCd, shopOrderId),
        {
          onSuccess: (res: ResponseBase<GetXmlResponse>) => {
            structure.orderInfo?.xml.build();
            const files: {
              filename: string,
              body: string,
              is_create: '0' | '1',
            }[] = structure.orderInfo?.xml ? [{
              filename: structure.orderInfo.xml.xmlUniqueName,
              body: structure.orderInfo.xml.xml,
              is_create: '0',
            }] : [];
            /* 保存済ファイルのうち使用していないファイルを削除する */
            const arr = res.body.data?.fileinfo.map((v) => v.filename) || [];
            (structure.orderInfo?.infoData || []).forEach((orderInfoData) => {
              /* order-info-data */
              const orderInfoDataIndex = arr.findIndex((v) => v === orderInfoData.xml.xmlUniqueName);
              if (orderInfoDataIndex !== -1) {
                arr.splice(orderInfoDataIndex, 1);
              }
              if (!files.find((v) => v.filename === orderInfoData.xml.xmlUniqueName)) {
                /* order-page-break を生成済の場合は引き継ぐ */
                if (orderInfoData.xml.metaModel.id === orderInfoDataId) {
                  const orderPageBreak = xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderInfoDataId)?.pageBreak;
                  if (orderInfoData && orderPageBreak) {
                    orderInfoData.pageBreak = orderPageBreak;
                    orderInfoData.xml.orderPageBreak = orderPageBreak;
                    if (orderInfoData.xml.metaModel.path) {
                      orderInfoData.xml.metaModel.path.orderPageBreak = orderPageBreak.xmlUniqueName;
                    } else {
                      orderInfoData.xml.metaModel.path = {
                        orderPageBreak: orderPageBreak.xmlUniqueName,
                      };
                    }
                    orderInfoData.xml.build();
                    const index = arr.findIndex((v) => v === orderPageBreak.xmlUniqueName);
                    if (index !== -1) {
                      arr.splice(index, 1);
                    }
                  }
                }
                files.push({
                  filename: orderInfoData.xml.xmlUniqueName,
                  body: orderInfoData.xml.xml,
                  is_create: orderInfoDataIndex === -1 ? '1' : '0',
                });
              }
              /* order-page-break */
              const pageBreakIndex = arr.findIndex((v) => v === orderInfoData.pageBreak?.xmlUniqueName);
              if (pageBreakIndex !== -1) {
                arr.splice(pageBreakIndex, 1);
              }
              if (orderInfoData.pageBreak && !files.find((v) => v.filename === orderInfoData.pageBreak?.xmlUniqueName)) {
                files.push({
                  filename: orderInfoData.pageBreak.xmlUniqueName,
                  body: orderInfoData.pageBreak.xml,
                  is_create: pageBreakIndex === -1 ? '1' : '0',
                });
              }
              /* order-parts */
              const pageIndex = arr.findIndex((v) => v === orderInfoData.parts?.xml.xmlUniqueName);
              if (pageIndex !== -1) {
                arr.splice(pageIndex, 1);
              }
              if (orderInfoData.parts?.xml && !files.find((v) => v.filename === orderInfoData.parts?.xml.xmlUniqueName)) {
                files.push({
                  filename: orderInfoData.parts.xml.xmlUniqueName,
                  body: orderInfoData.parts.xml.xml,
                  is_create: pageIndex === -1 ? '1' : '0',
                });
              }
              orderInfoData.parts?.partsData?.forEach((orderPartsData) => {
                /* order-parts-data */
                const partsDataIndex = arr.findIndex((v) => v === orderPartsData.xml.xmlUniqueName);
                if (partsDataIndex !== -1) {
                  arr.splice(partsDataIndex, 1);
                }
                if (!files.find((v) => v.filename === orderPartsData.xml.xmlUniqueName)) {
                  files.push({
                    filename: orderPartsData.xml.xmlUniqueName,
                    body: orderPartsData.xml.xml,
                    is_create: partsDataIndex === -1 ? '1' : '0',
                  });
                }
                /* order-page */
                const pageIndex = arr.findIndex((v) => v === orderPartsData.page?.xml.xmlUniqueName);
                if (pageIndex !== -1) {
                  arr.splice(pageIndex, 1);
                }
                if (orderPartsData.page?.xml && !files.find((v) => v.filename === orderPartsData.page?.xml.xmlUniqueName)) {
                  files.push({
                    filename: orderPartsData.page.xml.xmlUniqueName,
                    body: orderPartsData.page.xml.xml,
                    is_create: pageIndex === -1 ? '1' : '0',
                  });
                }
                orderPartsData.page?.pageData?.forEach((orderPageData) => {
                  /* order-page-data */
                  const pageDataIndex = arr.findIndex((v) => v === orderPageData.xmlUniqueName);
                  if (pageDataIndex !== -1) {
                    arr.splice(pageDataIndex, 1);
                  }
                  if (!files.find((v) => v.filename === orderPageData.xmlUniqueName)) {
                    files.push({
                      filename: orderPageData.xmlUniqueName,
                      body: orderPageData.xml,
                      is_create: pageDataIndex === -1 ? '1' : '0',
                    });
                  }
                });
              });
            });
            /* 商品に依存しないXMLを削除対象から除外 */
            // const xmlStructure = Object.assign(xml, structure);
            ([
              structure.info,
              structure.customer,
              structure.shop,
              structure.delivery,
              structure.status,
              structure.summary,
              structure.orderSelect,
              structure.orderInfo?.xml,
            ] as XmlClass<any>[]).forEach((data) => {
              const index = arr.findIndex((v) => v === data?.xmlUniqueName);
              if (index !== -1) {
                arr.splice(index, 1);
              }
            });
            /* status 更新（処理が重複するけど念の為直前で作り直す） */
            const statusProcess = ((execute: { [key: string]: [''] }, surrogate: { [key: string]: [''] }) => {
              // const execute = orderInfoDataModel.parent.executeProcess || {};
              // const surrogate = orderInfoDataModel.parent.surrogateProcess?.data || {};
              const arr: string[] = [];
              Object.keys(execute).forEach((key) => {
                if (!Object.keys(surrogate).find((v) => v === key)) {
                  arr.push(key);
                }
              });
              return arr;
            });
            const statusArr = structure.status?.metaModel.orderInfoData || [];
            if (structure.status?.metaModel) {
              structure.status.metaModel.orderInfoData = [];
            }
            structure.orderInfo?.infoData?.forEach((orderInfoData) => {
              const statusData = statusArr.find((v) => v.id === orderInfoData.xml.metaModel.id);
              const data = statusProcess(orderInfoData.xml.viewModel.executeProcess || {}, orderInfoData.xml.viewModel.surrogateProcess?.data || {}).map((v) => ({
                finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                machineID: getState().storage.machineId,
                processID: v,
                startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                statusCode: statusData?.data?.find((data) => data.processID === v)?.statusCode || ((v === processId.order && isComp) ? '1' : '0'),
                // orderPartsData: statusData?.orderPartsData,
              }));
              if (data.length) {
                structure.status?.metaModel.orderInfoData?.push({
                  id: statusData?.id || orderInfoData.xml.metaModel.id || orderInfoData.xml.indexes[0],
                  data,
                  orderPartsData: statusData?.orderPartsData,
                });
              }
            });
            if (structure.status) {
              structure.status?.build();
              files.push({
                filename: structure.status.xmlUniqueName,
                body: structure.status.xml,
                is_create: '0',
              });
            }
            /* summary 更新 */
            if (structure.summary) {
              structure.summary?.di(structure);
              structure.summary?.build();
              files.push({
                filename: structure.summary.xmlUniqueName,
                body: structure.summary.xml,
                is_create: '0',
              });
            }
            /* データ更新 */
            if (arr.length && preMaxIndex !== maxIndex) {
              dispatch(apiActions.run(new ApiDeleteXml(kijshopCd, shopOrderId, arr)));
            }
            dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
              onSuccess: data.callback?.success,
              onError: data.callback?.error,
            }));
            dispatch(xmlActions.setXml({ shopOrderId, xml: structure as XmlStructureModel }));
          },
        },
      ));
    },
    /* ロジックは編集と同じ */
    delete: (orderInfoDataId: string): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      if (!await XmlFactory.checkVersion()) {
        data?.callback?.error?.();
        return;
      }
      const xml = getState().xml[shopOrderId];
      if (!xml?.orderInfo) {
        console.log('shopOrderId: ', shopOrderId, ' - ', 'xml: ', xml);
        return;
      }
      /* アップロード画像以外の使用画像を削除 */
      const orderInfoData = xml.orderInfo.infoData?.find((v) => v.xml.metaModel.id === orderInfoDataId);
      // レタッチデータが存在するかフラグ
      const isRetouchCreated = Boolean(xml.orderInfo?.infoData?.find(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch'))
      // レタッチされているselectIDのリスト
      const retouchSelectIdList: {itemId: string, list: string[]}[] = []
      // 削除された商品で使用されているselectIDのリスト
      const targetSelectIdList: string[] = []
      // 削除された商品以外で使用されているselectIDのリスト
      const otherSelectIdList: string[] = []
      //　レタッチファイルがあった場合
      if (isRetouchCreated) {
        // レタッチ関連のxml群の中からレタッチのベースファイルを除いたファイルリストを作る
        const retouchInfoData = xml.orderInfo.infoData?.filter(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch' && v.xml.viewModel.item?.id !== 'jptg300179');
        if (retouchInfoData?.length) {
          retouchInfoData.forEach(infoData => {
            const retouchData: {itemId: string, list: string[]} = {itemId: infoData.xml.viewModel.item?.id ?? '', list: []}
            const orderPageData = infoData.parts?.partsData?.[0].page?.pageData?.[0]
            if (orderPageData) {
              orderPageData.viewModel.orderPicture?.data?.forEach(orderPicture => {
                if (orderPicture.selectID) {
                  retouchData.list.push(orderPicture.selectID);
                }
              })
            }
            retouchSelectIdList.push(retouchData);
          })
        }
      }
      if (orderInfoData?.xml.viewModel.orderMethod?.id === '10') {
        // orderPageBreak で selectID 管理してるので画像の削除は不要
        // レタッチされている場合、対象の画像と対象外の画像をリストアップ
        if (isRetouchCreated) {
          const orderInfoDataList = xml.orderInfo.infoData?.filter(v => v.xml.metaModel.id !== orderInfoDataId && v.xml.viewModel.orderMethod?.id === '10');
          orderInfoDataList?.forEach(infoData => {
            infoData.pageBreak?.viewModel.data?.image.forEach(image => {
              otherSelectIdList.push(image.selectID);
            })
          })
          orderInfoData.pageBreak?.viewModel.data?.image.forEach(image => {
            targetSelectIdList.push(image.selectID);
          })
        }
      }
      if (orderInfoData?.xml.viewModel.orderMethod?.id === '20' || orderInfoData?.xml.viewModel.orderMethod?.id === '30') {
        // レタッチが行われていた場合削除する際に使用状況チェックように他商品の使用画像のselectIDを一覧で保持
        if (isRetouchCreated) {
          const orderInfoDataList = xml.orderInfo.infoData?.filter(v => v.xml.metaModel.id !== orderInfoDataId && (v.xml.viewModel.orderMethod?.id === '20' || v.xml.viewModel.orderMethod?.id === '30'));
          orderInfoDataList?.forEach(infoData => {
            infoData.xml.allOrderPageDataXml.forEach(orderPageData => {
              orderPageData.viewModel.orderPicture?.data?.forEach(image => {
                if (image.selectID && image.selectID !== 'null') {
                  otherSelectIdList.push(image.selectID);
                }
              })
            })
          })
        }
        orderInfoData.xml.allOrderPageDataXml.forEach((orderPageData) => {
          // レタッチが行われていた場合削除する際に画像のselectIDを一覧で保持
          orderPageData.viewModel.orderPicture?.data?.forEach(image => {
            if (image.selectID && image.selectID !== 'null') {
              targetSelectIdList.push(image.selectID);
            }
          })
          // サムネ
          if (orderPageData.viewModel.compositeFileName?.virtualPath) {
            dispatch(apiActions.run(
              new ApiImagesDelete({
                kijshopCd,
                shopOrderId,
                kind: '2',
                filename: `${kijshopCd}/${shopOrderId}/${orderPageData.viewModel.compositeFileName.virtualPath}`,
              }),
              {},
              { ignoreSystemError: true },
            ));
          }
          // png
          orderPageData.viewModel?.freeGraphic?.data?.forEach((data) => {
            if (data?.virtual?.path) {
              dispatch(apiActions.run(
                new ApiImagesDelete({
                  kijshopCd,
                  shopOrderId,
                  kind: '5',
                  filename: `${kijshopCd}/${shopOrderId}/${data.virtual.path}`,
                }),
                {},
                { ignoreSystemError: true },
              ));
            }
          });
          // テキスト
          orderPageData.viewModel?.orderTextImage?.data?.forEach((data) => {
            if (data?.path) {
              dispatch(apiActions.run(
                new ApiImagesDelete({
                  kijshopCd,
                  shopOrderId,
                  kind: '4',
                  filename: `${kijshopCd}/${shopOrderId}/${data.path}`,
                }),
                {},
                { ignoreSystemError: true },
              ));
            }
          });
        });
      }
      if (orderInfoData?.xml.viewModel.orderMethod?.id === '40') {
        orderInfoData.xml.allOrderPageDataXml.forEach((orderPageData) => {
          if (orderPageData.viewModel.compositeFileName?.realPath) {
            dispatch(apiActions.run(
              new ApiImagesDelete({
                kijshopCd,
                shopOrderId,
                kind: '1',
                filename: `${kijshopCd}/${shopOrderId}/${orderPageData.viewModel.compositeFileName.realPath}`,
              }),
              {},
              { ignoreSystemError: true },
            ));
          }
        });
      }
      /* 処理を終えるまで元データを残すために一度新規に作成 */
      const structure: Partial<XmlStructureModel> = {};
      /* ファイル増減分の差分 */
      const optionOrderInfoDataArr = [...(xml.orderInfo?.infoData || [])].map((v) => v.xml.metaModel.parentId && v.xml.metaModel.parentId === orderInfoDataId ? v : null).filter((v) => !!v);
      /* 親商品に紐づくオプション商品のうち id が最大値のものを取得 */
      const preMaxIndex = optionOrderInfoDataArr.length ? Math.max(...optionOrderInfoDataArr.map((v) => Number(v?.xml.metaModel.id))) : Number(orderInfoDataId);
      const maxIndex = Number(orderInfoDataId) - 1;
      // レタッチファイルが完全削除されるかどうか
      const isRetouchDelete = (() => {
        const index = xml.orderInfo?.infoData?.findIndex(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch');
        return (index && index === 1);
      })()
      // 他商品でも使用されている画像を除外
      const diffSelectIdList = targetSelectIdList.filter(v => !otherSelectIdList.includes(v));
      // レタッチのselectIDのリストから削除される画像を取り除いたリスト
      const modifyRetouchList = retouchSelectIdList.map(retouchData => {
        retouchData.list = retouchData.list.filter(v => !diffSelectIdList.includes(v));
        return retouchData
      })
      // 削除対象のレタッチファイルリスト
      const deleteRetouchList = modifyRetouchList.filter(v => !v.list.length);
      // 更新対象のレタッチファイルリスト
      const updateRetouchList = modifyRetouchList.filter(v => v.list.length);
      /* ファイル数が異なる場合 */
      if (preMaxIndex !== maxIndex) {
        const updateFiles: {
          kind: '1' | '2' | '3' | '4' | '5' | '6',
          dir: string,
          before: string,
          after: string,
        }[] = [];
        /* 次の親商品の order-info-data を取得 */
        const arr = [...(xml.orderInfo?.xml.metaModel.parentSequence || [])];
        arr.sort((a, b) => Number(a.id) - Number(b.id));
        /* 編集した orderInfoData 以外の orderInfoData を格納 */
        const renameOrderInfoData: XmlStructureOrderInfoData[] = [];
        /* 更新後と更新前の index 差分をみる */
        const num = maxIndex - preMaxIndex;
        let retouchDeleteNum = 0
        /* parentSequence から該当商品を削除 */
        const index = xml.orderInfo.xml.metaModel.parentSequence?.findIndex((v) => v.id === orderInfoDataId);
        if ((index || index === 0) && index !== -1) {
          xml.orderInfo.xml.metaModel.parentSequence?.splice(index, 1);
          // レタッチファイルが先頭に来る場合削除する
          if (isRetouchDelete && !updateRetouchList.length) {
            const retouchTarget = xml.orderInfo.infoData?.[1].xml;
            const retouchIndex = xml.orderInfo.xml.metaModel.parentSequence?.findIndex((v) => v.id === retouchTarget?.metaModel.id);
            if ((retouchIndex || retouchIndex === 0) && retouchIndex !== -1) {
              xml.orderInfo.xml.metaModel.parentSequence?.splice(retouchIndex, 1);
            }
          }
        }

        /* status から該当商品を削除 */
        const statusIndex = xml.status.metaModel.orderInfoData?.findIndex((v) => v.id === orderInfoDataId);
        if ((statusIndex || statusIndex === 0) && statusIndex !== -1) {
          xml.status.metaModel.orderInfoData?.splice(statusIndex, 1);
        }
        optionOrderInfoDataArr.forEach((option) => {
          const optionStatusIndex = xml.status.metaModel.orderInfoData?.findIndex((v) => v.id === option?.xml.metaModel.id);
          if ((optionStatusIndex || optionStatusIndex === 0) && optionStatusIndex !== -1) {
            xml.status.metaModel.orderInfoData?.splice(optionStatusIndex, 1);
          }
        });
        /* 以降の内容は編集とほぼ同じ */
        xml.orderInfo?.infoData?.forEach((orderInfoData) => {
          const retouchUpdateTarget = updateRetouchList.find(v => v.itemId === orderInfoData.xml.viewModel.item?.id);
          const parent = xml.orderInfo?.xml.metaModel.parentSequence?.find((v) =>
            Number(v.id) > Number(orderInfoData.xml.metaModel.parentId) && Number(v.id) < Number(orderInfoData.xml.metaModel.id));
          if ((orderInfoData.xml.metaModel.id === orderInfoDataId || orderInfoData.xml.metaModel.parentId === orderInfoDataId) && !parent) {
            return;
          }
          if (isRetouchDelete && orderInfoData.xml.rootTagModel.contentGroupID === 'orderRetouch' && !updateRetouchList.length) {
            // レタッチファイルが完全に削除されるケースでもindex調整用の変数をincrement
            retouchDeleteNum++
            return;
          }
          // レタッチファイルが作られていた場合は削除対象があるかチェック
          if (isRetouchCreated) {
            const target = deleteRetouchList.find(v => v.itemId === orderInfoData.xml.viewModel.item?.id);
            if (target) {
              // レタッチファイルが削除されるケースでもindex調整用の変数をincrement
              retouchDeleteNum++
              return;
            }
          }
          renameOrderInfoData.push(orderInfoData);
          /* 元データの該当商品以前のidは精査対象外 */
          if (Number(orderInfoData.xml.metaModel.id) <= preMaxIndex) {
            return;
          }
          const newOrderInfoDataId = String(Number(orderInfoData.xml.metaModel.id) + num - retouchDeleteNum);
          const orderMethod = !orderInfoData.xml.metaModel.parentId
            ? orderInfoData.xml.viewModel.orderMethod?.id
            : xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === orderInfoData.xml.metaModel.parentId)?.xml.viewModel.orderMethod?.id;
          /* 親商品なら紐づくオプションの parentID と status の id を更新 */ // TODO 更新後の親idが他の親idと被っている場合
          // if (structure.orderInfo?.xml.metaModel.parentSequence?.find((v) => v.id !== orderInfoDataId && v.id === orderInfoData.xml.metaModel.id)) {
          if (xml.orderInfo?.xml.metaModel.parentSequence?.find((v) => v.id !== orderInfoDataId && v.id === orderInfoData.xml.metaModel.id)) {
            xml.orderInfo?.infoData?.forEach((v) => {
              if (v.xml.metaModel.parentId === orderInfoData.xml.metaModel.id) {
                v.xml.metaModel.parentId = newOrderInfoDataId;
              }
            });
            xml.status.metaModel.orderInfoData?.forEach((v) => {
              if (v.id === orderInfoData.xml.metaModel.id) {
                v.id = newOrderInfoDataId;
              }
            });
            xml.status.build();
          }
          /* order-info-data の id 更新 */
          orderInfoData.xml.changeIndexes([newOrderInfoDataId]);
          orderInfoData.xml.metaModel.id = newOrderInfoDataId;
          /* order-page-break の id 更新 */
          orderInfoData.pageBreak?.changeIndexes([newOrderInfoDataId]);
          /* order-parts の id 更新 */
          orderInfoData.parts?.xml.changeIndexes([newOrderInfoDataId]);
          /* order-info-data のパス情報更新 */
          orderInfoData.xml.metaModel.path = (orderInfoData.pageBreak || orderInfoData.parts) && {
            orderPageBreak: orderInfoData.pageBreak?.xmlUniqueName,
            orderParts: orderInfoData.parts?.xml.xmlUniqueName,
          };
          orderInfoData.xml.build();
          orderInfoData.parts?.partsData?.forEach((orderPartsData) => {
            /* order-parts-data の id 更新 */
            orderPartsData.xml.changeIndexes([newOrderInfoDataId, orderPartsData.xml.indexes[1]]);
            /* order-page の id 更新 */
            orderPartsData.page?.xml.changeIndexes([newOrderInfoDataId, orderPartsData.page.xml.indexes[1]]);
            /* order-parts-data のパス情報更新 */
            if (orderPartsData.page?.xml) {
              orderPartsData.xml.metaModel.path = orderPartsData.page.xml.xmlUniqueName;
              orderPartsData.xml.build();
            }
            orderPartsData.page?.pageData?.forEach((orderPageData) => {
              /* order-page-data の id 更新 */
              orderPageData.changeIndexes([newOrderInfoDataId, orderPageData.indexes[1], orderPageData.indexes[2]]);
              const indexes = orderPageData.indexes;
              /* 画像の更新が必要なものはXML内の記述のみここで更新してファイル自体は後述の処理で行う */
              if (orderMethod === '20' || orderMethod === '30') {
                /* サムネイル */
                if (orderPageData.viewModel?.compositeFileName?.virtualPath) {
                  const dir = 'Comp_Virtual';
                  const name = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01.jpg`;
                  updateFiles.push({
                    kind: '2',
                    dir,
                    before: orderPageData.viewModel?.compositeFileName.virtualPath.split('/')[1],
                    after: name,
                  });
                  orderPageData.viewModel.compositeFileName.virtualPath = `${dir}/${name}`;
                }
                /* テキスト */
                const textImageList = orderPageData.viewModel?.orderTextImage?.data;
                if (textImageList) {
                  textImageList.forEach((v) => {
                    const fileIndex = v.path?.split('/')?.[1]?.split('.')?.[0]?.split('_')?.[6];
                    if (v.path) {
                      const dir = 'Except_Real';
                      const name = `ER_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_${fileIndex}.png`
                      updateFiles.push({
                        kind: '4',
                        dir,
                        before: v.path.split('/')[1],
                        after: name,
                      });
                      v.path = `${dir}/${name}`;
                    }
                  });
                }
              }
              if (orderMethod === '40') {
                /* 完成画像 */
                if (orderPageData.viewModel.compositeFileName?.realPath) {
                  const dir = 'Comp_Real';
                  const ext = orderPageData.viewModel.compositeFileName.realPath.split('.')[1];
                  const fileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01`;
                  const name = `${fileName}.${ext}`;
                  updateFiles.push({
                    kind: '1',
                    dir,
                    before: orderPageData.viewModel.compositeFileName.realPath.split('/')[1],
                    after: name,
                  });
                  orderPageData.viewModel.compositeFileName.realPath = `${dir}/${name}`;
                  orderPageData.viewModel.compositeFileName.displayPath = `Display_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                  orderPageData.viewModel.compositeFileName.sheetPath = `ContactSheet_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                }
              }
              // 削除された商品で使用されていた画像とレタッチ情報の連携を解除
              if (retouchUpdateTarget && orderPageData.viewModel.orderPicture && orderPageData.viewModel.orderPicture.data) {
                orderPageData.viewModel.orderPicture.data = orderPageData.viewModel.orderPicture.data.filter(v => retouchUpdateTarget.list.includes(v.selectID ?? ''))
              }
              orderPageData.build();
            });
            /* order-page のパス情報更新 */
            if (orderPartsData.page?.pageData) {
              orderPartsData.page.xml.metaModel.paths = orderPartsData.page.pageData?.map((v, i) => ({
                id: `${i + 1}`,
                path: v.xmlUniqueName,
              }));
              orderPartsData.page.xml.build();
            }
          });
          /* order-parts のパス情報更新 */
          if (orderInfoData.parts) {
            orderInfoData.parts.xml.metaModel.paths = orderInfoData.parts.partsData?.map((v, i) => ({
              id: `${i + 1}`,
              path: v.xml.xmlUniqueName,
            }));
            orderInfoData.parts.xml.build();
          }
        });
        /* order-info のパス情報更新 */
        if (xml.orderInfo?.xml) {
          const orderInfoDataArr: XmlStructureOrderInfoData[] = [...renameOrderInfoData, ...(structure?.orderInfo?.infoData || [])];
          orderInfoDataArr.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id));
          /* 保存するデータを structure に上書き */
          structure.orderInfo = {
            xml: xml.orderInfo.xml,
            infoData: orderInfoDataArr,
          };
          const parentOrderInfo: { id: string, path: string }[] = [];
          structure.orderInfo.xml.metaModel.path = orderInfoDataArr.map((v, i) => {
            const path = {
              id: `${v.xml.metaModel.id || (i + 1)}`,
              path: v.xml.xmlUniqueName,
            };
            if (!v.xml.metaModel.parentId) {
              parentOrderInfo.push(path);
            }
            return path;
          });
          if (parentOrderInfo.length) {
            structure.orderInfo.xml.metaModel.parentSequence = parentOrderInfo;
          }
          structure.orderInfo.xml.build();
        }
        /* 更新が必要なファイルのアップデート */
        if (updateFiles.length) {
          /* ファイル取得 */
          const uploadFiles: ImagesPostRequest[] = await Promise.all(updateFiles.map((v) => new Promise<ImagesPostRequest | null>((resolve) => {
            dispatch(apiActions.run(
              new ApiImagesGetOne({
                kijshopCd,
                path: `${kijshopCd}/${shopOrderId}/${v.dir}/${v.before}`,
              }),
              {
                onSuccess: async (blob: Blob) => {
                  if (blob.type === 'application/json') {
                    resolve(null);
                    return;
                  }
                  const name = v.after;
                  const file = new File(
                    [blob],
                    name,
                    {
                      lastModified: new Date().getTime(),
                      type: blob.type,
                    },
                  );
                  let thumb: string | null = null;
                  if (v.kind === '1') {
                    thumb = await new Promise<string | null>((resolve) => {
                      dispatch(apiActions.run(
                        new ApiImagesGetOne({
                          kijshopCd,
                          path: `${kijshopCd}/uploadThumb/${shopOrderId}/${v.before}`,
                        }),
                        {
                          onSuccess: (v: Blob) => {
                            if (v.type === 'application/json') {
                              resolve(null);
                              return;
                            }
                            const reader = new FileReader();
                            reader.readAsDataURL(v);
                            reader.onload = () => {
                              resolve((reader.result as string).replace(/^data:\w+\/\w+;base64,/, ''));
                            }
                          },
                          onError: () => resolve(null),
                        },
                        { ignoreSystemError: true },
                      ));
                    });
                  }
                  resolve({
                    kijshopCd,
                    shopOrderId,
                    kind: v.kind,
                    filename: name,
                    data: file,
                    dataThumb: thumb || undefined,
                  });
                },
                onError: () => resolve(null),
              },
              { ignoreSystemError: true },
            ));
          }))) as ImagesPostRequest[];
          if (uploadFiles.findIndex((v) => !v) !== -1) {
            console.error(uploadFiles);
            data.callback?.error?.();
            return;
          }
          /* ファイル更新 ※このタイミングで中断すると致命的なので注意 */
          const beforeUnload = window.onbeforeunload;
          window.onbeforeunload = () => true;
          const resultArr = await Promise.all(updateFiles.map((v) => new Promise<boolean>((resolve) => {
            dispatch(apiActions.run(
              new ApiImagesDelete({
                kijshopCd,
                shopOrderId,
                kind: v.kind,
                filename: v.before,
              }),
              {
                onSuccess: (res: ResponseBase<any>) => resolve(res?.error?.errorCode === '200'),
                onError: () => resolve(false),
              },
            ));
          })));
          await Promise.all(uploadFiles.map((v) => new Promise<boolean>((resolve) => {
            dispatch(apiActions.run(
              new ApiImagesPost(v),
              {
                onSuccess: () => resolve(true),
                onError: () => resolve(false),
              },
            ));
          })))
            .then((res) => resultArr.push(...res));
          window.onbeforeunload = beforeUnload;
          if (resultArr.find((v) => !v)) {
            data.callback?.error?.();
            return;
          }
        }
        /* 採番しなおしはここまで */
      } else {
        if (!structure.orderInfo) {
          structure.orderInfo = {
            xml: xml.orderInfo.xml,
            infoData: xml.orderInfo.infoData,
          };
        }
        if (!structure.orderInfo.xml) {
          structure.orderInfo.xml = xml.orderInfo.xml;
        }
        if (!structure.orderInfo.infoData) {
          structure.orderInfo.infoData = [];
        }
        xml.orderInfo.infoData?.forEach((orderInfoData) => {
          if (structure.orderInfo?.infoData && !structure.orderInfo.infoData.find((v) => v.xml.metaModel.id === orderInfoData.xml.metaModel.id)) {
            structure.orderInfo.infoData.push(orderInfoData);
          }
        });
      }
      /* 注文データの統合 */
      structure.info = xml.info;
      structure.customer = xml.customer;
      structure.shop = xml.shop;
      structure.delivery = xml.delivery;
      structure.status = xml.status;
      structure.summary = xml.summary;
      structure.orderSelect = xml.orderSelect;
      /* 更新した order-info-data に紐づく xml を更新 */
      dispatch(apiActions.run(
        new ApiAllGetXml(kijshopCd, shopOrderId),
        {
          onSuccess: (res: ResponseBase<GetXmlResponse>) => {
            structure.orderInfo?.xml.build();
            const files: {
              filename: string,
              body: string,
              is_create: '0' | '1',
            }[] = structure.orderInfo?.xml ? [{
              filename: structure.orderInfo.xml.xmlUniqueName,
              body: structure.orderInfo.xml.xml,
              is_create: '0',
            }] : [];
            /* 保存済ファイルのうち使用していないファイルを削除する */
            const arr = res.body.data?.fileinfo.map((v) => v.filename) || [];
            (structure.orderInfo?.infoData || []).forEach((orderInfoData) => {
              /* order-info-data */
              const orderInfoDataIndex = arr.findIndex((v) => v === orderInfoData.xml.xmlUniqueName);
              if (orderInfoDataIndex !== -1) {
                arr.splice(orderInfoDataIndex, 1);
              }
              if (!files.find((v) => v.filename === orderInfoData.xml.xmlUniqueName)) {
                files.push({
                  filename: orderInfoData.xml.xmlUniqueName,
                  body: orderInfoData.xml.xml,
                  is_create: orderInfoDataIndex === -1 ? '1' : '0',
                });
              }
              /* order-page-break */
              const pageBreakIndex = arr.findIndex((v) => v === orderInfoData.pageBreak?.xmlUniqueName);
              if (pageBreakIndex !== -1) {
                arr.splice(pageBreakIndex, 1);
              }
              if (orderInfoData.pageBreak && !files.find((v) => v.filename === orderInfoData.pageBreak?.xmlUniqueName)) {
                files.push({
                  filename: orderInfoData.pageBreak.xmlUniqueName,
                  body: orderInfoData.pageBreak.xml,
                  is_create: pageBreakIndex === -1 ? '1' : '0',
                });
              }
              /* order-parts */
              const pageIndex = arr.findIndex((v) => v === orderInfoData.parts?.xml.xmlUniqueName);
              if (pageIndex !== -1) {
                arr.splice(pageIndex, 1);
              }
              if (orderInfoData.parts?.xml && !files.find((v) => v.filename === orderInfoData.parts?.xml.xmlUniqueName)) {
                files.push({
                  filename: orderInfoData.parts.xml.xmlUniqueName,
                  body: orderInfoData.parts.xml.xml,
                  is_create: pageIndex === -1 ? '1' : '0',
                });
              }
              orderInfoData.parts?.partsData?.forEach((orderPartsData) => {
                /* order-parts-data */
                const partsDataIndex = arr.findIndex((v) => v === orderPartsData.xml.xmlUniqueName);
                if (partsDataIndex !== -1) {
                  arr.splice(partsDataIndex, 1);
                }
                if (!files.find((v) => v.filename === orderPartsData.xml.xmlUniqueName)) {
                  files.push({
                    filename: orderPartsData.xml.xmlUniqueName,
                    body: orderPartsData.xml.xml,
                    is_create: partsDataIndex === -1 ? '1' : '0',
                  });
                }
                /* order-page */
                const pageIndex = arr.findIndex((v) => v === orderPartsData.page?.xml.xmlUniqueName);
                if (pageIndex !== -1) {
                  arr.splice(pageIndex, 1);
                }
                if (orderPartsData.page?.xml && !files.find((v) => v.filename === orderPartsData.page?.xml.xmlUniqueName)) {
                  files.push({
                    filename: orderPartsData.page.xml.xmlUniqueName,
                    body: orderPartsData.page.xml.xml,
                    is_create: pageIndex === -1 ? '1' : '0',
                  });
                }
                orderPartsData.page?.pageData?.forEach((orderPageData) => {
                  /* order-page-data */
                  const pageDataIndex = arr.findIndex((v) => v === orderPageData.xmlUniqueName);
                  if (pageDataIndex !== -1) {
                    arr.splice(pageDataIndex, 1);
                  }
                  if (!files.find((v) => v.filename === orderPageData.xmlUniqueName)) {
                    files.push({
                      filename: orderPageData.xmlUniqueName,
                      body: orderPageData.xml,
                      is_create: pageDataIndex === -1 ? '1' : '0',
                    });
                  }
                });
              });
            });
            /* 商品に依存しないXMLを削除対象から除外 */
            ([
              structure.info,
              structure.customer,
              structure.shop,
              structure.delivery,
              structure.status,
              structure.summary,
              structure.orderSelect,
              structure.orderInfo?.xml,
            ] as XmlClass<any>[]).forEach((data) => {
              const index = arr.findIndex((v) => v === data?.xmlUniqueName);
              if (index !== -1) {
                arr.splice(index, 1);
              }
            });
            /* status 更新 */
            if (structure.status) {
              structure.status?.build();
              files.push({
                filename: structure.status.xmlUniqueName,
                body: structure.status.xml,
                is_create: '0',
              });
            }
            /* summary 更新 */
            if (structure.summary) {
              structure.summary?.di(structure);
              structure.summary?.build();
              files.push({
                filename: structure.summary.xmlUniqueName,
                body: structure.summary.xml,
                is_create: '0',
              });
            }
            /* データ更新 */
            if (arr.length) {
              dispatch(apiActions.run(new ApiDeleteXml(kijshopCd, shopOrderId, arr)));
            }
            dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
              onSuccess: data.callback?.success,
              onError: data.callback?.error,
            }));
            dispatch(xmlActions.setXml({ shopOrderId, xml: structure as XmlStructureModel }));
          },
        },
      ));
    },
    /* 校正承認更新 */
    changeIsNeedAgreement: (orderInfoDataId: string, isNeedAgreement: boolean): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      if (!await XmlFactory.checkVersion()) {
        data?.callback?.error?.();
        return;
      }
      const xml = getState().xml[shopOrderId];
      if (!xml?.orderInfo) {
        console.log('shopOrderId: ', shopOrderId, ' - ', 'xml: ', xml);
        return;
      }
      const orderInfoData = xml.orderInfo.xml.orderInfoData(orderInfoDataId);
      if (!orderInfoData) {
        console.log(`orderInfoData_${orderInfoDataId}`);
        return;
      }
      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1',
      }[] = [];
      orderInfoData.metaModel.isNeedAgreement = isNeedAgreement ? 'true' : 'false';
      orderInfoData.build();
      files.push({
        filename: orderInfoData.xmlUniqueName,
        body: orderInfoData.xml,
        is_create: '0',
      });
      dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
        onSuccess: data.callback?.success,
        onError: data.callback?.error,
      }));
      dispatch(xmlActions.setXml({ shopOrderId, xml }));
    },
    /* プリント商材の画像数量更新 */
    changeOutputCount: (orderInfoDataId: string, orderPageDataIndex: number, value: string | number): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      if (!await XmlFactory.checkVersion()) {
        data?.callback?.error?.();
        return;
      }
      const xml = getState().xml[shopOrderId];
      if (!xml?.orderInfo) {
        console.log('shopOrderId: ', shopOrderId, ' - ', 'xml: ', xml);
        return;
      }
      const orderInfoData = xml.orderInfo.xml.orderInfoData(orderInfoDataId);
      const orderPageData = orderInfoData?.orderPageDataArr[orderPageDataIndex];
      if (!orderInfoData || !orderPageData) {
        console.log('orderPageDataIndex: ', orderPageDataIndex);
        return;
      }
      orderPageData.viewModel.pageOutputCount = String(value);
      orderPageData.build();
      let totalPageCount = 0;
      orderInfoData.orderPageDataArr.forEach((v) => {
        totalPageCount += Number(v.viewModel.pageOutputCount || 0);
      });
      if (totalPageCount) {
        orderInfoData.viewModel.totalPageCount = String(totalPageCount);
        orderInfoData.build();
      }
      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1',
      }[] = [
        {
          filename: orderPageData.xmlUniqueName,
          body: orderPageData.xml,
          is_create: '0',
        },
        {
          filename: orderInfoData.xmlUniqueName,
          body: orderInfoData.xml,
          is_create: '0',
        },
      ];
      dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
        onSuccess: data.callback?.success,
        onError: data.callback?.error,
      }));
      dispatch(xmlActions.setXml({ shopOrderId, xml }));
    },
  }),
  uploadImage: (data: { kijshopCd: string, shopOrderId: string }) => ({
    /** 完成画像アップロード */
    comp: (orderId: string, images: { file: File, materials?: { materialId: string, materialName: string } }[], callback: { success: (images: File[]) => void, error: () => void }, optionImages: { orderInfoDataId: string, file: File, materials?: { materialId: string, materialName: string } }[]): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      if (!await XmlFactory.checkVersion()) {
        callback.error();
        return;
      }
      const xml = getState().xml[shopOrderId];
      if (!xml) {
        return;
      }
      const structure = lodash.cloneDeep(xml);
      /* order-info-data 取得 */
      const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
      const infoData = structure.orderInfo?.infoData?.[infoDataIndex]?.xml;
      if (infoDataIndex === -1 || !infoData) {
        return;
      }
      /* 更新が必要なXML格納用 */
      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1',
      }[] = [];
      /* リネーム後の画像ファイル */
      const renameImages: File[] = [];
      const pageDataArr: OrderPageDataXml[] = [];
      infoData.orderParts?.orderPartsDataArr.forEach((partsData) => partsData.orderPage?.orderPageDataArr.forEach((pageData) => {
        if (pageData) {
          pageDataArr.push(pageData);
        }
      }));
      // 拡張子のチェックメソッド
      const checkExt = (ext: string) => {
        return (ext.toLowerCase() === 'png' || ext.toLowerCase() === 'jpg')
      };
      /* order-page-data に画像を設定 */
      images.forEach((image, i) => {
        const pageData = pageDataArr[i];
        if (pageData) {
          /* 元ファイルの削除 */
          if (pageData.viewModel.compositeFileName?.realPath) {
            dispatch(apiActions.run(
              new ApiImagesDelete({
                kijshopCd,
                shopOrderId,
                kind: '1',
                filename: pageData.viewModel.compositeFileName.realPath,
              }),
            ));
          }
          /* order-page-data 更新 */
          pageData.viewModel.originalCompositeFileName = {
            realPath: image.file.name,
          };
          const indexes = pageData.indexes;
          // const _ext = image.file.type.split('image/')[1] || image.file.name.split('.')[image.file.name.split('.').length - 1];
          const _ext = image.file.name.split('.')[image.file.name.length - 1] || image.file.name.split('.')[image.file.name.split('.').length - 1];
          const isUpperCase = image.file.name.split('.')[image.file.name.split('.').length - 1] === image.file.name.split('.')[image.file.name.split('.').length - 1].toUpperCase();
          // FIXME: 現行踏襲だと拡張子大文字の際はそのままで生成するがバックエンド側の実装に大幅な修正が必要になるので小文字にして強制的に上書きする(2024/02/15)
          const ext = checkExt(_ext) ? _ext.toLowerCase() : 'jpg';
          const compFileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01`;
          pageData.viewModel.compositeFileName = {
            // FIXME: 現行踏襲だと拡張子大文字の際はそのままで生成するがバックエンド側の実装に大幅な修正が必要になるので小文字にして強制的に上書きする(2024/02/15)
            // realPath: `Comp_Real/${compFileName}.${isUpperCase ? ext.toUpperCase() : ext}`,
            realPath: `Comp_Real/${compFileName}.${ext}`,
            displayPath: `Display_Virtual/${compFileName}.${ext.toLowerCase() === 'png' ? 'jpg' : (isUpperCase ? ext.toUpperCase() : ext)}`,
            sheetPath: `ContactSheet_Virtual/${compFileName}.${ext.toLowerCase() === 'png' ? 'jpg' : (isUpperCase ? ext.toUpperCase() : ext)}`,
          };
          if (image.materials) {
            pageData.viewModel.materials = {
              material: [{
                materialID: image.materials.materialId,
                materialName: image.materials.materialName,
              }],
            };
          }
          pageData.build();
          files.push({
            filename: pageData.xmlUniqueName,
            body: pageData.xml,
            is_create: '0',
          });
          // FIXME: 現行踏襲だと拡張子大文字の際はそのままで生成するがバックエンド側の実装に大幅な修正が必要になるので小文字にして強制的に上書きする(2024/02/15)
          // renameImages.push(new File([image.file.slice(0, image.file.size, image.file.type)], `${compFileName}.${isUpperCase ? ext.toUpperCase() : ext}`, { type: image.file.type }));
          renameImages.push(new File([image.file.slice(0, image.file.size, image.file.type)], `${compFileName}.${ext}`, { type: image.file.type }));
          // image.name = compFileName;
        }
      });
      /* オプション商品の画像設定 */
      if (optionImages.length) {
        optionImages.forEach((opImage, i) => {
          const infoData = structure.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === opImage.orderInfoDataId);
          // const pageData = infoData?.parts?.partsData?.[0]?.page?.pageData?.[0];
          const pageData = (() => {
            let v!: OrderPageDataXml;
            infoData?.parts?.partsData?.forEach((partsData) => {
              v = v || partsData?.page?.pageData?.find((pageData) => !files.find((file) => file.filename === pageData.xmlUniqueName));
            });
            return v;
          })();
          if (pageData) {
            /* 元ファイルの削除 */
            if (pageData.viewModel.compositeFileName?.realPath) {
              dispatch(apiActions.run(
                new ApiImagesDelete({
                  kijshopCd,
                  shopOrderId,
                  kind: '1',
                  filename: pageData.viewModel.compositeFileName.realPath,
                }),
              ));
            }
            /* order-page-data 更新 */
            pageData.viewModel.originalCompositeFileName = {
              realPath: opImage.file.name,
            };
            pageData.viewModel.parentID = undefined;
            const indexes = pageData.indexes;
            // const _ext = opImage.file.type.split('image/')[1] || opImage.file.name.split('.')[opImage.file.name.split('.').length - 1];
            // const ext = _ext === 'png' ? 'png' : 'jpg';
            const _ext = opImage.file.name.split('.')[opImage.file.name.length - 1] || opImage.file.name.split('.')[opImage.file.name.split('.').length - 1];
            const isUpperCase = opImage.file.name.split('.')[opImage.file.name.split('.').length - 1] === opImage.file.name.split('.')[opImage.file.name.split('.').length - 1].toUpperCase();
            const ext = _ext === 'png' ? 'png' : 'jpg';
            const compFileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01`;
            pageData.viewModel.compositeFileName = {
              realPath: `Comp_Real/${compFileName}.${isUpperCase ? ext.toUpperCase() : ext}`,
              displayPath: `Display_Virtual/${compFileName}.${ext === 'png' ? 'jpg' : (isUpperCase ? ext.toUpperCase() : ext)}`,
              sheetPath: `ContactSheet_Virtual/${compFileName}.${ext === 'png' ? 'jpg' : (isUpperCase ? ext.toUpperCase() : ext)}`,
            };
            if (opImage.materials) {
              pageData.viewModel.materials = {
                material: [{
                  materialID: opImage.materials.materialId,
                  materialName: opImage.materials.materialName,
                }],
              };
            }
            pageData.build();
            files.push({
              filename: pageData.xmlUniqueName,
              body: pageData.xml,
              is_create: '0',
            });
            renameImages.push(new File([opImage.file.slice(0, opImage.file.size, opImage.file.type)], `${compFileName}.${isUpperCase ? ext.toUpperCase() : ext}`, { type: opImage.file.type }));
            // image.name = compFileName;
          }
        });
      }
      /* order-page-break 作成 */
      if (!infoData.metaModel.path?.orderPageBreak) {
        const pageBreak = XmlFactory.createFromIndex({
          shopOrderId,
          data: { name: 'order-page-break', indexes: [infoData.indexes[0]] },
        });
        pageBreak.build();
        files.push({
          filename: pageBreak.xmlUniqueName,
          body: pageBreak.xml,
          is_create: '1',
        });
        infoData.metaModel.path = {
          orderParts: infoData.metaModel.path?.orderParts,
          orderPageBreak: pageBreak.xmlUniqueName,
        };
        infoData.build();
        files.push({
          filename: infoData.xmlUniqueName,
          body: infoData.xml,
          is_create: '0',
        });
        infoData.orderPageBreak = pageBreak;
        if (structure.orderInfo?.infoData?.[infoDataIndex]) {
          const orderInfoDataStructure = structure.orderInfo.infoData[infoDataIndex];
          structure.orderInfo.infoData[infoDataIndex] = {
            ...orderInfoDataStructure,
            pageBreak,
          };
        }
      }
      structure.summary.di(structure);
      structure.summary.build();
      dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
        onSuccess: () => callback.success(renameImages),
        onError: callback.error,
      }));
      dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
    },
    /** 画像を選択しておまかせ */
    select: (orderId: string) => ({
      add: (imageData: EditableImage[], printItem: boolean, sort: { type: 'name' | 'date' | 'free', case?: 'asc' | 'desc' }, callback: (list: EditableImage[]) => void): AppThunk => async (dispatch, getState) => {
        const { kijshopCd, shopOrderId } = data;
        const xml = getState().xml[shopOrderId];
        if (!xml || !await XmlFactory.checkVersion()) {
          return;
        }
        const structure = lodash.cloneDeep(xml);
        /* order-info-data 取得 */
        const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
        const infoDataStructure = structure.orderInfo?.infoData?.[infoDataIndex];
        const infoData = infoDataStructure?.xml;
        if (!infoDataStructure || infoDataIndex === -1 || !infoData) {
          return;
        }
        /* 更新が必要なXML格納用 */
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[] = [];
        /* order-select 生成 */
        if (!structure.orderSelect) {
          structure.orderSelect = XmlFactory.createFromIndex({
            shopOrderId,
            data: { name: 'order-select' },
          });
        }
        const orderSelect = structure.orderSelect;
        const imageDataArr: OrderSelectImageData[] = [];
        /* order-select がなければ info を更新 */
        if (!structure.info.metaModel.path?.orderSelect) {
          if (!structure.info.metaModel.path) {
            structure.info.metaModel.path = {
              orderSelect: orderSelect.xmlUniqueName,
            };
          } else {
            structure.info.metaModel.path.orderSelect = orderSelect.xmlUniqueName;
          }
          structure.info.build();
          files.push({
            filename: structure.info.xmlUniqueName,
            body: structure.info.xml,
            is_create: '0',
          });
        }
        /* order-page-break 生成 */
        const pageBreak = infoData.orderPageBreak || XmlFactory.createFromIndex({
          shopOrderId,
          data: { name: 'order-page-break', indexes: [orderId] },
        });
        const pageBreakImageDataArr: { selectID: string, path: string, }[] = [];
        /* order-page-break がなければ order-info-data を更新 */
        if (!infoData.metaModel.path?.orderPageBreak) {
          if (!infoData.metaModel.path) {
            infoData.metaModel.path = {
              orderPageBreak: pageBreak.xmlUniqueName,
            };
          } else {
            infoData.metaModel.path.orderPageBreak = pageBreak.xmlUniqueName;
          }
          infoData.orderPageBreak = pageBreak;
          infoDataStructure.pageBreak = pageBreak;
          infoData.build();
          files.push({
            filename: infoData.xmlUniqueName,
            body: infoData.xml,
            is_create: '0',
          });
        }
        /* index 取得 */
        let orderSelectCurrentIndex = 0;
        orderSelect.xmlModel.lnwOrderSelect.orderSelect?.[0]?.data?.forEach((v) => {
          if (Number(v.$.selectPictureID) > orderSelectCurrentIndex) {
            orderSelectCurrentIndex = Number(v.$.selectPictureID);
          }
        });
        orderSelectCurrentIndex++;
        /* プリント商材用の order-page-data */
        const orderPageDataArr = infoData.orderPageDataArr;
        /* データ生成 */
        imageData.forEach((v, i) => {
          const selectPictureID = padding(orderSelectCurrentIndex + i, 5);
          const selectID = v.selectId || `01${selectPictureID}`;
          v.selectId = selectID;
          const date = DateFormatter.date2str(v.exif.createDate, 'YYYYMMDDHHMMSSsss');
          imageDataArr.push({
            selectGroupID: '01',
            selectCode: '1',
            selectPictureID,
            selectID,
            id: `${orderSelectCurrentIndex + i}`,
            originalFileName: {
              real: {
                path: v.name,
                originalFileTime: date,
              },
            },
            selectFileName: {
              real: {
                path: v.path,
                realSelectFileTime: date,
              },
            },
          });
          pageBreakImageDataArr.push({
            selectID,
            path: v.name,
          });
          if (printItem) {
            for (let j = 0; j < orderPageDataArr.length; j++) {
              const orderPageData = orderPageDataArr[j];
              if (!orderPageData.viewModel.orderPicture?.data?.[0]?.selectID) {
                orderPageData.viewModel.orderPicture = {
                  data: [{
                    selectID,
                  }],
                };
                orderPageData.build();
                files.push({
                  filename: orderPageData.xmlUniqueName,
                  body: orderPageData.xml,
                  is_create: '0',
                });
                break;
              }
            }
          }
        });
        if (orderSelect.metaModel.imageData) {
          imageDataArr.forEach((v) => {
            if (orderSelect.metaModel.imageData && !orderSelect.metaModel.imageData?.find((data) => data.selectID === v.selectID)) {
              orderSelect.metaModel.imageData.push(v);
            }
          });
        } else {
          orderSelect.metaModel.imageData = [...imageDataArr];
        }
        orderSelect.build();
        files.push({
          filename: orderSelect.xmlUniqueName,
          body: orderSelect.xml,
          is_create: xml.orderSelect ? '0' : '1',
        });
        if (pageBreak.viewModel.data?.image) {
          pageBreak.viewModel.data.image.push(...pageBreakImageDataArr);
        } else {
          pageBreak.viewModel.data = {
            image: [...pageBreakImageDataArr],
          };
        }
        /* 並び順調整 */
        if (sort.type !== 'free') {
          pageBreak.viewModel.data.image.sort((a, b) => {
            const data1 = orderSelect.metaModel.imageData?.find((v) => v.selectID === a.selectID);
            const data2 = orderSelect.metaModel.imageData?.find((v) => v.selectID === b.selectID);
            const asc = sort.case === 'asc' ? 1 : -1;
            const desc = sort.case === 'desc' ? 1 : -1;
            if (sort.type === 'name') {
              return (data1?.originalFileName?.real.path || '') > (data2?.originalFileName?.real.path || '') ? asc : desc;
            }
            if (sort.type === 'date') {
              return (data1?.originalFileName?.real.originalFileTime || '') > (data2?.originalFileName?.real.originalFileTime || '') ? asc : desc;
            }
            return 1;
          });
          const pageData = infoData?.orderPageDataArr;
          const prevPageData = cloneDeep(infoData?.orderPageDataArr);
          pageBreak.viewModel.data.image.forEach((data, i) => {
            if (pageData && prevPageData) {
              const prevData = prevPageData.find(v => v.viewModel.orderPicture?.data?.[0].selectID === data.selectID);
              const prevDataPicture = prevData?.viewModel.orderPicture?.data?.[0];
              const nextData = pageData[i];
              const nextDataPicture = nextData?.viewModel.orderPicture?.data?.[0];
              if (nextData && prevData && nextDataPicture && prevDataPicture) {
                nextData.viewModel.pageOutputCount = prevData.viewModel.pageOutputCount;
                nextDataPicture.selectID = prevDataPicture.selectID;
                nextData.build();
                const isUpdateTargeted = files.find(v => v.filename === nextData.xmlUniqueName);
                if (isUpdateTargeted) {
                  const index = files.indexOf(isUpdateTargeted);
                  if (index >= 0) {
                    files.splice(index, 1, {
                      filename: nextData.xmlUniqueName,
                      body: nextData.xml,
                      is_create: '0',
                    })
                  }
                } else {
                files.push({
                  filename: nextData.xmlUniqueName,
                  body: nextData.xml,
                  is_create: '0',
                });
                }
              }
            }
          })
        }
        pageBreak.build();
        files.push({
          filename: pageBreak.xmlUniqueName,
          body: pageBreak.xml,
          is_create: infoData.orderPageBreak ? '0' : '1',
        });
        /* selectID 設定後の editableImage を返す */
        callback(imageData);
        /* プリント商材の場合 TODO */
        structure.summary.build();
        dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files })));
        dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      },
      delete: (idArr: string[], callback: { success: (pageBreak: OrderPageBreakXml) => void, error: () => void }, printItemData?: { list: string[] }): AppThunk => async (dispatch, getState) => {
        const { kijshopCd, shopOrderId } = data;
        const xml = getState().xml[shopOrderId];
        if (!xml || !await XmlFactory.checkVersion()) {
          callback.error();
          return;
        }
        const structure = lodash.cloneDeep(xml);
        /* order-info-data 取得 */
        const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
        const infoData = structure.orderInfo?.infoData?.[infoDataIndex]?.xml;
        const pageBreak = infoData?.orderPageBreak;
        const select = structure.orderSelect;
        if (infoDataIndex === -1 || !infoData || !pageBreak || !select) {
          callback.error();
          return;
        }
        /* 更新が必要なXML格納用 */
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[] = [];
        let selectIdList: string[] = [];
        idArr.forEach((pageBreakId) => {
          if (select.metaModel.imageData?.length) {
            if (pageBreak.viewModel.data?.image) {
              // const index = pageBreak.viewModel.data.image.findIndex((v) => v.selectID === id);
              const index = (pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data?.findIndex((v) => v.$.id === pageBreakId) !== undefined) ?
                pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data?.findIndex((v) => v.$.id === pageBreakId) : -1;
              // const index = 1;
              if (index !== -1) {
                const selectId = pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data?.[index].$.selectID;
                pageBreak.viewModel.data.image.splice(index, 1);
                if (selectId) selectIdList.push(selectId);
                const imageData = select.metaModel.imageData.find((v) => v.selectID === selectId);
                if (imageData) {
                  if (!structure.orderInfo?.infoData?.find((orderInfoData) => orderInfoData?.pageBreak?.viewModel.data?.image.find((v) => v.selectID === selectId))) {
                    imageData.selectCode = '0';
                  }
                }
              }
            }
          }
        });
        select.build();
        /* pageBreak の viewModel に id を持たせるのも冗長なので xmlModel を元に viewModel を作る */
        // if (pageBreak.viewModel.data) {
        //   pageBreak.viewModel.data.image = [...(pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data || [])].map((v) => ({
        //     selectID: v.$.selectID || '',
        //     path: v.fileName?.[0]?.$?.path || '',
        //   }));
        // }
        if (pageBreak.viewModel.data) {
          const ary: { selectID: string, path: string }[] = [];
          [...(pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data || [])].forEach((v) => {
            if (selectIdList.find((id) => id === v.$.selectID)) return;
            ary.push({
              selectID: v.$.selectID || '',
              path: v.fileName?.[0]?.$?.path || '',
            });
          });
          pageBreak.viewModel.data.image = ary;
        }
        pageBreak.build();
        files.push(
          {
            filename: pageBreak.xmlUniqueName,
            body: pageBreak.xml,
            is_create: '0',
          },
          {
            filename: select.xmlUniqueName,
            body: select.xml,
            is_create: '0',
          },
        );
        // if (printItemData) {
        //   const arr: string[] = [];
        //   printItemData.list.forEach((v) => {
        //     if (!idArr.find((id) => id === v)) {
        //       arr.push(v);
        //     }
        //   });
        //   const orderPageDataArr = infoData.orderPageDataArr;
        //   arr.forEach((id) => {
        //     for (let i = 0; i < orderPageDataArr.length; i++) {
        //       const orderPageData = orderPageDataArr[i];
        //       const selectID = arr[i];
        //       if (orderPageData && selectID) {
        //         orderPageData.viewModel.orderPicture = {
        //           data: [{
        //             selectID,
        //           }],
        //         };
        //         orderPageData.build();
        //         files.push({
        //           filename: orderPageData.xmlUniqueName,
        //           body: orderPageData.xml,
        //           is_create: '0',
        //         });
        //       }
        //     }
        //   });
        // }
        const pageData = infoData?.orderPageDataArr;
        const prevPageData = cloneDeep(infoData?.orderPageDataArr);
        // レタッチデータが存在するかフラグ
        const isRetouchCreated = Boolean(xml.orderInfo?.infoData?.find(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch'))
        const retouchInfoData = xml.orderInfo?.infoData?.filter(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch' && v.xml.viewModel.item?.id !== 'jptg300179');
        const retouchBaseInfoData = xml.orderInfo?.infoData?.find(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch' && v.xml.viewModel.item?.id === 'jptg300179');
        const retouchDeleteFiles: XmlStructureOrderInfoData[] = []
        const retouchUpdateFiles: OrderPageDataXml[] = []
        const renameOrderInfoData: XmlStructureOrderInfoData[] = [];
        const updateFiles: {
          kind: '1' | '2' | '3' | '4' | '5' | '6',
          dir: string,
          before: string,
          after: string,
        }[] = [];

        pageBreak.viewModel.data?.image.forEach((data, i) => {
          if (pageData && prevPageData) {
            const prevData = prevPageData.find(v => v.viewModel.orderPicture?.data?.[0].selectID === data.selectID);
            const prevDataPicture = prevData?.viewModel.orderPicture?.data?.[0];
            const nextData = pageData[i];
            const nextDataPicture = nextData?.viewModel.orderPicture?.data?.[0];
            if (nextData && prevData && nextDataPicture && prevDataPicture) {
              nextData.viewModel.pageOutputCount = prevData.viewModel.pageOutputCount;
              nextDataPicture.selectID = prevDataPicture.selectID;
              nextData.build();
              files.push({
                filename: nextData.xmlUniqueName,
                body: nextData.xml,
                is_create: '0',
              });
            }
          }
        })
        if (pageBreak.viewModel.data) {
          const resetData = pageData[pageBreak.viewModel.data.image.length];
          if (resetData) {
            resetData.viewModel.pageOutputCount = '1';
            resetData.viewModel.orderPicture = undefined;
            resetData.build();
            files.push({
              filename: resetData.xmlUniqueName,
              body: resetData.xml,
              is_create: '0',
            });
          } else {
            const lastData = pageData[pageData.length - 1];
            const lastDataPicture = lastData?.viewModel.orderPicture?.data?.[0];
            if (lastData && lastDataPicture) {
              lastData.viewModel.pageOutputCount = '1';
              lastDataPicture.selectID = pageBreak.viewModel.data?.image[pageData.length - 1].selectID;
              lastData.build();
              files.push({
                filename: lastData.xmlUniqueName,
                body: lastData.xml,
                is_create: '0',
              });
            }
          }
        }
        if (isRetouchCreated && retouchInfoData?.length) {
          for (const retouchInfoDataEntity of retouchInfoData) {
            const orderPageData = retouchInfoDataEntity.parts?.partsData?.[0].page?.pageData?.[0];
            const structureOrderPageData = structure.orderInfo?.infoData?.find(v => v.xml.xmlUniqueName === retouchInfoDataEntity.xml.xmlUniqueName)?.parts?.partsData?.[0].page?.pageData?.[0];
            let orderPicture = orderPageData?.viewModel.orderPicture?.data;
            if (structureOrderPageData && orderPageData && orderPicture?.length) {
              for (const selectId of selectIdList) {
                orderPicture = orderPicture?.filter(img => img.selectID !== selectId);
                orderPageData.viewModel.orderPicture!.data = orderPicture;
                structureOrderPageData.viewModel.orderPicture!.data = orderPicture;
                if (!orderPicture.length) {
                  retouchDeleteFiles.push(retouchInfoDataEntity);
                } else {
                  retouchUpdateFiles.push(orderPageData);
                }
              }
            }
          }
          if (retouchDeleteFiles.length === retouchInfoData.length && retouchBaseInfoData) {
            retouchDeleteFiles.push(retouchBaseInfoData);
          }
        }
        if (retouchDeleteFiles.length) {
          if (structure.orderInfo?.infoData?.length) {
            let deleteCount = 0;
            retouchDeleteFiles.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id));
            for (const infoData of structure.orderInfo.infoData) {
              if (Number(infoData.xml.metaModel.id) < Number(retouchDeleteFiles[0].xml.metaModel.id)) continue;
              const isDelete = retouchDeleteFiles.find(v => v.xml.xmlUniqueName === infoData.xml.xmlUniqueName);
              if (isDelete) {
                deleteCount++
              } else {
                renameOrderInfoData.push(infoData);
                const newOrderInfoDataId = String(Number(infoData.xml.metaModel.id) - deleteCount);
                const orderMethod = !infoData.xml.metaModel.parentId
                  ? infoData.xml.viewModel.orderMethod?.id
                  : xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === infoData.xml.metaModel.parentId)?.xml.viewModel.orderMethod?.id;
                if (xml.orderInfo?.xml.metaModel.parentSequence?.find((v) => v.id === infoData.xml.metaModel.id)) {
                  xml.orderInfo?.infoData?.forEach((v) => {
                    if (v.xml.metaModel.parentId === infoData.xml.metaModel.id) {
                      v.xml.metaModel.parentId = newOrderInfoDataId;
                    }
                  });
                  xml.status.metaModel.orderInfoData?.forEach((v) => {
                    if (v.id === infoData.xml.metaModel.id) {
                      v.id = newOrderInfoDataId;
                    }
                  });
                  xml.status.build();
                }
                /* order-info-data の id 更新 */
                infoData.xml.changeIndexes([newOrderInfoDataId]);
                infoData.xml.metaModel.id = newOrderInfoDataId;
                /* order-page-break の id 更新 */
                infoData.pageBreak?.changeIndexes([newOrderInfoDataId]);
                /* order-parts の id 更新 */
                infoData.parts?.xml.changeIndexes([newOrderInfoDataId]);
                /* order-info-data のパス情報更新 */
                infoData.xml.metaModel.path = (infoData.pageBreak || infoData.parts) && {
                  orderPageBreak: infoData.pageBreak?.xmlUniqueName,
                  orderParts: infoData.parts?.xml.xmlUniqueName,
                };
                infoData.xml.build();
                infoData.parts?.partsData?.forEach((orderPartsData) => {
                  /* order-parts-data の id 更新 */
                  orderPartsData.xml.changeIndexes([newOrderInfoDataId, orderPartsData.xml.indexes[1]]);
                  /* order-page の id 更新 */
                  orderPartsData.page?.xml.changeIndexes([newOrderInfoDataId, orderPartsData.page.xml.indexes[1]]);
                  /* order-parts-data のパス情報更新 */
                  if (orderPartsData.page?.xml) {
                    orderPartsData.xml.metaModel.path = orderPartsData.page.xml.xmlUniqueName;
                    orderPartsData.xml.build();
                  }
                  orderPartsData.page?.pageData?.forEach((orderPageData) => {
                    /* order-page-data の id 更新 */
                    orderPageData.changeIndexes([newOrderInfoDataId, orderPageData.indexes[1], orderPageData.indexes[2]]);
                    const indexes = orderPageData.indexes;
                    /* 画像の更新が必要なものはXML内の記述のみここで更新してファイル自体は後述の処理で行う */
                    if (orderMethod === '20' || orderMethod === '30') {
                      /* サムネイル */
                      if (orderPageData.viewModel?.compositeFileName?.virtualPath) {
                        const dir = 'Comp_Virtual';
                        const name = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01.jpg`;
                        updateFiles.push({
                          kind: '2',
                          dir,
                          before: orderPageData.viewModel?.compositeFileName.virtualPath.split('/')[1],
                          after: name,
                        });
                        orderPageData.viewModel.compositeFileName.virtualPath = `${dir}/${name}`;
                      }
                      /* テキスト */
                      const textImageList = orderPageData.viewModel?.orderTextImage?.data;
                      if (textImageList) {
                        textImageList.forEach((v) => {
                          const fileIndex = v.path?.split('/')?.[1]?.split('.')?.[0]?.split('_')?.[6];
                          if (v.path) {
                            const dir = 'Except_Real';
                            const name = `ER_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_${fileIndex}.png`
                            updateFiles.push({
                              kind: '4',
                              dir,
                              before: v.path.split('/')[1],
                              after: name,
                            });
                            v.path = `${dir}/${name}`;
                          }
                        });
                      }
                    }
                    if (orderMethod === '40') {
                      /* 完成画像 */
                      if (orderPageData.viewModel.compositeFileName?.realPath) {
                        const dir = 'Comp_Real';
                        const ext = orderPageData.viewModel.compositeFileName.realPath.split('.')[1];
                        const fileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01`;
                        const name = `${fileName}.${ext}`;
                        updateFiles.push({
                          kind: '1',
                          dir,
                          before: orderPageData.viewModel.compositeFileName.realPath.split('/')[1],
                          after: name,
                        });
                        orderPageData.viewModel.compositeFileName.realPath = `${dir}/${name}`;
                        orderPageData.viewModel.compositeFileName.displayPath = `Display_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                        orderPageData.viewModel.compositeFileName.sheetPath = `ContactSheet_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                      }
                    }
                    orderPageData.build();
                  });
                  /* order-page のパス情報更新 */
                  if (orderPartsData.page?.pageData) {
                    orderPartsData.page.xml.metaModel.paths = orderPartsData.page.pageData?.map((v, i) => ({
                      id: `${i + 1}`,
                      path: v.xmlUniqueName,
                    }));
                    orderPartsData.page.xml.build();
                  }
                });
                /* order-parts のパス情報更新 */
                if (infoData.parts) {
                  infoData.parts.xml.metaModel.paths = infoData.parts.partsData?.map((v, i) => ({
                    id: `${i + 1}`,
                    path: v.xml.xmlUniqueName,
                  }));
                  infoData.parts.xml.build();
                }
              }
            }
          }
          /* order-info のパス情報更新 */
          if (xml.orderInfo?.xml) {
            // const orderInfoDataArr: XmlStructureOrderInfoData[] = [...renameOrderInfoData, ...(structure?.orderInfo?.infoData || [])];
            const renameFirstId = renameOrderInfoData.length ? renameOrderInfoData.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id))[0].xml.metaModel.id : 0;
            let orderInfoDataArr: XmlStructureOrderInfoData[] = renameOrderInfoData.length ? [...renameOrderInfoData, ...(structure?.orderInfo?.infoData?.filter(v => Number(v.xml.metaModel.id) < (Number(renameFirstId)) ?? 0) || [])] : [...renameOrderInfoData, ...(structure?.orderInfo?.infoData|| [])];
            if (!renameOrderInfoData.length) {
              orderInfoDataArr = orderInfoDataArr.filter((v,  i) => !retouchDeleteFiles.find(vv => vv.xml.metaModel.id === v.xml.metaModel.id));
            }
            orderInfoDataArr.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id));
            /* 保存するデータを structure に上書き */
            structure.orderInfo = {
              xml: xml.orderInfo.xml,
              infoData: orderInfoDataArr,
            };
            const parentOrderInfo: { id: string, path: string }[] = [];
            structure.orderInfo.xml.metaModel.path = orderInfoDataArr.map((v, i) => {
              const path = {
                id: `${v.xml.metaModel.id || (i + 1)}`,
                path: v.xml.xmlUniqueName,
              };
              if (!v.xml.metaModel.parentId) {
                parentOrderInfo.push(path);
              }
              return path;
            });
            if (parentOrderInfo.length) {
              structure.orderInfo.xml.metaModel.parentSequence = parentOrderInfo;
            }
            structure.orderInfo.xml.build();
          }
          /* 更新が必要なファイルのアップデート */
          if (updateFiles.length) {
            /* ファイル取得 */
            const uploadFiles: ImagesPostRequest[] = await Promise.all(updateFiles.map((v) => new Promise<ImagesPostRequest | null>((resolve) => {
              dispatch(apiActions.run(
                new ApiImagesGetOne({
                  kijshopCd,
                  path: `${kijshopCd}/${shopOrderId}/${v.dir}/${v.before}`,
                }),
                {
                  onSuccess: async (blob: Blob) => {
                    if (blob.type === 'application/json') {
                      resolve(null);
                      return;
                    }
                    const name = v.after;
                    const file = new File(
                      [blob],
                      name,
                      {
                        lastModified: new Date().getTime(),
                        type: blob.type,
                      },
                    );
                    let thumb: string | null = null;
                    if (v.kind === '1') {
                      thumb = await new Promise<string | null>((resolve) => {
                        dispatch(apiActions.run(
                          new ApiImagesGetOne({
                            kijshopCd,
                            path: `${kijshopCd}/uploadThumb/${shopOrderId}/${v.before}`,
                          }),
                          {
                            onSuccess: (v: Blob) => {
                              if (v.type === 'application/json') {
                                resolve(null);
                                return;
                              }
                              const reader = new FileReader();
                              reader.readAsDataURL(v);
                              reader.onload = () => {
                                resolve((reader.result as string).replace(/^data:\w+\/\w+;base64,/, ''));
                              }
                            },
                            onError: () => resolve(null),
                          },
                          { ignoreSystemError: true },
                        ));
                      });
                    }
                    resolve({
                      kijshopCd,
                      shopOrderId,
                      kind: v.kind,
                      filename: name,
                      data: file,
                      dataThumb: thumb || undefined,
                    });
                  },
                  onError: () => resolve(null),
                },
                { ignoreSystemError: true },
              ));
            }))) as ImagesPostRequest[];
            if (uploadFiles.findIndex((v) => !v) !== -1) {
              console.error(uploadFiles);
              callback.error();
              return;
            }
            /* ファイル更新 ※このタイミングで中断すると致命的なので注意 */
            const beforeUnload = window.onbeforeunload;
            window.onbeforeunload = () => true;
            const resultArr = await Promise.all(updateFiles.map((v) => new Promise<boolean>((resolve) => {
              dispatch(apiActions.run(
                new ApiImagesDelete({
                  kijshopCd,
                  shopOrderId,
                  kind: v.kind,
                  filename: v.before,
                }),
                {
                  onSuccess: (res: ResponseBase<any>) => resolve(res?.error?.errorCode === '200'),
                  onError: () => resolve(false),
                },
              ));
            })));
            await Promise.all(uploadFiles.map((v) => new Promise<boolean>((resolve) => {
              dispatch(apiActions.run(
                new ApiImagesPost(v),
                {
                  onSuccess: () => resolve(true),
                  onError: () => resolve(false),
                },
              ));
            })))
              .then((res) => resultArr.push(...res));
            window.onbeforeunload = beforeUnload;
            if (resultArr.find((v) => !v)) {
              callback.error();
              return;
            }
          }
          /* 注文データの統合 */
          structure.info = xml.info;
          structure.customer = xml.customer;
          structure.shop = xml.shop;
          structure.delivery = xml.delivery;
          structure.status = xml.status;
          structure.summary = xml.summary;
          structure.orderSelect = xml.orderSelect;


          structure.summary.di(structure);
          structure.summary.build();
          /* 更新した order-info-data に紐づく xml を更新 */
          dispatch(apiActions.run(
            new ApiAllGetXml(kijshopCd, shopOrderId),
            {
              onSuccess: (res: ResponseBase<GetXmlResponse>) => {
                structure.orderInfo?.xml.build();
                const files: {
                  filename: string,
                  body: string,
                  is_create: '0' | '1',
                }[] = structure.orderInfo?.xml ? [{
                  filename: structure.orderInfo.xml.xmlUniqueName,
                  body: structure.orderInfo.xml.xml,
                  is_create: '0',
                }] : [];
                /* 保存済ファイルのうち使用していないファイルを削除する */
                const arr = res.body.data?.fileinfo.map((v) => v.filename) || [];
                (structure.orderInfo?.infoData || []).forEach((orderInfoData) => {
                  /* order-info-data */
                  const orderInfoDataIndex = arr.findIndex((v) => v === orderInfoData.xml.xmlUniqueName);
                  if (orderInfoDataIndex !== -1) {
                    arr.splice(orderInfoDataIndex, 1);
                  }
                  if (!files.find((v) => v.filename === orderInfoData.xml.xmlUniqueName)) {
                    files.push({
                      filename: orderInfoData.xml.xmlUniqueName,
                      body: orderInfoData.xml.xml,
                      is_create: orderInfoDataIndex === -1 ? '1' : '0',
                    });
                  }
                  /* order-page-break */
                  const pageBreakIndex = arr.findIndex((v) => v === orderInfoData.pageBreak?.xmlUniqueName);
                  if (pageBreakIndex !== -1) {
                    arr.splice(pageBreakIndex, 1);
                  }
                  if (orderInfoData.pageBreak && !files.find((v) => v.filename === orderInfoData.pageBreak?.xmlUniqueName)) {
                    files.push({
                      filename: orderInfoData.pageBreak.xmlUniqueName,
                      body: orderInfoData.pageBreak.xml,
                      is_create: pageBreakIndex === -1 ? '1' : '0',
                    });
                  }
                  /* order-parts */
                  const pageIndex = arr.findIndex((v) => v === orderInfoData.parts?.xml.xmlUniqueName);
                  if (pageIndex !== -1) {
                    arr.splice(pageIndex, 1);
                  }
                  if (orderInfoData.parts?.xml && !files.find((v) => v.filename === orderInfoData.parts?.xml.xmlUniqueName)) {
                    files.push({
                      filename: orderInfoData.parts.xml.xmlUniqueName,
                      body: orderInfoData.parts.xml.xml,
                      is_create: pageIndex === -1 ? '1' : '0',
                    });
                  }
                  orderInfoData.parts?.partsData?.forEach((orderPartsData) => {
                    /* order-parts-data */
                    const partsDataIndex = arr.findIndex((v) => v === orderPartsData.xml.xmlUniqueName);
                    if (partsDataIndex !== -1) {
                      arr.splice(partsDataIndex, 1);
                    }
                    if (!files.find((v) => v.filename === orderPartsData.xml.xmlUniqueName)) {
                      files.push({
                        filename: orderPartsData.xml.xmlUniqueName,
                        body: orderPartsData.xml.xml,
                        is_create: partsDataIndex === -1 ? '1' : '0',
                      });
                    }
                    /* order-page */
                    const pageIndex = arr.findIndex((v) => v === orderPartsData.page?.xml.xmlUniqueName);
                    if (pageIndex !== -1) {
                      arr.splice(pageIndex, 1);
                    }
                    if (orderPartsData.page?.xml && !files.find((v) => v.filename === orderPartsData.page?.xml.xmlUniqueName)) {
                      files.push({
                        filename: orderPartsData.page.xml.xmlUniqueName,
                        body: orderPartsData.page.xml.xml,
                        is_create: pageIndex === -1 ? '1' : '0',
                      });
                    }
                    orderPartsData.page?.pageData?.forEach((orderPageData) => {
                      /* order-page-data */
                      const pageDataIndex = arr.findIndex((v) => v === orderPageData.xmlUniqueName);
                      if (pageDataIndex !== -1) {
                        arr.splice(pageDataIndex, 1);
                      }
                      if (!files.find((v) => v.filename === orderPageData.xmlUniqueName)) {
                        orderPageData.build();
                        files.push({
                          filename: orderPageData.xmlUniqueName,
                          body: orderPageData.xml,
                          is_create: pageDataIndex === -1 ? '1' : '0',
                        });
                      }
                    });
                  });
                });
                /* 商品に依存しないXMLを削除対象から除外 */
                ([
                  structure.info,
                  structure.customer,
                  structure.shop,
                  structure.delivery,
                  structure.status,
                  structure.summary,
                  structure.orderSelect,
                  structure.orderInfo?.xml,
                ] as XmlClass<any>[]).forEach((data) => {
                  const index = arr.findIndex((v) => v === data?.xmlUniqueName);
                  if (index !== -1) {
                    arr.splice(index, 1);
                  }
                });
                /* status 更新 */
                if (structure.status) {
                  structure.status?.build();
                  files.push({
                    filename: structure.status.xmlUniqueName,
                    body: structure.status.xml,
                    is_create: '0',
                  });
                }
                /* summary 更新 */
                if (structure.summary) {
                  structure.summary?.di(structure);
                  structure.summary?.build();
                  files.push({
                    filename: structure.summary.xmlUniqueName,
                    body: structure.summary.xml,
                    is_create: '0',
                  });
                }
                /* データ更新 */
                if (arr.length) {
                  dispatch(apiActions.run(new ApiDeleteXml(kijshopCd, shopOrderId, arr)));
                }
                dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
                  onSuccess: () => {
                    callback.success(pageBreak);
                  },
                  onError: () => {
                    callback.error();
                  },
                }));
                dispatch(xmlActions.setXml({ shopOrderId, xml: structure as XmlStructureModel }));
              },
            },
          ));
        } else {
          for (const retouchUpdateFile of retouchUpdateFiles) {
            retouchUpdateFile.build();
            files.push({
              filename: retouchUpdateFile.xmlUniqueName,
              body: retouchUpdateFile.xml,
              is_create: '0',
            });
          }
          structure.summary.di(structure);
          structure.summary.build();
          dispatch(apiActions.run(
            new ApiUploadXml(kijshopCd, shopOrderId, { files }),
            {
              onSuccess: () => {
                callback.success(pageBreak);
              },
              onError: () => {
                callback.error();
              },
            },
          ));
          dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
        }
      },
      changeListRank: (pageBreakIdArr: string[]): AppThunk => async (dispatch, getState) => {
        const { kijshopCd, shopOrderId } = data;
        const xml = getState().xml[shopOrderId];
        if (!xml || !await XmlFactory.checkVersion()) {
          return;
        }
        const structure = lodash.cloneDeep(xml);
        /* order-info-data 取得 */
        const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
        const infoData = structure.orderInfo?.infoData?.[infoDataIndex]?.xml;
        const pageBreak = infoData?.orderPageBreak;
        const pageData = infoData?.orderPageDataArr;
        const prevPageData = cloneDeep(infoData?.orderPageDataArr);
        if (infoDataIndex === -1 || !infoData || !pageBreak?.viewModel.data?.image) {
          return;
        }
        // const pageBreakSelectIdArr = pageBreak.viewModel.data.image;
        const pageBreakSelectIdArr = pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data || [];
        /* 更新が必要なXML格納用 */
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[] = [];
        const idArr: PageBreakImageDataEntity[] = [];
        pageBreakIdArr.forEach((id) => {
          const index = pageBreakSelectIdArr.findIndex((data) => data.$.id === id);
          if (index !== -1) {
            idArr.push(pageBreakSelectIdArr[index]);
            pageBreakSelectIdArr.splice(index, 1);
          }
        });
        // pageBreak.viewModel.data.image = [...idArr, ...pageBreakSelectIdArr];
        if (pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data) {
          pageBreak.xmlModel.lnwOrderPageBreak.orderPageBreak[0].data[0].image[0].data = [...idArr, ...pageBreakSelectIdArr];
        }
        /* pageBreak の viewModel に id を持たせるのも冗長なので xmlModel を元に viewModel を作る */
        if (pageBreak.viewModel.data) {
          pageBreak.viewModel.data.image = [...(pageBreak.xmlModel.lnwOrderPageBreak?.orderPageBreak?.[0]?.data?.[0]?.image?.[0]?.data || [])].map((v) => ({
            selectID: v.$.selectID || '',
            path: v.fileName?.[0]?.$?.path || '',
          }));
        }
        pageBreak.build();
        files.push({
          filename: pageBreak.xmlUniqueName,
          body: pageBreak.xml,
          is_create: '0',
        });
        pageBreak.xmlModel.lnwOrderPageBreak.orderPageBreak?.[0].data?.[0].image?.[0].data?.forEach((data, i) => {
          if (pageData && prevPageData) {
            const prevData = prevPageData.find(v => v.viewModel.orderPicture?.data?.[0].selectID === data.$.selectID);
            const prevDataPicture = prevData?.viewModel.orderPicture?.data?.[0];
            const nextData = pageData[i];
            const nextDataPicture = nextData?.viewModel.orderPicture?.data?.[0];
            if (nextData && nextDataPicture) {
              if (prevData && prevDataPicture) {
                nextData.viewModel.pageOutputCount = prevData.viewModel.pageOutputCount;
                nextDataPicture.selectID = prevDataPicture.selectID;
              } else {
                nextData.viewModel.pageOutputCount = '1';
                nextDataPicture.selectID = data.$.selectID;
              }
              nextData.build();
              files.push({
                filename: nextData.xmlUniqueName,
                body: nextData.xml,
                is_create: '0',
              });
            }
          }
        })
        if (structure.summary) {
          structure.summary.di(structure);
          structure.summary.build();
          files.push({
            filename: structure.summary.xmlUniqueName,
            body: structure.summary.xml,
            is_create: '0',
          });
        }
        dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {}, { ignoreSystemError: true }));
        dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      },
    }),
    /** （完全・簡易）レイアウトして入稿 */
    layout: (orderId: string) => ({
      add: (imageData: EditableImage, callback: (saveSelect: SaveSelectData) => void): AppThunk => async (dispatch, getState) => {
        const update = () => new Promise<void>(async (resolve) => {
          const { kijshopCd, shopOrderId } = data;
          const xml = getState().xml[shopOrderId];
          if (!xml || !await XmlFactory.checkVersion()) {
            return;
          }
          const structure = lodash.cloneDeep(xml);
          /* order-info-data 取得 */
          const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
          const infoData = structure.orderInfo?.infoData?.[infoDataIndex]?.xml;
          if (infoDataIndex === -1 || !infoData) {
            return;
          }
          /* 更新が必要なXML格納用 */
          const files: {
            filename: string,
            body: string,
            is_create: '0' | '1',
          }[] = [];
          /* order-select 生成 */
          if (!structure.orderSelect) {
            structure.orderSelect = XmlFactory.createFromIndex({
              shopOrderId,
              data: { name: 'order-select' },
            });
          }
          /* 最新のorderSelect.xmlを取得(selectID被り対策) */
          const getOrderSelect = (xml: OrderSelectXml): Promise<OrderSelectXml> => new Promise((resolve, reject) => {
            dispatch(apiActions.run(new ApiGetXml(kijshopCd, shopOrderId, [xml.xmlUniqueName]),
              {
                onSuccess: async (res) => {
                  if (res.body.data) {
                    await xml.parse(res.body.data.fileinfo[0].body)
                    resolve(xml)
                  }
                },
                onError: () => reject(xml)
              }))
          })

          const orderSelect = await getOrderSelect(structure.orderSelect);
          // const orderSelect = structure.orderSelect;
          // const imageDataArr: OrderSelectImageData[] = [];
          /* order-select がなければ info を更新 */
          if (!structure.info.metaModel.path?.orderSelect) {
            if (!structure.info.metaModel.path) {
              structure.info.metaModel.path = {
                orderSelect: orderSelect.xmlUniqueName,
              };
            } else {
              structure.info.metaModel.path.orderSelect = orderSelect.xmlUniqueName;
            }
            structure.info.build();
            files.push({
              filename: structure.info.xmlUniqueName,
              body: structure.info.xml,
              is_create: '0',
            });
          }
          /* index 取得 */
          let orderSelectCurrentIndex = 0;
          orderSelect.xmlModel.lnwOrderSelect.orderSelect?.[0]?.data?.forEach((v) => {
            if (Number(v.$.selectPictureID) > orderSelectCurrentIndex) {
              orderSelectCurrentIndex = Number(v.$.selectPictureID);
            }
          });
          orderSelectCurrentIndex++;
          /* データ生成 */
          const selectPictureID = padding(orderSelectCurrentIndex, 5);
          const selectID = `01${selectPictureID}`;
          const date = DateFormatter.date2str(imageData.exif.createDate, 'YYYYMMDDHHMMSSsss');
          const newImageData = {
            selectGroupID: '01',
            selectCode: '1',
            selectPictureID,
            selectID,
            id: `${orderSelectCurrentIndex + 1}`,
            height: imageData.realHeight,
            width: imageData.realWidth,
            originalFileName: {
              real: {
                path: imageData.name,
                originalFileTime: date,
              },
            },
            selectFileName: {
              real: {
                path: imageData.path,
                realSelectFileTime: date,
              },
            },
          };
          imageData.selectId = selectID;
          if (orderSelect.metaModel.imageData) {
            // orderSelect.metaModel.imageData.push(...imageDataArr);
            orderSelect.metaModel.imageData.push(newImageData);
          } else {
            orderSelect.metaModel.imageData = [newImageData];
          }
          orderSelect.build();
          files.push({
            filename: orderSelect.xmlUniqueName,
            body: orderSelect.xml,
            is_create: xml.orderSelect ? '0' : '1',
          });
          structure.summary.build();
          dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
            onSuccess: () => {
              callback({selectId: selectID, editableImageId: imageData.id});
              resolve();
            },
          }));
          dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
        });
        await SelectIdQueueManager.ins.do(update);
      },
      comp: (images: LayoutCompFilesType, callback: (renameImages: File[], xml: XmlStructureModel, isError: boolean, isThumbError: boolean) => void): AppThunk => async (dispatch, getState) => {
        const { kijshopCd, shopOrderId } = data;
        const xml = getState().xml[shopOrderId];
        let isError = false;
        let isThumbError = false;
        if (!xml || !await XmlFactory.checkVersion()) {
          isError = true;
          return;
        }
        const structure = lodash.cloneDeep(xml);
        /* order-info-data 取得 */
        const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
        const infoData = structure.orderInfo?.infoData?.[infoDataIndex]?.xml;
        const optionOrderId: string[] = [];
        const optionInfoData: OrderInfoDataXml[] = [];
        /* 設定済のサムネイル削除用 */
        const thumbPathList: string[] = [];
        for (const info of structure.orderInfo?.infoData ?? []) {
          if (info.xml.metaModel.parentId === orderId) {
            for (const page of info.xml.orderPageDataArr ?? []) {
              if (!page.viewModel.parentID) {
                if (optionInfoData.find(v => v.xmlUniqueName === info.xml.xmlUniqueName)) continue;
                if (info.xml.indexes[0]) {
                  optionOrderId.push(info.xml.indexes[0]);
                }
                optionInfoData.push(info.xml);
              }
              if (page.viewModel.compositeFileName?.virtualPath) {
                thumbPathList.push(page.viewModel.compositeFileName.virtualPath);
              }
            }
          }
          if (info.xml.metaModel.id === orderId) {
            for (const page of info.xml.orderPageDataArr ?? []) {
              if (page.viewModel.compositeFileName?.virtualPath) {
                thumbPathList.push(page.viewModel.compositeFileName.virtualPath);
              }
            }
          }
        }
        if (infoDataIndex === -1 || !infoData) {
          isError = true;
          return;
        }
        /* 削除ファイルリストの作成 */
        await new Promise<void>((resolve) => {
          dispatch(apiActions.run(
            new ApiImagesGet({
              kijshopCd,
              shopOrderId,
              kind: '2',
            }),
            {
              onSuccess: (res) => {
                const resData = res.body.data ?? [];
                const filter = resData.filter((v: any) => {
                  const split = v.filename.split('_');
                  return (optionOrderId.concat(orderId).includes(String(Number(split[split.length - 4])))) || thumbPathList.find((thumb) => thumb.split('/')[thumb.split('/').length - 1] === v.filename);
                });
                dispatch(apiActions.runAll(
                  filter.map((v: any) => new ApiImagesDelete({
                    kijshopCd,
                    shopOrderId,
                    kind: '2',
                    filename: v.filename,
                  })),
                  {
                    onSuccess: () => resolve(),
                  }
                ))
              }
            }
          ))
        });
        /* 更新が必要なXML格納用 */
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[] = [];
        /* リネーム後の画像ファイル */
        const renameImages: File[] = [];
        const pageDataArr: OrderPageDataXml[] = [];
        const parts = infoData.orderParts?.orderPartsDataArr.concat(optionInfoData.map((v) => v.orderParts?.orderPartsDataArr ?? []).flat());
        (parts ?? []).forEach((partsData) => partsData.orderPage?.orderPageDataArr.forEach((pageData) => {
          if (pageData) {
            pageDataArr.push(pageData);
          }
        }));
        /* order-page-data に画像を設定 */
        const loopImages = images.filter((v) => !v.album.isOption);
        const optionImages = images.filter((v) => v.album.isOption);
        loopImages.forEach((image, i) => {
          const pageData = pageDataArr.find((v) => v.viewModel.displayPageNo === image.album.pageNo);
          // NOTE: サムネイルを壊したい時はここでindexなどをうまく使ってelseに入れてやると手っ取り早い
          if (pageData) {
            /* 元ファイルの削除 */
            // if (pageData.viewModel.compositeFileName?.virtualPath) {
            //   dispatch(apiActions.run(
            //     new ApiImagesDelete({
            //       kijshopCd,
            //       shopOrderId,
            //       kind: '2',
            //       filename: pageData.viewModel.compositeFileName.virtualPath,
            //     }),
            //   ));
            // }
            /* order-page-data 更新 */
            // pageData.viewModel.originalCompositeFileName = {
            //   realPath: image.name,
            // };
            const indexes = pageData.indexes;
            const compFileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01.jpg`;
            pageData.viewModel.compositeFileName = {
              realPath: '',
              virtualPath: `Comp_Virtual/${compFileName}`,
            };
            pageData.build();
            files.push({
              filename: pageData.xmlUniqueName,
              body: pageData.xml,
              is_create: '0',
            });
            renameImages.push(new File([image.file.slice(0, image.file.size, image.file.type)], compFileName, { type: image.file.type }));
            // image.name = compFileName;
          } else {
            console.error('サムネイルの生成に失敗しました');
            console.group(image.album.displayName);
            console.log('pageNo: ', image.album.pageNo);
            console.group('page data');
            pageDataArr.forEach((v) => console.log(v.viewModel.displayPageNo));
            console.groupEnd();
            console.groupEnd();
            isThumbError = true;
          }
        });
        optionImages.forEach((image, i) => {
          const pageDataList = pageDataArr.filter((v) => v.viewModel.id === image.album.pageDataId && v.viewModel.pageType === image.album.typeID);
          if (pageDataList.length) {
            pageDataList.forEach((pageData) => {
              /* 元ファイルの削除 */
              // if (pageData.viewModel.compositeFileName?.virtualPath) {
              //   dispatch(apiActions.run(
              //     new ApiImagesDelete({
              //       kijshopCd,
              //       shopOrderId,
              //       kind: '2',
              //       filename: pageData.viewModel.compositeFileName.virtualPath,
              //     }),
              //   ));
              // }
              /* order-page-data 更新 */
              // pageData.viewModel.originalCompositeFileName = {
              //   realPath: image.name,
              // };
              const indexes = pageData.indexes;
              const compFileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01.jpg`;
              pageData.viewModel.compositeFileName = {
                realPath: '',
                virtualPath: `Comp_Virtual/${compFileName}`,
              };
              pageData.build();
              files.push({
                filename: pageData.xmlUniqueName,
                body: pageData.xml,
                is_create: '0',
              });
              renameImages.push(new File([image.file.slice(0, image.file.size, image.file.type)], compFileName, { type: image.file.type }));
              // image.name = compFileName;
            })
          } else {
            console.error('サムネイルの生成に失敗しました');
            console.group(image.album.displayName);
            console.group('page data');
            pageDataArr.forEach((v) => console.log(v.viewModel.displayPageNo));
            console.groupEnd();
            console.groupEnd();
            isThumbError = true;
          }
        });
        callback(renameImages, structure, isError, isThumbError);
        // structure.summary.build();
        // dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
        //   onSuccess: () => {
        //     callback(renameImages, structure)
        //   },
        // }));
        dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      },
      // delete: (idArr: string[]): AppThunk => async (dispatch, getState) => {
      //   const { kijshopCd, shopOrderId } = data;
      //   const xml = getState().xml[shopOrderId];
      //   if (!xml) {
      //     return;
      //   }
      //   const structure = lodash.cloneDeep(xml);
      //   /* order-info-data 取得 */
      //   const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
      //   const infoData = structure.orderInfo?.infoData?.[infoDataIndex]?.xml;
      //   if (infoDataIndex === -1 || !infoData) {
      //     return;
      //   }
      //   /* 更新が必要なXML格納用 */
      //   const files: {
      //     filename: string,
      //     body: string,
      //     is_create: '0' | '1',
      //   }[] = [];
      //   structure.summary.build();
      //   dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files })));
      //   dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      // },
    }),
    delete: (image: EditableImage, callback?: () => void): AppThunk => async(dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      const xml = getState().xml[shopOrderId];
      if (!xml || !await XmlFactory.checkVersion()) return;
      const structure = lodash.cloneDeep(xml);

      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1',
      }[] = [];
      if (!structure.orderSelect) {
        structure.orderSelect = XmlFactory.createFromIndex({
          shopOrderId,
          data: { name: 'order-select' },
        });
      }
      const orderSelect = structure.orderSelect;
      if (!structure.info.metaModel.path?.orderSelect) {
        if (!structure.info.metaModel.path) {
          structure.info.metaModel.path = {
            orderSelect: orderSelect.xmlUniqueName,
          };
        } else {
          structure.info.metaModel.path.orderSelect = orderSelect.xmlUniqueName;
        }
        structure.info.build();
        files.push({
          filename: structure.info.xmlUniqueName,
          body: structure.info.xml,
          is_create: '0',
        });
      }
      let deleteFile: Partial<OrderSelectImageData>;
      let uploadedFilePath: string;
      if (orderSelect.metaModel.imageData) {
        const findIndex = orderSelect.metaModel.imageData.findIndex((v) => v.selectID === image.selectId);
        deleteFile = lodash.cloneDeep(orderSelect.metaModel.imageData[findIndex]);
        (findIndex !== -1) && orderSelect.metaModel.imageData.splice(findIndex, 1);
        if (deleteFile.selectCode === '1') {
          uploadedFilePath = orderSelect.xmlModel.lnwOrderSelect.orderSelect?.[0]?.data?.find((v) => v.$.selectID === image.selectId)?.selectFileName?.[0]?.real?.[0]?.$.path?.split('/')[2] || '';
        }
      }
      console.group('before');
      console.log(orderSelect.xml);
      console.log(lodash.cloneDeep(orderSelect.metaModel.imageData));
      console.groupEnd();
      orderSelect.build();
      console.group('after');
      console.log(orderSelect.xml);
      console.log(lodash.cloneDeep(orderSelect.metaModel.imageData));
      console.groupEnd();
      files.push({
        filename: orderSelect.xmlUniqueName,
        body: orderSelect.xml,
        is_create: xml.orderSelect ? '0' : '1',
      });
      structure.summary.build();
      if (!deleteFile!) return;
      const uploadXml = () => new Promise<void>((resolve) => {
        dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
          onSuccess: () => {
            resolve();
          },
        }));
      });
      const deleteImage = () => new Promise<void>((resolve) => {
        dispatch(apiActions.run(new ApiImagesDelete({
          kijshopCd,
          shopOrderId,
          kind: '6',
          filename: deleteFile.selectFileName?.real.path ?? '',
        }), {
          onSuccess: () => resolve(),
        }));
      });
      const deleteUploadedImage = () => new Promise<void>((resolve) => {
        if (!uploadedFilePath) {
          resolve();
        }
        dispatch(apiActions.run(
          new ApiImagesDelete({
            kijshopCd,
            shopOrderId,
            kind: '3',
            // filename: `${kijshopCd}/${shopOrderId}/${uploadedFilePath}`,
            filename: `${uploadedFilePath.split('/')[2]}`,
          }),
          {
            onSuccess: () => resolve(),
            onError: () => resolve(),
          },
          { ignoreSystemError: true },
        ));
      });
      await Promise.all([uploadXml(), deleteImage(), deleteUploadedImage()]);
      dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      callback?.();
    },
    update: (param: TOrderSelectMetaModel): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId } = data;
      if (!await XmlFactory.checkVersion()) {
        return;
      }
      const xml = getState().xml[shopOrderId]?.orderSelect;
      const imageData = xml?.metaModel.imageData || [];
      if (xml) {
        const id = (() => {
          let num = 1;
          xml.metaModel.imageData?.forEach((v) => {
            if (Number(v.selectPictureID) >= num) {
              num = Number(v.selectPictureID) + 1;
            }
          });
          return num;
        })();
        const digit = (v: number, d: number) => {
          let str = String(v);
          for (let i: number = str.length; i < d; i++) {
            str = `0${str}`;
          }
          return str;
        };
        xml.viewModel = {
          data: [
            ...imageData,
            ...param.imageData.map((v, i) => ({
              id: `${id}`,
              selectID: `01${digit(id + i, 5)}`,
              selectPictureID: `${digit(id + i, 5)}`,
              selectCode: '1',
              width: v.width,
              height: v.height,
              originalFileName: v.originalFileName,
              selectFileName: v.selectFileName,
            })),
          ],
        };
        xml.build();
      }
    },
  }),
  layout: (data: { kijshopCd: string, shopOrderId: string, orderId: string }) => ({
    create: (): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId, orderId } = data;
      if (!await XmlFactory.checkVersion()) {
        return;
      }
      const xml = lodash.cloneDeep(getState().xml[shopOrderId]);
      if (!xml) {
        dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).create({ success: () => {}, error: () => {} }));
        // setTimeout(() => dispatch(xmlActions.layout({ kijshopCd, shopOrderId, orderId }).create()), 100);
        return;
      }
      if (xml.orderInfo?.xml) {
        dispatch(xmlActions.shopOrder({ kijshopCd, shopOrderId }).create({ success: () => {}, error: () => {} }));
        // setTimeout(() => dispatch(xmlActions.layout({ kijshopCd, shopOrderId, orderId }).create()), 100);
        return;
      }
      const infoPathData = xml.orderInfo?.xml.metaModel.path?.find((v) => Number(v.id) === Number(orderId));
      if (infoPathData) {
        xml.orderInfo = {
          xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-info' } }),
        };
      }
      const index1 = (xml.orderInfo?.xml.metaModel.path?.length || 0) + 1;
      xml.orderInfo = {
        xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-info' } }),
        infoData: [{
          xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-info-data', indexes: [index1] } }),
          parts: {
            xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-parts', indexes: [index1] } }),
            partsData: [{
              xml: XmlFactory.createFromIndex({
                shopOrderId,
                data: { name: 'order-parts-data', indexes: [index1, 1] },
              }),
              page: {
                xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-page', indexes: [index1, 1] } }),
                pageData: [
                  XmlFactory.createFromIndex({
                    shopOrderId,
                    data: { name: 'order-page-data', indexes: [index1, 1, 1] },
                  }),
                ],
              },
            }],
          },
          pageBreak: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-page-break', indexes: [index1] } }),
        }],
      };
      dispatch(xmlActions.setXml({ shopOrderId, xml }));
    },

    page: () => ({
      add: (addPageNum: number, totalPage: string, pageData: AlbumData, endData: AlbumData, callback?: (_xml: XmlStructureModel) => void): AppThunk => async (dispatch, getState) => {
        const { kijshopCd, shopOrderId, orderId } = data;
        if (!await XmlFactory.checkVersion()) {
          return;
        }
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[] = [];
        const xml = getState().xml[shopOrderId];
        if (!xml) {
          return;
        }
        const structure = lodash.cloneDeep(xml);
        const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
        const parts = structure.orderInfo?.infoData?.[infoDataIndex].parts?.partsData?.find(elm => elm.xml.viewModel.type === 'main');
        if (!parts || !parts.page || !parts.page.pageData) return;
        let end;
        const optionEnds = [];
        if (endData.page > 0) {
          end = parts.page.pageData.pop();
          parts.page.xml.metaModel.paths?.pop();
          const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
            return (v.xml.metaModel.parentId === orderId
              && v.parts
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
            );
          }) : [];
          for (const optionInfo of optionInfoArr) {
            if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
            const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
            if (option.page && option.page.pageData) {
              optionEnds.push(option.page.pageData.pop());
              option.page.xml.metaModel.paths?.pop();
            }
          }
        }
        const pageCount = parts.page.pageData.length || 0;
        const pageType = pageData.pageTypeId;
        const targetPages = parts.page.pageData.filter((v) => v.viewModel.pageType === pageType)!;
        const targetPage = targetPages[targetPages.length - 1];
        const targetDisplayPageNo = (targetPage.viewModel.displayPageNo ?? '').split('-');
        let num = 0;
        while (num < addPageNum) {
          const pageData = XmlFactory.createFromIndex({
            shopOrderId,
            data: {
              name: 'order-page-data',
              indexes: [parts.xml.indexes[0], parts.xml.indexes[1], pageCount + 1 + num],
            },
          });
          parts.page.pageData?.push(pageData);
          pageData.build({
            ...targetPage.viewModel,
            displayPageNo: targetPage.viewModel.pageCount === '1'
              ? `${parts.page.pageData.length}`
              : `${Number(targetDisplayPageNo[0]) + (Number(targetPage.viewModel.pageCount || 0)) * (num + 1)}-${Number(targetDisplayPageNo[1]) + (Number(targetPage.viewModel.pageCount || 0) * (num + 1))}`,
            id: String(pageCount + 1 + num),
            pageNo: String((pageCount + num) * Number(targetPage.viewModel.pageCount)),
            displayPageType: targetPage.viewModel.displayPageType,
            pageOutputCount: targetPage.viewModel.pageOutputCount,
            pageType: targetPage.viewModel.pageType,
            additionalFrame: undefined,
            additionalHole: undefined,
            compositeFileName: undefined,
            freeGraphic: undefined,
            materials: undefined,
            orderFrame: undefined,
            orderPicture: undefined,
            orderText: undefined,
            orderTextImage: undefined,
            originalCompositeFileName: undefined,
            pageNoOverlap: undefined,
            parentID: undefined,
            template: undefined,
          });
          files.push({
            filename: pageData.xmlUniqueName,
            body: pageData.xml,
            is_create: '1',
          });
          const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
            return (v.xml.metaModel.parentId === orderId
              && v.parts
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
            );
          }) : [];
          for (const optionInfo of optionInfoArr) {
            if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
            const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
            if (option.page && option.page.pageData) {
              const opPageData = XmlFactory.createFromIndex({
                shopOrderId,
                data: {
                  name: 'order-page-data',
                  indexes: [option.xml.indexes[0], option.xml.indexes[1], pageCount + 1 + num],
                },
              });
              option.page.pageData.push(opPageData);
              opPageData.build({
                ...targetPage.viewModel,
                displayPageNo: '***',
                // displayPageNo: targetPage.viewModel.pageCount === '1'
                //   ? `${parts.page.pageData.length}`
                //   : `${Number(targetDisplayPageNo[0]) + (Number(targetPage.viewModel.pageCount || 0)) * (num + 1)}-${Number(targetDisplayPageNo[1]) + (Number(targetPage.viewModel.pageCount || 0) * (num + 1))}`,
                id: String(pageCount + 1 + num),
                pageNo: String((pageCount + num) * Number(targetPage.viewModel.pageCount)),
                displayPageType: targetPage.viewModel.displayPageType,
                // displayPageType: '***',
                pageOutputCount: targetPage.viewModel.pageOutputCount,
                pageType: targetPage.viewModel.pageType,
                additionalFrame: undefined,
                additionalHole: undefined,
                compositeFileName: undefined,
                freeGraphic: undefined,
                materials: undefined,
                orderFrame: undefined,
                orderPicture: undefined,
                orderText: undefined,
                orderTextImage: undefined,
                originalCompositeFileName: undefined,
                pageNoOverlap: undefined,
                parentID: String(pageCount + 1 + num),
                template: undefined,
              });
              files.push({
                filename: opPageData.xmlUniqueName,
                body: opPageData.xml,
                is_create: '1',
              });
              option.page.xml.metaModel.paths?.push({
                id: String(option.page.xml.metaModel.paths.length + 1),
                path: opPageData.xmlUniqueName,
              });
            }
          }
          parts.page.xml.metaModel.paths?.push({
            id: String(parts.page.xml.metaModel.paths.length + 1),
            path: pageData.xmlUniqueName,
          });
          num++;
        }
        if (parts.page.pageData.length > 1) {
          const newEnd = XmlFactory.createFromIndex({
            shopOrderId,
            data: {
              name: 'order-page-data',
              indexes: [parts.xml.indexes[0], parts.xml.indexes[1], parts.page.pageData.length + 1],
            },
          });
          if (end) {
            const lastPageData = parts.page?.pageData?.[parts.page.pageData.length - 1];
            if (!lastPageData) return;
            const lastPageCount = (lastPageData.viewModel.displayPageNo ?? '').split('-');
            const _endCount = lastPageCount[lastPageCount.length === 1 ? 0 : 1] ?? '0';
            const endCount = Number(_endCount) + 1;
            parts.page.pageData?.push(newEnd);
            const endDisplayNo = (end.viewModel.displayPageNo ?? '').split('-');

            newEnd.build({
              ...end.viewModel,
              displayPageNo: endDisplayNo.length === 1 ? `${totalPage}` : `${Number(endDisplayNo[0]) + (Number(end.viewModel.pageCount) * addPageNum)}-${Number(endDisplayNo[1]) + (Number(end.viewModel.pageCount) * addPageNum)}`,
              id: String((pageCount + addPageNum) + 1),
              pageNo: endDisplayNo.length === 1 ? `${endCount}` : `${Number(endDisplayNo[0]) + (Number(end.viewModel.pageCount) * addPageNum)}-${Number(endDisplayNo[1]) + (Number(end.viewModel.pageCount) * addPageNum)}`,
            });
            files.push({
              filename: newEnd.xmlUniqueName,
              body: newEnd.xml,
              is_create: '1',
            });
            parts.page.xml.metaModel.paths?.push({
              id: String(parts.page.xml.metaModel.paths.length + 1),
              path: newEnd.xmlUniqueName,
            });
            const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
              return (v.xml.metaModel.parentId === orderId
                && v.parts
                && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
                && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
              );
            }) : [];
            let opEndIndex = 0;
            for (const optionInfo of optionInfoArr) {
              if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
              const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
              if (option.page && option.page.pageData) {
                const newOpEnd = XmlFactory.createFromIndex({
                  shopOrderId,
                  data: {
                    name: 'order-page-data',
                    indexes: [option.xml.indexes[0], option.xml.indexes[1], option.page.pageData.length + 1],
                  },
                });
                const lastPageData = option.page?.pageData?.[option.page.pageData.length - 1];
                if (!lastPageData) return;
                // const lastPageCount = (lastPageData.viewModel.displayPageNo ?? '').split('-');
                // const _endCount = lastPageCount[lastPageCount.length === 1 ? 0 : 1] ?? '0';
                // const endCount = Number(_endCount) + 1;
                option.page.pageData?.push(newOpEnd);
                const endDisplayNo = ((optionEnds ?? [])[opEndIndex]?.viewModel.displayPageNo ?? '').split('-');

                newOpEnd.build({
                  ...(optionEnds ?? [])[opEndIndex]?.viewModel,
                  displayPageNo: endDisplayNo.length === 1 ? `${totalPage}` : `${Number(endDisplayNo[0]) + (Number(end.viewModel.pageCount) * addPageNum)}-${Number(endDisplayNo[1]) + (Number(end.viewModel.pageCount) * addPageNum)}`,
                  id: String((pageCount + addPageNum) + 1),
                  pageNo: endDisplayNo.length === 1 ? `${endCount}` : `${Number(endDisplayNo[0]) + (Number(end.viewModel.pageCount) * addPageNum)}-${Number(endDisplayNo[1]) + (Number(end.viewModel.pageCount) * addPageNum)}`,
                  parentID: String((pageCount + addPageNum) + 1),
                });
                files.push({
                  filename: newOpEnd.xmlUniqueName,
                  body: newOpEnd.xml,
                  is_create: '1',
                });
                option.page.xml.metaModel.paths?.push({
                  id: String(option.page.xml.metaModel.paths.length + 1),
                  path: newOpEnd.xmlUniqueName,
                });
              }
            }
            const albumData = getState().layout.albumPages;
            if (albumData.endCover && albumData.endCover.albums.length) {
              // albumData.endCover.pageCount = Number(totalPage);
              albumData.endCover.albums[0].pageNo = totalPage;
              dispatch(layoutActions.setAlbumData(albumData));
            }
          }
          parts.xml.viewModel.pageCount = totalPage;
          parts.xml.build();
          files.push({
            filename: parts.xml.xmlUniqueName,
            body: parts.xml.xml,
            is_create: '0',
          });
          parts.page.xml.build();
          files.push({
            filename: parts.page.xml.xmlUniqueName,
            body: parts.page.xml.xml,
            is_create: '0',
          });
          const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
            return (v.xml.metaModel.parentId === orderId
              && v.parts
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
            );
          }) : [];
          for (const optionInfo of optionInfoArr) {
            if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
            const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
            option.xml.viewModel.pageCount = totalPage;
            option.xml.build();
            files.push({
              filename: option.xml.xmlUniqueName,
              body: option.xml.xml,
              is_create: '0',
            });
            if (option.page && option.page.pageData) {
              option.page.xml.build();
              files.push({
                filename: option.page.xml.xmlUniqueName,
                body: option.page.xml.xml,
                is_create: '0',
              });
            }
          }
          const orderInfo = structure.orderInfo?.infoData?.find((infoData) => infoData.xml.metaModel.id === orderId);
          if (orderInfo) {
            orderInfo.xml.metaModel.layoutFinish = '0';
            orderInfo.xml.build();
            files.push({
              filename: orderInfo.xml.xmlUniqueName,
              body: orderInfo.xml.xml,
              is_create: '0',
            });
          }
          // dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files })));
          dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
          dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
            onSuccess: () => callback?.(structure),
          }));
        }
      },
      remove: (removePageNum: number, totalPage: string, pageData: AlbumData, endData: AlbumData, callback?: (_xml: XmlStructureModel) => void): AppThunk => async (dispatch, getState) => {
        const { kijshopCd, shopOrderId, orderId } = data;
        if (!await XmlFactory.checkVersion()) {
          return;
        }
        const xml = getState().xml[shopOrderId];
        const files: {
          filename: string,
          body: string,
          is_create: '0' | '1',
        }[] = [];
        if (!xml) {
          return;
        }
        const structure = lodash.cloneDeep(xml);
        const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
        const parts = structure.orderInfo?.infoData?.[infoDataIndex].parts?.partsData?.find(elm => elm.xml.viewModel.type === 'main');
        if (!parts || !parts.page || !parts.page.pageData) return;
        const pageData = parts.page.pageData;
        let end;
        const optionEnds = [];
        if (endData.page > 0) {
          end = parts.page.pageData.pop();
          parts.page.xml.metaModel.paths?.pop();
          const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
            return (v.xml.metaModel.parentId === orderId
              && v.parts
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
            );
          }) : [];
          for (const optionInfo of optionInfoArr) {
            if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
            const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
            if (option.page && option.page.pageData) {
              optionEnds.push(option.page.pageData.pop());
              option.page.xml.metaModel.paths?.pop();
            }
          }
        }
        let num = 0;
        const deletePath: string[] = [];
        while (num < removePageNum) {
          pageData.pop();
          const path = parts.page.xml.metaModel.paths?.pop();
          if (path) deletePath.push(path.path);
          const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
            return (v.xml.metaModel.parentId === orderId
              && v.parts
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
            );
          }) : [];
          for (const optionInfo of optionInfoArr) {
            if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
            const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
            if (option.page && option.page.pageData) {
              option.page.pageData.pop();
              const opPath = option.page.xml.metaModel.paths?.pop();
              if (opPath) deletePath.push(opPath.path);
            }
          }
          num++;
        }
        if (end) deletePath.push(end.xmlUniqueName);
        if (optionEnds) {
          optionEnds.forEach(opEnd => {
            if (opEnd) {
              deletePath.push(opEnd.xmlUniqueName);
            }
          })
        }
        const deleteAsync = () => new Promise<void>((resolve) => {
          if (!deletePath.length) {
            resolve();
            return;
          }
          dispatch(apiActions.run(
            new ApiDeleteXml(kijshopCd, shopOrderId, deletePath),
            {
              onSuccess: () => resolve(),
            },
          ));
        });
        if (end) {
          const newEnd = XmlFactory.createFromIndex({
            shopOrderId,
            data: {
              name: 'order-page-data',
              indexes: [parts.xml.indexes[0], parts.xml.indexes[1], parts.page.pageData.length + 1],
            },
          });
          const lastPageData = parts.page?.pageData?.[parts.page.pageData.length - 1];
          if (!lastPageData) return;
          const lastPageCount = (lastPageData.viewModel.displayPageNo ?? '').split('-');
          const _endCount = lastPageCount[lastPageCount.length === 1 ? 0 : 1] ?? '0';
          const endCount = Number(_endCount) + 1;
          const endDisplayNo = (end.viewModel.displayPageNo ?? '').split('-');
          pageData.push(newEnd);
          newEnd.build({
            ...end.viewModel,
            displayPageNo: String(endDisplayNo
              ? endDisplayNo.length === 1
                ? `${totalPage}`
                : `${Number(endDisplayNo[0]) - (Number(end.viewModel.pageCount) * removePageNum)}-${Number(endDisplayNo[1]) - (Number(end.viewModel.pageCount) * removePageNum)}`
              : totalPage),
            id: String(pageData.length),
            pageNo: endDisplayNo.length === 1 ? `${endCount}` : `${Number(endDisplayNo[0]) + (Number(end.viewModel.pageCount) * removePageNum)}-${Number(endDisplayNo[1]) + (Number(end.viewModel.pageCount) * removePageNum)}`,
          });
          files.push({
            filename: newEnd.xmlUniqueName,
            body: newEnd.xml,
            is_create: '1',
          });
          const albumData = getState().layout.albumPages;
          if (albumData.endCover && albumData.endCover.albums.length) {
            albumData.endCover.albums[0].pageNo = totalPage;
            dispatch(layoutActions.setAlbumData(albumData));
          }
          parts.page.xml.metaModel.paths?.push({
            id: String(parts.page.xml.metaModel.paths.length + 1),
            path: newEnd.xmlUniqueName,
          });
          const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
            return (v.xml.metaModel.parentId === orderId
              && v.parts
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
              && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
            );
          }) : [];
          let opEndIndex = 0;
          for (const optionInfo of optionInfoArr) {
            if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
            const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
            if (option.page && option.page.pageData) {
              const newOpEnd = XmlFactory.createFromIndex({
                shopOrderId,
                data: {
                  name: 'order-page-data',
                  indexes: [option.xml.indexes[0], option.xml.indexes[1], option.page.pageData.length + 1],
                },
              });
              const lastPageCount = (lastPageData.viewModel.displayPageNo ?? '').split('-');
              const _endCount = lastPageCount[lastPageCount.length === 1 ? 0 : 1] ?? '0';
              const endCount = Number(_endCount) + 1;
              const endDisplayNo = (end.viewModel.displayPageNo ?? '').split('-');
              option.page.pageData?.push(newOpEnd);
              newOpEnd.build({
                ...(optionEnds ?? [])[opEndIndex]?.viewModel,
                displayPageNo: String(endDisplayNo
                  ? endDisplayNo.length === 1
                    ? `${totalPage}`
                    : `${Number(endDisplayNo[0]) - (Number(end.viewModel.pageCount) * removePageNum)}-${Number(endDisplayNo[1]) - (Number(end.viewModel.pageCount) * removePageNum)}`
                  : totalPage),
                id: String(pageData.length),
                pageNo: endDisplayNo.length === 1 ? `${endCount}` : `${Number(endDisplayNo[0]) + (Number(end.viewModel.pageCount) * removePageNum)}-${Number(endDisplayNo[1]) + (Number(end.viewModel.pageCount) * removePageNum)}`,
                parentID: String(pageData.length),
              });
              files.push({
                filename: newOpEnd.xmlUniqueName,
                body: newOpEnd.xml,
                is_create: '1',
              });
              option.page.xml.metaModel.paths?.push({
                id: String(option.page.xml.metaModel.paths.length + 1),
                path: newOpEnd.xmlUniqueName,
              });
            }
          }
        }
        parts.xml.viewModel.pageCount = String(totalPage);
        parts.xml.build();
        files.push({
          filename: parts.xml.xmlUniqueName,
          body: parts.xml.xml,
          is_create: '0',
        });
        parts.page.xml.build();
        files.push({
          filename: parts.page.xml.xmlUniqueName,
          body: parts.page.xml.xml,
          is_create: '0',
        });
        const optionInfoArr = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.filter((v) => {
          return (v.xml.metaModel.parentId === orderId
            && v.parts
            && v.parts.partsData?.find(v2 => v2.xml.viewModel.parentID)
            && v.parts.partsData?.find(v2 => v2.xml.viewModel.inheritParent !== undefined)
          );
        }) : [];
        for (const optionInfo of optionInfoArr) {
          if (!optionInfo.parts || !optionInfo.parts.partsData || !optionInfo.parts.partsData.length) continue;
          const option = optionInfo.parts.partsData[optionInfo.parts.partsData.length - 1];
          option.xml.viewModel.pageCount = String(totalPage);
          option.xml.build();
          files.push({
            filename: option.xml.xmlUniqueName,
            body: option.xml.xml,
            is_create: '0',
          });
          if (option.page && option.page.pageData) {
            option.page.xml.build();
            files.push({
              filename: option.page.xml.xmlUniqueName,
              body: option.page.xml.xml,
              is_create: '0',
            });
          }
        }
        const orderInfo = structure.orderInfo?.infoData?.find((infoData) => infoData.xml.metaModel.id === orderId);
        if (orderInfo) {
          orderInfo.xml.metaModel.layoutFinish = '0';
          orderInfo.xml.build();
          files.push({
            filename: orderInfo.xml.xmlUniqueName,
            body: orderInfo.xml.xml,
            is_create: '0',
          });
        }
        await deleteAsync();
        dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
        dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
          onSuccess: () => callback?.(structure),
        }));
      },
    }),

    update: (
      xml: XmlStructureModel,
      type: 'save' | 'comp',
      startDate: string,
      callback: { success: () => void, error: (res: ResponseBase<any>) => void },
      mng: { albumMng: AlbumManager },
      settingData: { datas: AlbumSettingData, pages: AlbumDatas, changeOrderSelectData: ChangeSelectData[], metaData: MetaOrderPostRequest },
    ): AppThunk => async (dispatch, getState) => {
      const { kijshopCd, shopOrderId, orderId } = data;
      if (!await XmlFactory.checkVersion()) {
        return;
      }
      /* ファイルアップロード用 */
      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1',
      }[] = [];
      // const xml = getState().xml[shopOrderId];
      if (!xml) {
        console.error('shopOrderId: ', shopOrderId, ' - ', 'xml: ', xml);
        return;
      }
      const structure = lodash.cloneDeep(xml);
      const infoDataIndex = structure.orderInfo?.infoData ? structure.orderInfo?.infoData?.findIndex((v) => v.xml.metaModel.id === orderId) : -1;
      const infoData = structure.orderInfo?.infoData?.[infoDataIndex];
      const optionInfoData: XmlStructureOrderInfoData[] = [];
      const visibleCondition = getState().createOrder.visibleCondition;
      // レタッチデータが存在するかフラグ
      const isRetouchCreated = Boolean(xml.orderInfo?.infoData?.find(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch'))
      const retouchInfoData = xml.orderInfo?.infoData?.filter(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch' && v.xml.viewModel.item?.id !== 'jptg300179');
      const retouchBaseInfoData = xml.orderInfo?.infoData?.find(v => v.xml.rootTagModel.contentGroupID === 'orderRetouch' && v.xml.viewModel.item?.id === 'jptg300179');
      const retouchDeleteFiles: XmlStructureOrderInfoData[] = []
      let retouchUpdateFiles: OrderPageDataXml[] = []
      const renameOrderInfoData: XmlStructureOrderInfoData[] = [];
      const updateFiles: {
        kind: '1' | '2' | '3' | '4' | '5' | '6',
        dir: string,
        before: string,
        after: string,
      }[] = [];
      /* レイアウトが必要なオプション商品が存在する際に pageData が正しく出力されない不具合再現用にレイアウトしたオプション商品数を計数 */
      let optionCnt = 0;
      for (const info of structure.orderInfo?.infoData ?? []) {
        if (info.xml.metaModel.parentId === orderId) {
          for (const page of info.xml.orderPageDataArr ?? []) {
            if (!page.viewModel.parentID) {
              optionInfoData.push(info);
            }
            if (page.viewModel.template) {
              optionCnt++;
            }
          }
        }
      }
      const optionParts = optionInfoData.map((v) => v.parts) ?? [];
      const parts = infoData?.parts;
      const status = structure.status.metaModel.orderInfoData?.find((v) => v.id === orderId);
      if (!status) return;
      status.data = status.data.map(info => {
        if (info.processID === 'jptg380040') {
          info.statusCode = type === 'save' ? '2' : '1';
        }
        return info;
      });
      if (!status.data.find((v) => v.processID === processId.layout)) {
        status.data.push({
          finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
          machineID: getState().storage.machineId,
          processID: processId.layout,
          startDate,
          statusCode: type === 'save' ? '2' : '1',
        });
      }
      status.orderPartsData = parts?.partsData?.map((orderPartsData) => ({
        orderPageData: orderPartsData.page?.pageData?.map((pageData) => ({
          data: [{
            finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
            machineID: getState().storage.machineId,
            processID: 'jptg380040',
            startDate,
            statusCode: type === 'save' ? '2' : '1',
          },
          ],
        })) || [],
      })).filter((orderPartsData) => orderPartsData.orderPageData?.[0]?.data?.[0]);
      const _status = structure.status.metaModel.orderInfoData ?? [];
      for (const album of mng.albumMng.pages) {
        if ((album.indexes?.length ?? 0) < 3) continue;
        const index1 = Number(album.indexes?.[0] ?? 0) - 1;
        const index2 = Number(album.indexes?.[1] ?? 0) - 1;
        const index3 = Number(album.indexes?.[2] ?? 0) - 1;

        const targetPartsData = _status[index1]?.orderPartsData;
        const　partsData = parts?.partsData;
        let index = 0
        const statusPartsData = partsData?.map(data => {
          if (data?.page) {
            index++
            return  targetPartsData?.[index - 1]
          } else {
            return undefined
          }
        })
        const targetPartsDataXml = statusPartsData?.[index2];
        if (!targetPartsDataXml) continue;
        const targetStatus = targetPartsDataXml?.orderPageData?.[index3]
        if (!targetStatus) continue;
        targetPartsDataXml.id = String((statusPartsData?.indexOf(targetPartsDataXml) ?? 0) + 1);
        targetStatus.data = [
          {
            ...targetStatus.data?.[0],
            comment: album.memo,
          },
        ];
      }
      // parts?.partsData?.forEach((v) => {
      //   v.page?.pageData?.forEach(vv => {
      //     files.push({
      //       filename: vv.xmlUniqueName,
      //       body: vv.xml,
      //       is_create: '0',
      //     });
      //   })
      // })
      for (const partsData of (parts?.partsData ?? []).concat(optionParts.map((v) => v?.partsData ?? []).flat())) {
        for (const pageData of partsData.page?.pageData ?? []) {
          files.push({
            filename: pageData.xmlUniqueName,
            body: pageData.xml,
            is_create: '0',
          });
        }
      }
      /* order-page-break がない場合は作成する */
      if (infoData && !infoData?.pageBreak) {
        const orderPageBreak = XmlFactory.createFromIndex({
          shopOrderId,
          data: { name: 'order-page-break', indexes: infoData.xml.indexes },
        });
        orderPageBreak.build();
        files.push({
          filename: orderPageBreak.xmlUniqueName,
          body: orderPageBreak.xml,
          is_create: '1',
        });
        infoData.xml.orderPageBreak = orderPageBreak;
        if (infoData?.xml.metaModel.path) {
          infoData.xml.metaModel.path.orderPageBreak = orderPageBreak.xmlUniqueName;
        }
        if (structure.orderInfo?.infoData?.[infoDataIndex]) {
          structure.orderInfo.infoData[infoDataIndex].pageBreak = orderPageBreak;
        }
      }
      /* アルバム設定更新 */
      if (infoData) {
        const { datas, pages } = settingData;
        const { name, date, pageTypes } = settingData.datas;
        const pageTypeInfo: { [key in string]: [''] } = {};
        pageTypes.forEach((v) => {
          pageTypeInfo[v] = [''];
        });
        if (infoData.xml.viewModel.descriptionInfo) {
          infoData.xml.viewModel.descriptionInfo.date = date;
          infoData.xml.viewModel.descriptionInfo.name = name;
          infoData.xml.viewModel.descriptionInfo.location = datas.location;
          infoData.xml.viewModel.descriptionInfo.pageType = pageTypeInfo;
        } else {
          infoData.xml.viewModel.descriptionInfo = {
            name,
            date,
            location: datas.location,
            pageType: pageTypeInfo,
          };
        }
      }

      const layoutInfo = ['20', '30'];
      const option = LayoutXmlUtile.getOptionInfoData(orderId, xml);
      const filterLayoutData = xml?.orderInfo?.infoData?.filter(
        (v) => (
          (v.xml.metaModel.id !== orderId && layoutInfo.includes(v.xml?.viewModel?.surrogateProcess?.id ?? ''))
          || (v.xml.metaModel.id !== orderId && !option.map((v2) => v2.xml.metaModel.id ?? '').filter((v2) => v2).includes(v.xml.metaModel.id ?? ''))
        )
      ) ?? [];
      const breakData = xml?.orderInfo?.infoData?.filter(
        (v) => v.xml.viewModel?.surrogateProcess?.id === '10'
      ) ?? [];
      const currentImageData = EditableImageManager.ins.list.filter((v) => v.flags.used && v.selectId).map((v) => v.selectId);
      const searchImage = (selectId: string) => {
        const result: string[] = [];
        /* 当オーダー以外の簡易レイアウト 完全レイアウトの画像チェック */
        for (const info of  filterLayoutData.filter(v => !(v.xml.rootTagModel.contentGroupID === 'orderRetouch' && v.xml.viewModel.item?.id !== 'jptg300179'))) {
          for (const parts of info.parts?.partsData ?? []) {
            for (const page of parts.page?.pageData ?? []) {
              for (const picData of page.viewModel.orderPicture?.data ?? []) {
                if (picData.selectID && picData.selectID !== 'null' && !result.includes(picData.selectID)) {
                  result.push(picData.selectID);
                }
              }
            }
          }
        }
        /* おまかせ画像の画像チェック */
        for (const info of  breakData) {
          for (const picData of info.pageBreak?.viewModel.data?.image ?? []) {
            if (picData.selectID && picData.selectID !== 'null' && !result.includes(picData.selectID)) {
              result.push(picData.selectID);
            }
          }
        }
        for (const picData of currentImageData) {
          if (!result.includes(picData)) result.push(picData);
        }
        return !!result.find((v) => v === selectId);
      }

      if (isRetouchCreated && retouchInfoData?.length) {
        for (const retouchInfoDataEntity of retouchInfoData) {
          const orderPageData = retouchInfoDataEntity.parts?.partsData?.[0].page?.pageData?.[0];
          const structureOrderPageData = structure.orderInfo?.infoData?.find(v => v.xml.xmlUniqueName === retouchInfoDataEntity.xml.xmlUniqueName)?.parts?.partsData?.[0].page?.pageData?.[0];
          let orderPicture = orderPageData?.viewModel.orderPicture?.data;
          if (structureOrderPageData && orderPageData && orderPicture?.length) {
            const selectIdList = orderPicture?.map(img => img.selectID);
            for (const selectId of selectIdList) {
              if (selectId && !searchImage(selectId)) {
                orderPicture = orderPicture?.filter(img => img.selectID !== selectId);
                orderPageData.viewModel.orderPicture!.data = orderPicture;
                structureOrderPageData.viewModel.orderPicture!.data = orderPicture;
                if (!orderPicture.length) {
                  retouchDeleteFiles.push(retouchInfoDataEntity);
                } else {
                  retouchUpdateFiles.push(orderPageData);
                }
              }
            }
          }
        }
        retouchUpdateFiles = retouchUpdateFiles.filter((v, i, self) => self.findIndex(vv => vv.xmlUniqueName === v.xmlUniqueName) === i);
        if (retouchDeleteFiles.length === retouchInfoData.length && retouchBaseInfoData) {
          retouchDeleteFiles.push(retouchBaseInfoData);
        }
      }
      /* order-info-data 更新*/
      if (type === 'comp') {
        if (infoData) {
          infoData.xml.metaModel.layoutFinish = '1';
          if (!infoData.xml.viewModel.descriptionInfo) {
            infoData.xml.viewModel.descriptionInfo = {
              name: '',
              location: '',
              date: '',
              pageType: {},
            };
          }
          const parentCategory = infoData.xml.viewModel.category?.id;
          infoData.xml.optionOrderInfoDataXml.forEach((v) => {
            v.metaModel.layoutFinish = '1';
            // v.viewModel.descriptionInfo = { ...infoData.xml.viewModel.descriptionInfo };
            if (v.viewModel.descriptionInfo) {
              v.viewModel.descriptionInfo.name = infoData.xml.viewModel.descriptionInfo?.name;
              v.viewModel.descriptionInfo.date = infoData.xml.viewModel.descriptionInfo?.date;
              v.viewModel.descriptionInfo.location = infoData.xml.viewModel.descriptionInfo?.location;
              if (!optionDescriptionLocationEditableCheck(
                v.viewModel.category?.id,
                v.viewModel.descriptionInfo.name,
                v.viewModel.descriptionInfo.date,
                v.viewModel.descriptionInfo.location,
                infoData.xml.viewModel.orderMethod?.id,
                v.viewModel.item?.id,
                infoData.xml.viewModel.category?.id,
              ) || (
                (
                  (v.viewModel.category?.id || parentCategory) === 'jp0332'
                  || (v.viewModel.category?.id || parentCategory) === 'jp0301'
                  || (v.viewModel.category?.id || parentCategory) === 'jp0335'
                  || (v.viewModel.category?.id || parentCategory) === 'jp0441'
                  || (v.viewModel.category?.id || parentCategory) === 'jp0487'
                  || (v.viewModel.category?.id || parentCategory) === 'jp0488'
                ) && (
                  (v.viewModel.category?.id || parentCategory) !== 'jp0301'
                  && (v.viewModel.category?.id || parentCategory) !== 'jp0332'
                  && (v.viewModel.category?.id || parentCategory) !== 'jp0487'
                  && (v.viewModel.category?.id || parentCategory) !== 'jp0488'
                )
              )) {
                // v.viewModel.descriptionInfo.pageType = descriptionInfoPageTypeInherit(v.viewModel.item?.id || infoData.xml.viewModel.item?.id || '') ? (v.viewModel.descriptionInfo.pageType || {}) : infoData.xml.viewModel.descriptionInfo?.pageType;
                v.viewModel.descriptionInfo.pageType = {};
              }
            }
            v.build();
            files.push({
              filename: v.xmlUniqueName,
              body: v.xml,
              is_create: '0',
            });
            /* status 更新 */
            const orderInfoData = structure.status.metaModel.orderInfoData?.find((data) => data.id === v.metaModel.id);
            if (type === 'comp' && structure.status.metaModel.orderInfoData) {
              let partsStatusArr: {
                id: string,
                // パーツ明細データ
                orderPageData: {
                  id: string,
                  data: {
                    // 作業完了日時
                    finishDate: string,
                    // マシンID
                    machineID: string,
                    // 工程ID(レイアウトでのみ生成、jptg380040固定)
                    processID: string,
                    // 作業開始日時
                    startDate: string,
                    // ステータスコード(0 or null or 1 or 2 or 3)
                    statusCode: string,
                  }[],
                }[],
              }[] | undefined = undefined;
              if (v.orderParts?.orderPartsDataArr) {
                v.orderParts.orderPartsDataArr.forEach((partsData, partsDataIndex) => {
                  if (!partsStatusArr) {
                    partsStatusArr = [];
                  }
                  // if (partsData.orderPage?.orderPageDataArr?.find((v) => v.viewModel.template?.name)) {
                  const arr: {
                    id: string,
                    finishDate: string,
                    machineID: string,
                    processID: string,
                    startDate: string,
                    statusCode: string,
                  }[] = [];
                  partsData.orderPage?.orderPageDataArr.forEach((pageData, i) => {
                    if (pageData.viewModel.template) {
                      optionCnt--;
                    }
                    if (optionCnt > 0) {
                      optionCnt--;
                    } else {
                      arr.push({
                        id: pageData.viewModel.id || `${i + 1}`,
                        finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                        machineID: getState().storage.machineId,
                        processID: 'jptg380040',
                        startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                        statusCode: '1',
                      });
                    }
                  });
                  // if (optionCnt) {
                  //   optionCnt--;
                  // } else {
                  //   partsStatusArr.push({
                  //     orderPageData: partsData.orderPage?.orderPageDataArr ? [...partsData.orderPage?.orderPageDataArr].map((pageData) => ({
                  //       data: [{
                  //         finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                  //         machineID: getState().storage.machineId,
                  //         processID: 'jptg380040',
                  //         startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                  //         statusCode: '1',
                  //       }],
                  //     })) : [],
                  //   });
                  // }
                  // }
                  if (arr.length) {
                    partsStatusArr.push({
                      id: partsData.viewModel.id || `${partsDataIndex + 1}`,
                      orderPageData: arr.map((pageData) => ({
                        id: pageData.id,
                        data: [{
                          finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                          machineID: getState().storage.machineId,
                          processID: 'jptg380040',
                          startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                          statusCode: '1',
                        }],
                      })),
                    });
                  }
                });
              }
              if (orderInfoData) {
                // orderInfoData.data = [{
                //   finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                //   machineID: getState().storage.machineId,
                //   processID: 'jptg380040',
                //   startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                //   statusCode: '1',
                // }];
                orderInfoData.orderPartsData = partsStatusArr;
              } else {
                structure.status.metaModel.orderInfoData.push({
                  id: v.metaModel.id,
                  data: [{
                    finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                    machineID: getState().storage.machineId,
                    processID: 'jptg380040',
                    startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                    statusCode: '1',
                  }],
                  orderPartsData: v.orderParts?.orderPartsDataArr ? [...v.orderParts?.orderPartsDataArr].map((partsData) => ({
                    id: partsData.viewModel.id,
                    orderPageData: partsData.orderPage?.orderPageDataArr ? [...partsData.orderPage?.orderPageDataArr].map((pageData, i) => {
                      if (pageData.viewModel.template) {
                        optionCnt--;
                      }
                      if (optionCnt > 0) {
                        optionCnt--;
                        return { data: [] };
                      }
                      return ({
                        id: pageData.viewModel.id,
                        data: [{
                          // id: pageData.viewModel.id || `${i + 1}`,
                          finishDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                          machineID: getState().storage.machineId,
                          processID: 'jptg380040',
                          startDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                          statusCode: '1',
                        }],
                      });
                    }).filter((v) => v.data.length) : [],
                  })) : undefined,
                });
              }
            }
          });
        }
      }
      if (infoData) {
        infoData.xml.build();
        files.push({
          filename: infoData.xml.xmlUniqueName,
          body: infoData.xml.xml,
          is_create: '0',
        });
        infoData.xml.optionOrderInfoDataXml.forEach((v) => {
          const optionStatus = structure.status.metaModel.orderInfoData?.find((data) => data.id === v.metaModel.id);
          if (optionStatus) {
            const layoutStatus = optionStatus.data.find((v) => v.processID === processId.layout);
            if (layoutStatus) {
              layoutStatus.statusCode = type === 'save' ? '2' : '1';
            }
          }
        });
      }
      structure.status.metaModel.orderInfoData?.sort((a, b) => Number(a.id || 0) > Number(b.id || 0) ? 1 : -1);
      structure.status.build();
      files.push({
        filename: structure.status.xmlUniqueName,
        body: structure.status.xml,
        is_create: '0',
      });
      if (structure.orderSelect?.metaModel.imageData && settingData.changeOrderSelectData.length) {
        const imageDataArr: Partial<OrderSelectImageData>[] = [];
        lodash.cloneDeep(structure.orderSelect.metaModel.imageData).forEach((img) => {
          const find = settingData.changeOrderSelectData.find((data) => data.selectId === img.selectID);
          if (find) {
            imageDataArr.push({
              ...img,
              width: find.rect.width,
              height: find.rect.height,
            });
          } else {
            imageDataArr.push({
              ...img,
            });
          }
        });
        structure.orderSelect.metaModel.imageData = imageDataArr;
        structure.orderSelect.build();
        files.push({
          filename: structure.orderSelect.xmlUniqueName,
          body: structure.orderSelect.xml,
          is_create: '0',
        });
      }
      // if (structure.summary) {
      //   structure.summary.di(structure);
      //   structure.summary.build();
      //   files.push({
      //     filename: structure.summary.xmlUniqueName,
      //     body: structure.summary.xml,
      //     is_create: '0',
      //   });
      // }
      await new Promise<void>((resolve) => {
        console.log(settingData.metaData);
        if (settingData.metaData) {
          const metaData = settingData.metaData;
          console.log({...metaData});
          dispatch(apiActions.run(
            new ApiMetaOrderPost({
              kijshopCd: metaData.kijshopCd ?? '',
              shopOrderId: metaData.shopOrderId ?? '',
              orderId: metaData.orderId ?? '',
              data: {
                ...metaData.data,
              }
            }),
            {
              onSuccess: resolve,
            },
          ))
        } else {
          resolve()
        }
      })
      if (retouchDeleteFiles.length) {
        if (structure.orderInfo?.infoData?.length) {
          let deleteCount = 0;
          retouchDeleteFiles.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id));
          for (const infoData of structure.orderInfo.infoData) {
            if (Number(infoData.xml.metaModel.id) < Number(retouchDeleteFiles[0].xml.metaModel.id)) continue;
            const isDelete = retouchDeleteFiles.find(v => v.xml.xmlUniqueName === infoData.xml.xmlUniqueName);
            if (isDelete) {
              deleteCount++
            } else {
              renameOrderInfoData.push(infoData);
              const newOrderInfoDataId = String(Number(infoData.xml.metaModel.id) - deleteCount);
              const orderMethod = !infoData.xml.metaModel.parentId
                ? infoData.xml.viewModel.orderMethod?.id
                : xml.orderInfo?.infoData?.find((v) => v.xml.metaModel.id === infoData.xml.metaModel.parentId)?.xml.viewModel.orderMethod?.id;
              if (xml.orderInfo?.xml.metaModel.parentSequence?.find((v) => v.id === infoData.xml.metaModel.id)) {
                xml.orderInfo?.infoData?.forEach((v) => {
                  if (v.xml.metaModel.parentId === infoData.xml.metaModel.id) {
                    v.xml.metaModel.parentId = newOrderInfoDataId;
                  }
                });
                xml.status.metaModel.orderInfoData?.forEach((v) => {
                  if (v.id === infoData.xml.metaModel.id) {
                    v.id = newOrderInfoDataId;
                  }
                });
                xml.status.build();
              }
              /* order-info-data の id 更新 */
              infoData.xml.changeIndexes([newOrderInfoDataId]);
              infoData.xml.metaModel.id = newOrderInfoDataId;
              /* order-page-break の id 更新 */
              infoData.pageBreak?.changeIndexes([newOrderInfoDataId]);
              /* order-parts の id 更新 */
              infoData.parts?.xml.changeIndexes([newOrderInfoDataId]);
              /* order-info-data のパス情報更新 */
              infoData.xml.metaModel.path = (infoData.pageBreak || infoData.parts) && {
                orderPageBreak: infoData.pageBreak?.xmlUniqueName,
                orderParts: infoData.parts?.xml.xmlUniqueName,
              };
              infoData.xml.build();
              infoData.parts?.partsData?.forEach((orderPartsData) => {
                /* order-parts-data の id 更新 */
                orderPartsData.xml.changeIndexes([newOrderInfoDataId, orderPartsData.xml.indexes[1]]);
                /* order-page の id 更新 */
                orderPartsData.page?.xml.changeIndexes([newOrderInfoDataId, orderPartsData.page.xml.indexes[1]]);
                /* order-parts-data のパス情報更新 */
                if (orderPartsData.page?.xml) {
                  orderPartsData.xml.metaModel.path = orderPartsData.page.xml.xmlUniqueName;
                  orderPartsData.xml.build();
                }
                orderPartsData.page?.pageData?.forEach((orderPageData) => {
                  /* order-page-data の id 更新 */
                  orderPageData.changeIndexes([newOrderInfoDataId, orderPageData.indexes[1], orderPageData.indexes[2]]);
                  const indexes = orderPageData.indexes;
                  /* 画像の更新が必要なものはXML内の記述のみここで更新してファイル自体は後述の処理で行う */
                  if (orderMethod === '20' || orderMethod === '30') {
                    /* サムネイル */
                    if (orderPageData.viewModel?.compositeFileName?.virtualPath) {
                      const dir = 'Comp_Virtual';
                      const name = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01.jpg`;
                      updateFiles.push({
                        kind: '2',
                        dir,
                        before: orderPageData.viewModel?.compositeFileName.virtualPath.split('/')[1],
                        after: name,
                      });
                      orderPageData.viewModel.compositeFileName.virtualPath = `${dir}/${name}`;
                    }
                    /* テキスト */
                    const textImageList = orderPageData.viewModel?.orderTextImage?.data;
                    if (textImageList) {
                      textImageList.forEach((v) => {
                        const fileIndex = v.path?.split('/')?.[1]?.split('.')?.[0]?.split('_')?.[6];
                        if (v.path) {
                          const dir = 'Except_Real';
                          const name = `ER_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_${fileIndex}.png`
                          updateFiles.push({
                            kind: '4',
                            dir,
                            before: v.path.split('/')[1],
                            after: name,
                          });
                          v.path = `${dir}/${name}`;
                        }
                      });
                    }
                  }
                  if (orderMethod === '40') {
                    /* 完成画像 */
                    if (orderPageData.viewModel.compositeFileName?.realPath) {
                      const dir = 'Comp_Real';
                      const ext = orderPageData.viewModel.compositeFileName.realPath.split('.')[1];
                      const fileName = `CR_PPM_${shopOrderId}_${indexes[0]}_${indexes[1]}_${indexes[2]}_01`;
                      const name = `${fileName}.${ext}`;
                      updateFiles.push({
                        kind: '1',
                        dir,
                        before: orderPageData.viewModel.compositeFileName.realPath.split('/')[1],
                        after: name,
                      });
                      orderPageData.viewModel.compositeFileName.realPath = `${dir}/${name}`;
                      orderPageData.viewModel.compositeFileName.displayPath = `Display_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                      orderPageData.viewModel.compositeFileName.sheetPath = `ContactSheet_Virtual/${fileName}.${ext === 'png' ? 'jpg' : ext}`;
                    }
                  }
                  orderPageData.build();
                });
                /* order-page のパス情報更新 */
                if (orderPartsData.page?.pageData) {
                  orderPartsData.page.xml.metaModel.paths = orderPartsData.page.pageData?.map((v, i) => ({
                    id: `${i + 1}`,
                    path: v.xmlUniqueName,
                  }));
                  orderPartsData.page.xml.build();
                }
              });
              /* order-parts のパス情報更新 */
              if (infoData.parts) {
                infoData.parts.xml.metaModel.paths = infoData.parts.partsData?.map((v, i) => ({
                  id: `${i + 1}`,
                  path: v.xml.xmlUniqueName,
                }));
                infoData.parts.xml.build();
              }
            }
          }
        }
        /* order-info のパス情報更新 */
        if (xml.orderInfo?.xml) {
          const renameFirstId = renameOrderInfoData.length ? renameOrderInfoData.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id))[0].xml.metaModel.id : 0;
          let orderInfoDataArr: XmlStructureOrderInfoData[] = renameOrderInfoData.length ? [...renameOrderInfoData, ...(structure?.orderInfo?.infoData?.filter(v => Number(v.xml.metaModel.id) < (Number(renameFirstId)) ?? 0) || [])] : [...renameOrderInfoData, ...(structure?.orderInfo?.infoData|| [])];
          if (!renameOrderInfoData.length) {
            orderInfoDataArr = orderInfoDataArr.filter((v,  i) => !retouchDeleteFiles.find(vv => vv.xml.metaModel.id === v.xml.metaModel.id));
          }
          orderInfoDataArr.sort((a, b) => Number(a.xml.metaModel.id) - Number(b.xml.metaModel.id));
          /* 保存するデータを structure に上書き */
          structure.orderInfo = {
            xml: xml.orderInfo.xml,
            infoData: orderInfoDataArr,
          };
          const parentOrderInfo: { id: string, path: string }[] = [];
          structure.orderInfo.xml.metaModel.path = orderInfoDataArr.map((v, i) => {
            const path = {
              id: `${v.xml.metaModel.id || (i + 1)}`,
              path: v.xml.xmlUniqueName,
            };
            if (!v.xml.metaModel.parentId) {
              parentOrderInfo.push(path);
            }
            return path;
          });
          if (parentOrderInfo.length) {
            structure.orderInfo.xml.metaModel.parentSequence = parentOrderInfo;
          }
          structure.orderInfo.xml.build();
        }
        /* 更新が必要なファイルのアップデート */
        if (updateFiles.length) {
          /* ファイル取得 */
          const uploadFiles: ImagesPostRequest[] = await Promise.all(updateFiles.map((v) => new Promise<ImagesPostRequest | null>((resolve) => {
            dispatch(apiActions.run(
              new ApiImagesGetOne({
                kijshopCd,
                path: `${kijshopCd}/${shopOrderId}/${v.dir}/${v.before}`,
              }),
              {
                onSuccess: async (blob: Blob) => {
                  if (blob.type === 'application/json') {
                    resolve(null);
                    return;
                  }
                  const name = v.after;
                  const file = new File(
                    [blob],
                    name,
                    {
                      lastModified: new Date().getTime(),
                      type: blob.type,
                    },
                  );
                  let thumb: string | null = null;
                  if (v.kind === '1') {
                    thumb = await new Promise<string | null>((resolve) => {
                      dispatch(apiActions.run(
                        new ApiImagesGetOne({
                          kijshopCd,
                          path: `${kijshopCd}/uploadThumb/${shopOrderId}/${v.before}`,
                        }),
                        {
                          onSuccess: (v: Blob) => {
                            if (v.type === 'application/json') {
                              resolve(null);
                              return;
                            }
                            const reader = new FileReader();
                            reader.readAsDataURL(v);
                            reader.onload = () => {
                              resolve((reader.result as string).replace(/^data:\w+\/\w+;base64,/, ''));
                            }
                          },
                          onError: () => resolve(null),
                        },
                        { ignoreSystemError: true },
                      ));
                    });
                  }
                  resolve({
                    kijshopCd,
                    shopOrderId,
                    kind: v.kind,
                    filename: name,
                    data: file,
                    dataThumb: thumb || undefined,
                  });
                },
                onError: () => resolve(null),
              },
              { ignoreSystemError: true },
            ));
          }))) as ImagesPostRequest[];
          if (uploadFiles.findIndex((v) => !v) !== -1) {
            console.error(uploadFiles);
            // callback.error();
            return;
          }
          /* ファイル更新 ※このタイミングで中断すると致命的なので注意 */
          const beforeUnload = window.onbeforeunload;
          window.onbeforeunload = () => true;
          const resultArr = await Promise.all(updateFiles.map((v) => new Promise<boolean>((resolve) => {
            dispatch(apiActions.run(
              new ApiImagesDelete({
                kijshopCd,
                shopOrderId,
                kind: v.kind,
                filename: v.before,
              }),
              {
                onSuccess: (res: ResponseBase<any>) => resolve(res?.error?.errorCode === '200'),
                onError: () => resolve(false),
              },
            ));
          })));
          await Promise.all(uploadFiles.map((v) => new Promise<boolean>((resolve) => {
            dispatch(apiActions.run(
              new ApiImagesPost(v),
              {
                onSuccess: () => resolve(true),
                onError: () => resolve(false),
              },
            ));
          })))
            .then((res) => resultArr.push(...res));
          window.onbeforeunload = beforeUnload;
          if (resultArr.find((v) => !v)) {
            // callback.error();
            return;
          }
        }
        /* 注文データの統合 */
        structure.info = xml.info;
        structure.customer = xml.customer;
        structure.shop = xml.shop;
        structure.delivery = xml.delivery;
        structure.status = xml.status;
        structure.summary = xml.summary;
        structure.orderSelect = xml.orderSelect;


        // structure.summary.di(structure);
        // structure.summary.build();
        /* 更新した order-info-data に紐づく xml を更新 */
        dispatch(apiActions.run(
          new ApiAllGetXml(kijshopCd, shopOrderId),
          {
            onSuccess: (res: ResponseBase<GetXmlResponse>) => {
              structure.orderInfo?.xml.build();
              const files: {
                filename: string,
                body: string,
                is_create: '0' | '1',
              }[] = structure.orderInfo?.xml ? [{
                filename: structure.orderInfo.xml.xmlUniqueName,
                body: structure.orderInfo.xml.xml,
                is_create: '0',
              }] : [];
              /* 保存済ファイルのうち使用していないファイルを削除する */
              const arr = res.body.data?.fileinfo.map((v) => v.filename) || [];
              (structure.orderInfo?.infoData || []).forEach((orderInfoData) => {
                /* order-info-data */
                const orderInfoDataIndex = arr.findIndex((v) => v === orderInfoData.xml.xmlUniqueName);
                if (orderInfoDataIndex !== -1) {
                  arr.splice(orderInfoDataIndex, 1);
                }
                if (!files.find((v) => v.filename === orderInfoData.xml.xmlUniqueName)) {
                  files.push({
                    filename: orderInfoData.xml.xmlUniqueName,
                    body: orderInfoData.xml.xml,
                    is_create: orderInfoDataIndex === -1 ? '1' : '0',
                  });
                }
                /* order-page-break */
                const pageBreakIndex = arr.findIndex((v) => v === orderInfoData.pageBreak?.xmlUniqueName);
                if (pageBreakIndex !== -1) {
                  arr.splice(pageBreakIndex, 1);
                }
                if (orderInfoData.pageBreak && !files.find((v) => v.filename === orderInfoData.pageBreak?.xmlUniqueName)) {
                  files.push({
                    filename: orderInfoData.pageBreak.xmlUniqueName,
                    body: orderInfoData.pageBreak.xml,
                    is_create: pageBreakIndex === -1 ? '1' : '0',
                  });
                }
                /* order-parts */
                const pageIndex = arr.findIndex((v) => v === orderInfoData.parts?.xml.xmlUniqueName);
                if (pageIndex !== -1) {
                  arr.splice(pageIndex, 1);
                }
                if (orderInfoData.parts?.xml && !files.find((v) => v.filename === orderInfoData.parts?.xml.xmlUniqueName)) {
                  files.push({
                    filename: orderInfoData.parts.xml.xmlUniqueName,
                    body: orderInfoData.parts.xml.xml,
                    is_create: pageIndex === -1 ? '1' : '0',
                  });
                }
                orderInfoData.parts?.partsData?.forEach((orderPartsData) => {
                  /* order-parts-data */
                  const partsDataIndex = arr.findIndex((v) => v === orderPartsData.xml.xmlUniqueName);
                  if (partsDataIndex !== -1) {
                    arr.splice(partsDataIndex, 1);
                  }
                  if (!files.find((v) => v.filename === orderPartsData.xml.xmlUniqueName)) {
                    files.push({
                      filename: orderPartsData.xml.xmlUniqueName,
                      body: orderPartsData.xml.xml,
                      is_create: partsDataIndex === -1 ? '1' : '0',
                    });
                  }
                  /* order-page */
                  const pageIndex = arr.findIndex((v) => v === orderPartsData.page?.xml.xmlUniqueName);
                  if (pageIndex !== -1) {
                    arr.splice(pageIndex, 1);
                  }
                  if (orderPartsData.page?.xml && !files.find((v) => v.filename === orderPartsData.page?.xml.xmlUniqueName)) {
                    files.push({
                      filename: orderPartsData.page.xml.xmlUniqueName,
                      body: orderPartsData.page.xml.xml,
                      is_create: pageIndex === -1 ? '1' : '0',
                    });
                  }
                  orderPartsData.page?.pageData?.forEach((orderPageData) => {
                    /* order-page-data */
                    const pageDataIndex = arr.findIndex((v) => v === orderPageData.xmlUniqueName);
                    if (pageDataIndex !== -1) {
                      arr.splice(pageDataIndex, 1);
                    }
                    if (!files.find((v) => v.filename === orderPageData.xmlUniqueName)) {
                      orderPageData.build();
                      files.push({
                        filename: orderPageData.xmlUniqueName,
                        body: orderPageData.xml,
                        is_create: pageDataIndex === -1 ? '1' : '0',
                      });
                    }
                  });
                });
              });
              /* 商品に依存しないXMLを削除対象から除外 */
              ([
                structure.info,
                structure.customer,
                structure.shop,
                structure.delivery,
                structure.status,
                structure.summary,
                structure.orderSelect,
                structure.orderInfo?.xml,
              ] as XmlClass<any>[]).forEach((data) => {
                const index = arr.findIndex((v) => v === data?.xmlUniqueName);
                if (index !== -1) {
                  arr.splice(index, 1);
                }
              });
              /* status 更新 */
              if (structure.status) {
                structure.status?.build();
                files.push({
                  filename: structure.status.xmlUniqueName,
                  body: structure.status.xml,
                  is_create: '0',
                });
              }
              /* summary 更新 */
              if (structure.summary) {
                structure.summary?.di(structure);
                structure.summary?.build();
                files.push({
                  filename: structure.summary.xmlUniqueName,
                  body: structure.summary.xml,
                  is_create: '0',
                });
              }
              /* データ更新 */
              if (arr.length) {
                dispatch(apiActions.run(new ApiDeleteXml(kijshopCd, shopOrderId, arr)));
              }
              dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
                onSuccess: () => {
                  dispatch(apiActions.run(
                    new ApiMetaShopOrderGet({
                      kijshopCd,
                      shopOrderId,
                    }),
                    {
                      onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
                        if (res.body.data) {
                          dispatch(apiActions.run(
                            new ApiMetaShopOrderPost({
                              kijshopCd,
                              shopOrderId,
                              data: {
                                ...res.body.data,
                                lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                                // status: 'ラボ発注中',
                              },
                            }),
                            {
                              onSuccess: () => {
                                callback.success();
                              },
                              onError: () => {
                                callback.success();
                              },
                            },
                          ));
                        } else {
                          callback.success();
                          // setUpdatingName(false);
                        }
                      },
                      onError: (e: ResponseBase<any>) => {
                        callback.success();
                      },
                    },
                  ));
                },
                onError: (res: ResponseBase<any>) => callback.error(res),
              }));
              dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
            },
          },
        ));
      } else {
        for (const retouchUpdateFile of retouchUpdateFiles) {
          retouchUpdateFile.build();
          files.push({
            filename: retouchUpdateFile.xmlUniqueName,
            body: retouchUpdateFile.xml,
            is_create: '0',
          });
        }
        structure.summary.di(structure);
        structure.summary.build();
        dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
          onSuccess: () => {
            dispatch(apiActions.run(
              new ApiMetaShopOrderGet({
                kijshopCd,
                shopOrderId,
              }),
              {
                onSuccess: (res: ResponseBase<MetaShopOrderGetResponse>) => {
                  if (res.body.data) {
                    dispatch(apiActions.run(
                      new ApiMetaShopOrderPost({
                        kijshopCd,
                        shopOrderId,
                        data: {
                          ...res.body.data,
                          lastUpdateDate: DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS', '/'),
                          // status: 'ラボ発注中',
                        },
                      }),
                      {
                        onSuccess: () => {
                          callback.success();
                        },
                        onError: () => {
                          callback.success();
                        },
                      },
                    ));
                  } else {
                    callback.success();
                    // setUpdatingName(false);
                  }
                },
                onError: (e: ResponseBase<any>) => {
                  callback.success();
                },
              },
            ));
          },
          onError: (res: ResponseBase<any>) => callback.error(res),
        }));
        dispatch(xmlActions.setXml({ shopOrderId, xml: structure }));
      }
    },
  }),
  retouch: (data: { kijshopCd: string, shopOrderId: string }) => ({
    create: (props: {
      parentRetouchItem: ParentRetouchInfo,
      retouchItems: ChildRetouchInfo,
      infoOutputCount: string,
      onSuccess: () => void,
      startDate: Date,
    }): AppThunk => async (dispatch, getState) => {
      const { parentRetouchItem, retouchItems, infoOutputCount, onSuccess, startDate } = props;
      const { kijshopCd, shopOrderId } = data;
      if (!await XmlFactory.checkVersion()) {
        return;
      }
      const xml = getState().xml[shopOrderId];
      if (!xml) {
        return;
      }
      /* store のデータをクローン */
      const structure = lodash.cloneDeep(xml);
      if (!structure.orderInfo) {
        return;
      }

      const model = new XMLRetouchModel({
        infoOutputCount,
        kijshopCd,
        shopOrderId,
        structure,
        infoData: structure.orderInfo.infoData,
        checkList: retouchItems,
      });

      const deleteAsync = (): Promise<void> => {
        return new Promise((resolve) => {
          if (!model?.deleteFiles.length) {
            resolve();
            return;
          }
          dispatch(apiActions.run(new ApiDeleteXml(kijshopCd, shopOrderId, model.deleteFiles.map((v) => v.xmlUniqueName)), {
            onSuccess: () => resolve(),
          }));
        });
      };
      /* status-xml 更新 */
      const statusXml = (model.structure || structure).status;
      const retouchStatusDataIndex = statusXml.metaModel.data?.findIndex((v) => v.processID === 'retouch') || -1;
      const date = DateFormatter.date2str(new Date(), 'YYYYMMDD_HHmmSS');
      if (statusXml.metaModel.data) {
        if (retouchStatusDataIndex !== -1) {
          statusXml.metaModel.data[retouchStatusDataIndex].finishDate = date;
          statusXml.metaModel.data[retouchStatusDataIndex].machineID = getState().storage.machineId;
        } else {
          statusXml.metaModel.data.push({
            finishDate: date,
            machineID: getState().storage.machineId,
            processID: 'retouch',
            startDate: DateFormatter.date2str(startDate, 'YYYYMMDD_HHmmSS'),
            statusCode: '1',
          });
        }
      }
      statusXml.build();
      const files: {
        filename: string,
        body: string,
        is_create: '0' | '1'
      }[] = [
        ...model.pushFiles,
      ];
      const statusIndex = files.findIndex((v) => v.filename === statusXml.xmlUniqueName);
      if (statusIndex !== -1) {
        files[statusIndex].body = statusXml.xml;
      } else {
        files.push({
          filename: statusXml.xmlUniqueName,
          body: statusXml.xml,
          is_create: '0',
        });
      }
      await deleteAsync();
      dispatch(apiActions.run(new ApiUploadXml(kijshopCd, shopOrderId, { files }), {
        onSuccess: () => onSuccess(),
      }));
    },
  }),
  /** XML取得からクラス生成まで */
  getXml: (kijshopCd: string, shopOrderId: string, getResOnly?: (xml: XmlStructureModel | null) => void, callback?: () => void): AppThunk => async (dispatch) => {
    if (!await XmlFactory.checkVersion()) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    /* 全件取得実行 */
    const res = await new ApiAllGetXml(kijshopCd, shopOrderId).do() as ResponseBase<GetXmlResponse>;
    if (!res?.body?.data?.fileinfo?.length) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    const files = res.body.data.fileinfo;
    /* オーダー情報XMLのみ shopOrderId を元に取得 */
    const infoRes = files.find((v) => v.filename === `PPM_${shopOrderId}_11_11.xml`);
    if (!infoRes) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    // const info = XmlFactory.createFromName({ shopOrderId, data: { name: 'order', uniqueName: infoRes.filename, parentName: '' } });
    const info = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order' } });
    await info.parse(infoRes.body);
    /* オーダー情報から紐づくXMLを取得 */
    /* customer */
    const customerName = info.metaModel.path?.customer;
    if (!customerName) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    const customerRes = files.find((v) => v.filename === customerName);
    if (!customerRes) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    /* delivery */
    const deliveryName = info.metaModel.path?.delivery;
    if (!deliveryName) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    const deliveryRes = files.find((v) => v.filename === deliveryName);
    if (!deliveryRes) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    /* shop */
    const shopName = info.metaModel?.path?.shop;
    if (!shopName) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    const shopRes = files.find((v) => v.filename === shopName);
    if (!shopRes) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    /* status */
    const statusName = info.metaModel.path?.status;
    if (!statusName) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    const statusRes = files.find((v) => v.filename === statusName);
    if (!statusRes) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    /* summary */
    const summaryName = info.metaModel.path?.summary;
    if (!summaryName) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    const summaryRes = files.find((v) => v.filename === summaryName);
    if (!summaryRes) {
      getResOnly?.(null);
      callback?.();
      return;
    }
    /* store に保持するクラスデータ作成 */
    const customer = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'customer' } });
    await customer.parse(customerRes.body);
    const delivery = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'delivery' } });
    await delivery.parse(deliveryRes.body);
    const shop = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'shop' } });
    await shop.parse(shopRes.body);
    const status = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'status' } });
    await status.parse(statusRes.body);
    const summary = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'summary' } });
    await summary.parse(summaryRes.body);
    const structure: XmlStructureModel = {
      info,
      customer,
      delivery,
      shop,
      status,
      summary,
    };
    /* order-select */
    const orderSelectName = info.metaModel.path?.orderSelect;
    if (orderSelectName) {
      const orderSelectRes = files.find((v) => v.filename === orderSelectName);
      if (orderSelectRes) {
        structure.orderSelect = XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-select' } });
        await structure.orderSelect.parse(orderSelectRes.body);
      }
    }
    /* order-info */
    const orderInfoName = info.metaModel.path?.orderInfo;
    if (orderInfoName) {
      const orderInfoRes = files.find((v) => v.filename === orderInfoName);
      if (orderInfoRes) {
        structure.orderInfo = {
          xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-info' } }),
        };
        await structure.orderInfo.xml.parse(orderInfoRes.body);
        const infoPathArr = structure.orderInfo.xml.metaModel.path || [];
        const infoDataArr = structure.orderInfo.infoData || [];
        for (let i = 0; i < infoPathArr.length; i++) {
          const { path, id } = infoPathArr[i];
          const infoDataRes = files.find((v) => v.filename === path);
          if (infoDataRes) {
            const orderInfoData = XmlFactory.createFromIndex({
              shopOrderId,
              data: { name: 'order-info-data', indexes: [id] },
            });
            await orderInfoData.parse(infoDataRes.body);
            const infoData: XmlStructureOrderInfoData = {
              xml: orderInfoData,
            };
            infoDataArr.push(infoData);
            /* order-page-break */
            const infoDataPathPageBreak = orderInfoData.metaModel.path?.orderPageBreak;
            const pageBreakRes = files.find((v) => v.filename === infoDataPathPageBreak);
            if (infoDataPathPageBreak && pageBreakRes) {
              infoData.pageBreak = XmlFactory.createFromIndex({
                shopOrderId,
                data: { name: 'order-page-break', indexes: [id] },
              });
              await infoData.pageBreak.parse(pageBreakRes.body);
              infoData.xml.orderPageBreak = infoData.pageBreak;
            }
            /* order-parts */
            const infoDataPathParts = orderInfoData.metaModel.path?.orderParts;
            const partsRes = files.find((v) => v.filename === infoDataPathParts);
            if (infoDataPathParts && partsRes) {
              infoData.parts = {
                xml: XmlFactory.createFromIndex({ shopOrderId, data: { name: 'order-parts', indexes: [id] } }),
              };
              await infoData.parts.xml.parse(partsRes.body);
              infoData.xml.orderParts = infoData.parts.xml;
              /* order-parts-data */
              const partsPathArr = infoData.parts.xml.metaModel.paths || [];
              const partsDataArr = infoData.parts?.partsData || [];
              for (let j = 0; j < partsPathArr.length; j++) {
                const { path: partsDataPath, id: partsDataId } = partsPathArr[j];
                const partsDataRes = files.find((v) => v.filename === partsDataPath);
                if (partsDataPath && partsDataRes) {
                  const partsData: XmlStructureOrderPartsData = {
                    xml: XmlFactory.createFromIndex({
                      shopOrderId,
                      data: { name: 'order-parts-data', indexes: [id, partsDataId] },
                    }),
                  };
                  await partsData.xml.parse(partsDataRes.body);
                  partsDataArr.push(partsData);
                  /* order-page */
                  const pagePath = partsData.xml.metaModel.path;
                  const pageRes = files.find((v) => v.filename === pagePath);
                  if (pagePath && pageRes) {
                    partsData.page = {
                      xml: XmlFactory.createFromIndex({
                        shopOrderId,
                        data: { name: 'order-page', indexes: [id, partsDataId] },
                      }),
                    };
                    await partsData.page.xml.parse(pageRes.body);
                    partsData.xml.orderPage = partsData.page?.xml;
                    /* order-page-data */
                    const pagePathArr = partsData.page.xml.metaModel.paths || [];
                    const pageDataArr = partsData.page?.pageData || [];
                    for (let k = 0; k < pagePathArr.length; k++) {
                      const { path: pageDataPath, id: pageDataId } = pagePathArr[k];
                      const pageDataRes = files.find((v) => v.filename === pageDataPath);
                      if (pageDataPath && pageDataRes) {
                        const pageData = XmlFactory.createFromIndex({
                          shopOrderId,
                          data: { name: 'order-page-data', indexes: [id, partsDataId, pageDataId] },
                        });
                        await pageData.parse(pageDataRes.body);
                        pageDataArr.push(pageData);
                      }
                    }
                    partsData.page.pageData = pageDataArr;
                    if (partsData.page?.xml) {
                      partsData.page.xml.orderPageDataArr = pageDataArr;
                    }
                  }
                }
              }
              infoData.parts.partsData = partsDataArr;
              if (infoData.parts?.xml) {
                infoData.parts.xml.orderPartsDataArr = partsDataArr.map((v) => v.xml);
              }
            }
          }
        }
        structure.orderInfo.infoData = infoDataArr;
      }
    }
    console.log(structure);
    if (getResOnly) {
      getResOnly(structure);
    } else {
      dispatch(xmlActions.setXml({
        shopOrderId,
        xml: structure,
      }));
    }
    callback?.();
  },
};

export const xmlReducer = xmlSlice.reducer;
export const xmlActions = Object.assign(xmlSlice.actions, asyncActions);
